@charset "UTF-8";
@import url(~plyr/dist/plyr.css);
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #2fb5d2;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #2fb5d2;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #208094;
    text-decoration: none; }

a:not([href]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label, .label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1,
.products-section-title, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: "Noto Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 500;
  line-height: 1.2; }

h1,
.h1,
.products-section-title {
  font-size: 1.375rem; }

h2, .h2 {
  font-size: 1.25rem; }

h3, .h3 {
  font-size: 1.125rem; }

h4, .h4 {
  font-size: 1.125rem; }

h5, .h5 {
  font-size: 1rem; }

h6, .h6 {
  font-size: 0.9375rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 3.125rem;
  font-weight: 600;
  line-height: 1.2; }

.display-2 {
  font-size: 2.188rem;
  font-weight: 400;
  line-height: 1.2; }

.display-3 {
  font-size: 1.563rem;
  font-weight: 400;
  line-height: 1.2; }

.display-4 {
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small,
.quick-view {
  font-size: 0.875rem;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 0.875rem;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #f1f1f1;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  box-shadow: inset 0 -0.1rem 0 rgba(0, 0, 0, 0.25); }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700;
    box-shadow: none; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container, .l-wrapper, main > .notifications-container, .l-wrapper--boxed {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container, .l-wrapper, main > .notifications-container, .l-wrapper--boxed {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container, .l-wrapper, main > .notifications-container, .l-wrapper--boxed {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container, .l-wrapper, main > .notifications-container, .l-wrapper--boxed {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container, .l-wrapper, main > .notifications-container, .l-wrapper--boxed {
      max-width: 1140px; } }

.container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .l-wrapper, main > .notifications-container, .l-wrapper--boxed, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .l-wrapper, main > .notifications-container, .l-wrapper--boxed, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .l-wrapper, main > .notifications-container, .l-wrapper--boxed, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .l-wrapper, main > .notifications-container, .l-wrapper--boxed, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

.row, .products {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .product-miniature, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col-xs-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  min-width: 0;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6, .product-miniature {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12, .col-xs-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  background-color: #fff; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #f1f1f1; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #f1f1f1; }
  .table tbody + tbody {
    border-top: 2px solid #f1f1f1; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #f1f1f1; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #f1f1f1; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075); }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #f1f1f1; }

.table-dark {
  color: #fff;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #98dae9;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(47, 181, 210, 0.25); }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  appearance: none; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875rem;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9); }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #28a745; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875rem;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9); }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #dc3545; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label, .form-inline .label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #212529;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(47, 181, 210, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65;
    box-shadow: none; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }
    .btn:not(:disabled):not(.disabled):active, .btn:not(:disabled):not(.disabled).active {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
      .btn:not(:disabled):not(.disabled):active:focus, .btn:not(:disabled):not(.disabled).active:focus {
        box-shadow: 0 0 0 0.2rem rgba(47, 181, 210, 0.25), inset 0 3px 5px rgba(0, 0, 0, 0.125); }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #2fb5d2;
  border-color: #2fb5d2;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-primary:hover {
    color: #fff;
    background-color: #279bb4;
    border-color: #2592a9; }
  .btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #279bb4;
    border-color: #2592a9;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(78, 192, 217, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #2fb5d2;
    border-color: #2fb5d2; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #2592a9;
    border-color: #22899f; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(78, 192, 217, 0.5); }

.btn-secondary, .wysiwyg-content a.btn-default {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-secondary:hover, .wysiwyg-content a.btn-default:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62; }
  .btn-secondary:focus, .wysiwyg-content a.btn-default:focus, .btn-secondary.focus, .wysiwyg-content a.focus.btn-default {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }
  .btn-secondary.disabled, .wysiwyg-content a.disabled.btn-default, .btn-secondary:disabled, .wysiwyg-content a.btn-default:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-secondary:not(:disabled):not(.disabled):active, .wysiwyg-content a.btn-default:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .wysiwyg-content a.btn-default:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle,
  .wysiwyg-content .show > a.dropdown-toggle.btn-default {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .wysiwyg-content a.btn-default:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .wysiwyg-content a.btn-default:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus,
    .wysiwyg-content .show > a.dropdown-toggle.btn-default:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success:focus, .btn-success.focus {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger:focus, .btn-danger.focus {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }

.btn-outline-primary {
  color: #2fb5d2;
  border-color: #2fb5d2; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #2fb5d2;
    border-color: #2fb5d2; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(47, 181, 210, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #2fb5d2;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #2fb5d2;
    border-color: #2fb5d2; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(47, 181, 210, 0.5); }

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-outline-success {
  color: #28a745;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-link {
  font-weight: 400;
  color: #2fb5d2;
  text-decoration: none; }
  .btn-link:hover {
    color: #208094;
    text-decoration: none; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.175); }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #2fb5d2; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group.show .dropdown-toggle {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
  .btn-group.show .dropdown-toggle.btn-link {
    box-shadow: none; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #2fb5d2;
    background-color: #2fb5d2;
    box-shadow: none; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(47, 181, 210, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #98dae9; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #c2e9f2;
    border-color: #c2e9f2;
    box-shadow: none; }
  .custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #adb5bd solid 1px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50% / 50% 50%; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #2fb5d2;
  background-color: #2fb5d2;
  box-shadow: none; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(47, 181, 210, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(47, 181, 210, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(47, 181, 210, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(47, 181, 210, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  appearance: none; }
  .custom-select:focus {
    border-color: #98dae9;
    outline: 0;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(47, 181, 210, 0.25); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }
  .custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #98dae9;
    box-shadow: 0 0 0 0.2rem rgba(47, 181, 210, 0.25); }
  .custom-file-input[disabled] ~ .custom-file-label,
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit; }

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(47, 181, 210, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(47, 181, 210, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(47, 181, 210, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #2fb5d2;
    border: 0;
    box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #c2e9f2; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #f1f1f1;
    border-color: transparent;
    box-shadow: inset 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1); }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #2fb5d2;
    border: 0;
    box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #c2e9f2; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #f1f1f1;
    border-color: transparent;
    box-shadow: inset 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1); }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #2fb5d2;
    border: 0;
    box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #c2e9f2; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem;
    box-shadow: inset 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1); }
  .custom-range::-ms-fill-lower {
    background-color: #f1f1f1; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #f1f1f1; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 2px solid #f1f1f1; }
  .nav-tabs .nav-item {
    margin-bottom: -2px; }
  .nav-tabs .nav-link {
    border: 2px solid transparent; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: transparent transparent #f1f1f1; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #2fb5d2;
    background-color: #fff;
    border-color: transparent transparent #2fb5d2; }
  .nav-tabs .dropdown-menu {
    margin-top: -2px; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #2fb5d2; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.card, .page-wrapper, .card-block, .block-categories, .account-links .link-item, .address {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .card > hr, .page-wrapper > hr, .card-block > hr, .block-categories > hr, .account-links .link-item > hr, .address > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group, .page-wrapper > .list-group, .card-block > .list-group, .block-categories > .list-group, .account-links .link-item > .list-group, .address > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child, .page-wrapper > .list-group:first-child, .card-block > .list-group:first-child, .block-categories > .list-group:first-child, .account-links .link-item > .list-group:first-child, .address > .list-group:first-child {
      border-top-width: 0; }
    .card > .list-group:last-child, .page-wrapper > .list-group:last-child, .card-block > .list-group:last-child, .block-categories > .list-group:last-child, .account-links .link-item > .list-group:last-child, .address > .list-group:last-child {
      border-bottom-width: 0; }

.card-body, .page-content, .card-block, .block-categories, .address-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header, .page-header, .address-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header + .list-group .list-group-item:first-child, .page-header + .list-group .list-group-item:first-child, .address-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer, .page-footer, .address-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%; }

.card-deck .card, .card-deck .page-wrapper, .card-deck .card-block, .card-deck .block-categories, .card-deck .account-links .link-item, .account-links .card-deck .link-item, .card-deck .address {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px; }
    .card-deck .card, .card-deck .page-wrapper, .card-deck .card-block, .card-deck .block-categories, .card-deck .account-links .link-item, .account-links .card-deck .link-item, .card-deck .address {
      flex: 1 0 0%;
      margin-right: 15px;
      margin-bottom: 0;
      margin-left: 15px; } }

.card-group > .card, .card-group > .page-wrapper, .card-group > .card-block, .card-group > .block-categories, .account-links .card-group > .link-item, .card-group > .address {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card, .card-group > .page-wrapper, .card-group > .card-block, .card-group > .block-categories, .account-links .card-group > .link-item, .card-group > .address {
      flex: 1 0 0%;
      margin-bottom: 0; }
      .card-group > .card + .card, .card-group > .page-wrapper + .card, .card-group > .card-block + .card, .card-group > .block-categories + .card, .account-links .card-group > .link-item + .card, .card-group > .address + .card, .card-group > .card + .page-wrapper, .card-group > .page-wrapper + .page-wrapper, .card-group > .card-block + .page-wrapper, .card-group > .block-categories + .page-wrapper, .account-links .card-group > .link-item + .page-wrapper, .card-group > .address + .page-wrapper, .card-group > .card + .card-block, .card-group > .page-wrapper + .card-block, .card-group > .card-block + .card-block, .card-group > .block-categories + .card-block, .account-links .card-group > .link-item + .card-block, .card-group > .address + .card-block, .card-group > .card + .block-categories, .card-group > .page-wrapper + .block-categories, .card-group > .card-block + .block-categories, .card-group > .block-categories + .block-categories, .account-links .card-group > .link-item + .block-categories, .card-group > .address + .block-categories, .account-links .card-group > .card + .link-item, .account-links .card-group > .page-wrapper + .link-item, .account-links .card-group > .card-block + .link-item, .account-links .card-group > .block-categories + .link-item, .account-links .card-group > .link-item + .link-item, .account-links .card-group > .address + .link-item, .card-group > .card + .address, .card-group > .page-wrapper + .address, .card-group > .card-block + .address, .card-group > .block-categories + .address, .account-links .card-group > .link-item + .address, .card-group > .address + .address {
        margin-left: 0;
        border-left: 0; } }

.card-columns .card, .card-columns .page-wrapper, .card-columns .card-block, .card-columns .block-categories, .card-columns .account-links .link-item, .account-links .card-columns .link-item, .card-columns .address {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card, .card-columns .page-wrapper, .card-columns .card-block, .card-columns .block-categories, .card-columns .account-links .link-item, .account-links .card-columns .link-item, .card-columns .address {
      display: inline-block;
      width: 100%; } }

.accordion > .card, .accordion > .page-wrapper, .accordion > .card-block, .accordion > .block-categories, .account-links .accordion > .link-item, .accordion > .address {
  overflow: hidden; }
  .accordion > .card:not(:last-of-type), .accordion > .page-wrapper:not(:last-of-type), .accordion > .card-block:not(:last-of-type), .accordion > .block-categories:not(:last-of-type), .account-links .accordion > .link-item:not(:last-of-type), .accordion > .address:not(:last-of-type) {
    border-bottom: 0; }
  .accordion > .card > .card-header, .accordion > .page-wrapper > .card-header, .accordion > .card-block > .card-header, .accordion > .block-categories > .card-header, .account-links .accordion > .link-item > .card-header, .accordion > .address > .card-header, .accordion > .card > .page-header, .accordion > .page-wrapper > .page-header, .accordion > .card-block > .page-header, .accordion > .block-categories > .page-header, .account-links .accordion > .link-item > .page-header, .accordion > .address > .page-header, .accordion > .card > .address-header, .accordion > .page-wrapper > .address-header, .accordion > .card-block > .address-header, .accordion > .block-categories > .address-header, .account-links .accordion > .link-item > .address-header, .accordion > .address > .address-header {
    margin-bottom: -1px; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef; }

.breadcrumb-item {
  display: flex; }
  .breadcrumb-item + .breadcrumb-item {
    padding-left: 0.5rem; }
    .breadcrumb-item + .breadcrumb-item::before {
      display: inline-block;
      padding-right: 0.5rem;
      color: #6c757d;
      content: "/"; }
  .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: underline; }
  .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: none; }
  .breadcrumb-item.active {
    color: #6c757d; }

.pagination, .product-comments__pagination ul {
  display: flex;
  padding-left: 0;
  list-style: none; }

.page-link, .product-comments__pagination li span {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #2fb5d2;
  background-color: #fff;
  border: 1px solid #ced4da; }
  .page-link:hover, .product-comments__pagination li span:hover {
    z-index: 2;
    color: #208094;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #ced4da; }
  .page-link:focus, .product-comments__pagination li span:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(47, 181, 210, 0.25); }

.page-item:first-child .page-link, .product-comments__pagination li:first-child .page-link, .page-item:first-child .product-comments__pagination li span, .product-comments__pagination li .page-item:first-child span, .product-comments__pagination li:first-child span {
  margin-left: 0; }

.page-item.active .page-link, .product-comments__pagination li.active .page-link, .page-item.active .product-comments__pagination li span, .product-comments__pagination li .page-item.active span, .product-comments__pagination li.active span {
  z-index: 3;
  color: #fff;
  background-color: #2fb5d2;
  border-color: #2fb5d2; }

.page-item.disabled .page-link, .product-comments__pagination li.disabled .page-link, .page-item.disabled .product-comments__pagination li span, .product-comments__pagination li .page-item.disabled span, .product-comments__pagination li.disabled span {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #ced4da; }

.pagination-lg .page-link, .pagination-lg .product-comments__pagination li span, .product-comments__pagination li .pagination-lg span {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-sm .page-link, .product-comments__pagination ul .page-link, .pagination-sm .product-comments__pagination li span, .product-comments__pagination li .pagination-sm span, .product-comments__pagination ul li span, .product-comments__pagination li ul span {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em; }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #185e6d;
  background-color: #d5f0f6;
  border-color: #c5eaf2; }
  .alert-primary hr {
    border-top-color: #b0e2ed; }
  .alert-primary .alert-link {
    color: #0f3a43; }

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db; }
  .alert-secondary hr {
    border-top-color: #c8cbcf; }
  .alert-secondary .alert-link {
    color: #202326; }

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb; }
  .alert-success hr {
    border-top-color: #b1dfbb; }
  .alert-success .alert-link {
    color: #0b2e13; }

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-info hr {
    border-top-color: #abdde5; }
  .alert-info .alert-link {
    color: #062c33; }

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }
  .alert-warning hr {
    border-top-color: #ffe8a1; }
  .alert-warning .alert-link {
    color: #533f03; }

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb; }
  .alert-danger hr {
    border-top-color: #f1b0b7; }
  .alert-danger .alert-link {
    color: #491217; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }
  .alert-dark hr {
    border-top-color: #b9bbbe; }
  .alert-dark .alert-link {
    color: #040505; }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #2fb5d2;
    border-color: #2fb5d2; }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

.list-group-flush > .list-group-item {
  border-width: 0 0 1px; }
  .list-group-flush > .list-group-item:last-child {
    border-bottom-width: 0; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0; }

a.close.disabled {
  pointer-events: none; }

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  opacity: 0; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    height: min-content;
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.5);
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #f1f1f1; }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #f1f1f1; }
  .modal-footer > * {
    margin: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem);
      height: min-content; }
  .modal-content {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.5); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 990px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.2); }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc(-0.5rem - 1px); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc(-0.5rem - 1px); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb; }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg); } }

.spinner-border, .rc:after, .btn-add-to-cart .btn-add-to-cart__spinner {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border .75s linear infinite; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1;
    transform: none; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow .75s linear infinite; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #2fb5d2 !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #2592a9 !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #f1f1f1 !important; }

.border-top {
  border-top: 1px solid #f1f1f1 !important; }

.border-right {
  border-right: 1px solid #f1f1f1 !important; }

.border-bottom {
  border-bottom: 1px solid #f1f1f1 !important; }

.border-left {
  border-left: 1px solid #f1f1f1 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #2fb5d2 !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex, .social-sharing, .pack-product-container, .pack-product-quantity {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap, .social-sharing {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1, .pack-product-name {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between, .pack-product-container {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center, .social-sharing--product, .pack-product-container, .pack-product-quantity {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch, .account-links {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch, .pack-product-quantity {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left, .float-xs-left {
  float: left !important; }

.float-right, .float-xs-right {
  float: right !important; }

.float-none, .float-xs-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.user-select-all {
  user-select: all !important; }

.user-select-auto {
  user-select: auto !important; }

.user-select-none {
  user-select: none !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm, .social-share-btn--product {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.2) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4,
.pack-product-name {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4,
.pack-product-name {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #2fb5d2 !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #208094 !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #19692c !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container, .l-wrapper, main > .notifications-container, .l-wrapper--boxed {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #f1f1f1 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #f1f1f1; }
  .table .thead-dark th {
    color: inherit;
    border-color: #f1f1f1; } }

/* HEADER */
/* MAIN */
/* FOOTER */
/* PRODUCT MINIATURE */
/* product title */
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(~material-design-icons/iconfont/MaterialIcons-Regular.eot);
  /* For IE6-8 */
  src: local("Material Icons"), local("MaterialIcons-Regular"), url(~material-design-icons/iconfont/MaterialIcons-Regular.woff2) format("woff2"), url(~material-design-icons/iconfont/MaterialIcons-Regular.woff) format("woff"), url(~material-design-icons/iconfont/MaterialIcons-Regular.ttf) format("truetype"); }

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5rem;
  /* Preferred icon size */
  display: inline-block;
  vertical-align: top;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }

.material-icons + span {
  display: inline-block; }

.small .material-icons, .quick-view .material-icons,
.btn-sm .material-icons,
.btn-group-sm > .btn .material-icons {
  font-size: 0.875rem;
  line-height: 1.5; }

.btn-lg .material-icons, .btn-group-lg > .btn .material-icons {
  font-size: 1.875rem; }

.pagination .material-icons, .product-comments__pagination ul .material-icons {
  font-size: 1.25rem; }

.material-icons.md-18 {
  font-size: 18px;
  line-height: 1; }

.material-icons.md-24 {
  font-size: 24px;
  line-height: 1; }

.material-icons.md-36 {
  font-size: 36px;
  line-height: 1; }

.material-icons.md-48 {
  font-size: 48px;
  line-height: 1; }

/** VENDOR **/
body[data-aos-duration='50'] [data-aos], [data-aos][data-aos][data-aos-duration='50'] {
  transition-duration: 50ms; }

body[data-aos-delay='50'] [data-aos], [data-aos][data-aos][data-aos-delay='50'] {
  transition-delay: 0; }
  body[data-aos-delay='50'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='50'].aos-animate {
    transition-delay: 50ms; }

body[data-aos-duration='100'] [data-aos], [data-aos][data-aos][data-aos-duration='100'] {
  transition-duration: 100ms; }

body[data-aos-delay='100'] [data-aos], [data-aos][data-aos][data-aos-delay='100'] {
  transition-delay: 0; }
  body[data-aos-delay='100'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='100'].aos-animate {
    transition-delay: 100ms; }

body[data-aos-duration='150'] [data-aos], [data-aos][data-aos][data-aos-duration='150'] {
  transition-duration: 150ms; }

body[data-aos-delay='150'] [data-aos], [data-aos][data-aos][data-aos-delay='150'] {
  transition-delay: 0; }
  body[data-aos-delay='150'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='150'].aos-animate {
    transition-delay: 150ms; }

body[data-aos-duration='200'] [data-aos], [data-aos][data-aos][data-aos-duration='200'] {
  transition-duration: 200ms; }

body[data-aos-delay='200'] [data-aos], [data-aos][data-aos][data-aos-delay='200'] {
  transition-delay: 0; }
  body[data-aos-delay='200'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='200'].aos-animate {
    transition-delay: 200ms; }

body[data-aos-duration='250'] [data-aos], [data-aos][data-aos][data-aos-duration='250'] {
  transition-duration: 250ms; }

body[data-aos-delay='250'] [data-aos], [data-aos][data-aos][data-aos-delay='250'] {
  transition-delay: 0; }
  body[data-aos-delay='250'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='250'].aos-animate {
    transition-delay: 250ms; }

body[data-aos-duration='300'] [data-aos], [data-aos][data-aos][data-aos-duration='300'] {
  transition-duration: 300ms; }

body[data-aos-delay='300'] [data-aos], [data-aos][data-aos][data-aos-delay='300'] {
  transition-delay: 0; }
  body[data-aos-delay='300'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='300'].aos-animate {
    transition-delay: 300ms; }

body[data-aos-duration='350'] [data-aos], [data-aos][data-aos][data-aos-duration='350'] {
  transition-duration: 350ms; }

body[data-aos-delay='350'] [data-aos], [data-aos][data-aos][data-aos-delay='350'] {
  transition-delay: 0; }
  body[data-aos-delay='350'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='350'].aos-animate {
    transition-delay: 350ms; }

body[data-aos-duration='400'] [data-aos], [data-aos][data-aos][data-aos-duration='400'] {
  transition-duration: 400ms; }

body[data-aos-delay='400'] [data-aos], [data-aos][data-aos][data-aos-delay='400'] {
  transition-delay: 0; }
  body[data-aos-delay='400'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='400'].aos-animate {
    transition-delay: 400ms; }

body[data-aos-duration='450'] [data-aos], [data-aos][data-aos][data-aos-duration='450'] {
  transition-duration: 450ms; }

body[data-aos-delay='450'] [data-aos], [data-aos][data-aos][data-aos-delay='450'] {
  transition-delay: 0; }
  body[data-aos-delay='450'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='450'].aos-animate {
    transition-delay: 450ms; }

body[data-aos-duration='500'] [data-aos], [data-aos][data-aos][data-aos-duration='500'] {
  transition-duration: 500ms; }

body[data-aos-delay='500'] [data-aos], [data-aos][data-aos][data-aos-delay='500'] {
  transition-delay: 0; }
  body[data-aos-delay='500'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='500'].aos-animate {
    transition-delay: 500ms; }

body[data-aos-duration='550'] [data-aos], [data-aos][data-aos][data-aos-duration='550'] {
  transition-duration: 550ms; }

body[data-aos-delay='550'] [data-aos], [data-aos][data-aos][data-aos-delay='550'] {
  transition-delay: 0; }
  body[data-aos-delay='550'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='550'].aos-animate {
    transition-delay: 550ms; }

body[data-aos-duration='600'] [data-aos], [data-aos][data-aos][data-aos-duration='600'] {
  transition-duration: 600ms; }

body[data-aos-delay='600'] [data-aos], [data-aos][data-aos][data-aos-delay='600'] {
  transition-delay: 0; }
  body[data-aos-delay='600'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='600'].aos-animate {
    transition-delay: 600ms; }

body[data-aos-duration='650'] [data-aos], [data-aos][data-aos][data-aos-duration='650'] {
  transition-duration: 650ms; }

body[data-aos-delay='650'] [data-aos], [data-aos][data-aos][data-aos-delay='650'] {
  transition-delay: 0; }
  body[data-aos-delay='650'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='650'].aos-animate {
    transition-delay: 650ms; }

body[data-aos-duration='700'] [data-aos], [data-aos][data-aos][data-aos-duration='700'] {
  transition-duration: 700ms; }

body[data-aos-delay='700'] [data-aos], [data-aos][data-aos][data-aos-delay='700'] {
  transition-delay: 0; }
  body[data-aos-delay='700'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='700'].aos-animate {
    transition-delay: 700ms; }

body[data-aos-duration='750'] [data-aos], [data-aos][data-aos][data-aos-duration='750'] {
  transition-duration: 750ms; }

body[data-aos-delay='750'] [data-aos], [data-aos][data-aos][data-aos-delay='750'] {
  transition-delay: 0; }
  body[data-aos-delay='750'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='750'].aos-animate {
    transition-delay: 750ms; }

body[data-aos-duration='800'] [data-aos], [data-aos][data-aos][data-aos-duration='800'] {
  transition-duration: 800ms; }

body[data-aos-delay='800'] [data-aos], [data-aos][data-aos][data-aos-delay='800'] {
  transition-delay: 0; }
  body[data-aos-delay='800'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='800'].aos-animate {
    transition-delay: 800ms; }

body[data-aos-duration='850'] [data-aos], [data-aos][data-aos][data-aos-duration='850'] {
  transition-duration: 850ms; }

body[data-aos-delay='850'] [data-aos], [data-aos][data-aos][data-aos-delay='850'] {
  transition-delay: 0; }
  body[data-aos-delay='850'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='850'].aos-animate {
    transition-delay: 850ms; }

body[data-aos-duration='900'] [data-aos], [data-aos][data-aos][data-aos-duration='900'] {
  transition-duration: 900ms; }

body[data-aos-delay='900'] [data-aos], [data-aos][data-aos][data-aos-delay='900'] {
  transition-delay: 0; }
  body[data-aos-delay='900'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='900'].aos-animate {
    transition-delay: 900ms; }

body[data-aos-duration='950'] [data-aos], [data-aos][data-aos][data-aos-duration='950'] {
  transition-duration: 950ms; }

body[data-aos-delay='950'] [data-aos], [data-aos][data-aos][data-aos-delay='950'] {
  transition-delay: 0; }
  body[data-aos-delay='950'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='950'].aos-animate {
    transition-delay: 950ms; }

body[data-aos-duration='1000'] [data-aos], [data-aos][data-aos][data-aos-duration='1000'] {
  transition-duration: 1000ms; }

body[data-aos-delay='1000'] [data-aos], [data-aos][data-aos][data-aos-delay='1000'] {
  transition-delay: 0; }
  body[data-aos-delay='1000'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1000'].aos-animate {
    transition-delay: 1000ms; }

body[data-aos-duration='1050'] [data-aos], [data-aos][data-aos][data-aos-duration='1050'] {
  transition-duration: 1050ms; }

body[data-aos-delay='1050'] [data-aos], [data-aos][data-aos][data-aos-delay='1050'] {
  transition-delay: 0; }
  body[data-aos-delay='1050'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1050'].aos-animate {
    transition-delay: 1050ms; }

body[data-aos-duration='1100'] [data-aos], [data-aos][data-aos][data-aos-duration='1100'] {
  transition-duration: 1100ms; }

body[data-aos-delay='1100'] [data-aos], [data-aos][data-aos][data-aos-delay='1100'] {
  transition-delay: 0; }
  body[data-aos-delay='1100'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1100'].aos-animate {
    transition-delay: 1100ms; }

body[data-aos-duration='1150'] [data-aos], [data-aos][data-aos][data-aos-duration='1150'] {
  transition-duration: 1150ms; }

body[data-aos-delay='1150'] [data-aos], [data-aos][data-aos][data-aos-delay='1150'] {
  transition-delay: 0; }
  body[data-aos-delay='1150'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1150'].aos-animate {
    transition-delay: 1150ms; }

body[data-aos-duration='1200'] [data-aos], [data-aos][data-aos][data-aos-duration='1200'] {
  transition-duration: 1200ms; }

body[data-aos-delay='1200'] [data-aos], [data-aos][data-aos][data-aos-delay='1200'] {
  transition-delay: 0; }
  body[data-aos-delay='1200'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1200'].aos-animate {
    transition-delay: 1200ms; }

body[data-aos-duration='1250'] [data-aos], [data-aos][data-aos][data-aos-duration='1250'] {
  transition-duration: 1250ms; }

body[data-aos-delay='1250'] [data-aos], [data-aos][data-aos][data-aos-delay='1250'] {
  transition-delay: 0; }
  body[data-aos-delay='1250'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1250'].aos-animate {
    transition-delay: 1250ms; }

body[data-aos-duration='1300'] [data-aos], [data-aos][data-aos][data-aos-duration='1300'] {
  transition-duration: 1300ms; }

body[data-aos-delay='1300'] [data-aos], [data-aos][data-aos][data-aos-delay='1300'] {
  transition-delay: 0; }
  body[data-aos-delay='1300'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1300'].aos-animate {
    transition-delay: 1300ms; }

body[data-aos-duration='1350'] [data-aos], [data-aos][data-aos][data-aos-duration='1350'] {
  transition-duration: 1350ms; }

body[data-aos-delay='1350'] [data-aos], [data-aos][data-aos][data-aos-delay='1350'] {
  transition-delay: 0; }
  body[data-aos-delay='1350'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1350'].aos-animate {
    transition-delay: 1350ms; }

body[data-aos-duration='1400'] [data-aos], [data-aos][data-aos][data-aos-duration='1400'] {
  transition-duration: 1400ms; }

body[data-aos-delay='1400'] [data-aos], [data-aos][data-aos][data-aos-delay='1400'] {
  transition-delay: 0; }
  body[data-aos-delay='1400'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1400'].aos-animate {
    transition-delay: 1400ms; }

body[data-aos-duration='1450'] [data-aos], [data-aos][data-aos][data-aos-duration='1450'] {
  transition-duration: 1450ms; }

body[data-aos-delay='1450'] [data-aos], [data-aos][data-aos][data-aos-delay='1450'] {
  transition-delay: 0; }
  body[data-aos-delay='1450'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1450'].aos-animate {
    transition-delay: 1450ms; }

body[data-aos-duration='1500'] [data-aos], [data-aos][data-aos][data-aos-duration='1500'] {
  transition-duration: 1500ms; }

body[data-aos-delay='1500'] [data-aos], [data-aos][data-aos][data-aos-delay='1500'] {
  transition-delay: 0; }
  body[data-aos-delay='1500'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1500'].aos-animate {
    transition-delay: 1500ms; }

body[data-aos-duration='1550'] [data-aos], [data-aos][data-aos][data-aos-duration='1550'] {
  transition-duration: 1550ms; }

body[data-aos-delay='1550'] [data-aos], [data-aos][data-aos][data-aos-delay='1550'] {
  transition-delay: 0; }
  body[data-aos-delay='1550'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1550'].aos-animate {
    transition-delay: 1550ms; }

body[data-aos-duration='1600'] [data-aos], [data-aos][data-aos][data-aos-duration='1600'] {
  transition-duration: 1600ms; }

body[data-aos-delay='1600'] [data-aos], [data-aos][data-aos][data-aos-delay='1600'] {
  transition-delay: 0; }
  body[data-aos-delay='1600'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1600'].aos-animate {
    transition-delay: 1600ms; }

body[data-aos-duration='1650'] [data-aos], [data-aos][data-aos][data-aos-duration='1650'] {
  transition-duration: 1650ms; }

body[data-aos-delay='1650'] [data-aos], [data-aos][data-aos][data-aos-delay='1650'] {
  transition-delay: 0; }
  body[data-aos-delay='1650'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1650'].aos-animate {
    transition-delay: 1650ms; }

body[data-aos-duration='1700'] [data-aos], [data-aos][data-aos][data-aos-duration='1700'] {
  transition-duration: 1700ms; }

body[data-aos-delay='1700'] [data-aos], [data-aos][data-aos][data-aos-delay='1700'] {
  transition-delay: 0; }
  body[data-aos-delay='1700'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1700'].aos-animate {
    transition-delay: 1700ms; }

body[data-aos-duration='1750'] [data-aos], [data-aos][data-aos][data-aos-duration='1750'] {
  transition-duration: 1750ms; }

body[data-aos-delay='1750'] [data-aos], [data-aos][data-aos][data-aos-delay='1750'] {
  transition-delay: 0; }
  body[data-aos-delay='1750'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1750'].aos-animate {
    transition-delay: 1750ms; }

body[data-aos-duration='1800'] [data-aos], [data-aos][data-aos][data-aos-duration='1800'] {
  transition-duration: 1800ms; }

body[data-aos-delay='1800'] [data-aos], [data-aos][data-aos][data-aos-delay='1800'] {
  transition-delay: 0; }
  body[data-aos-delay='1800'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1800'].aos-animate {
    transition-delay: 1800ms; }

body[data-aos-duration='1850'] [data-aos], [data-aos][data-aos][data-aos-duration='1850'] {
  transition-duration: 1850ms; }

body[data-aos-delay='1850'] [data-aos], [data-aos][data-aos][data-aos-delay='1850'] {
  transition-delay: 0; }
  body[data-aos-delay='1850'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1850'].aos-animate {
    transition-delay: 1850ms; }

body[data-aos-duration='1900'] [data-aos], [data-aos][data-aos][data-aos-duration='1900'] {
  transition-duration: 1900ms; }

body[data-aos-delay='1900'] [data-aos], [data-aos][data-aos][data-aos-delay='1900'] {
  transition-delay: 0; }
  body[data-aos-delay='1900'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1900'].aos-animate {
    transition-delay: 1900ms; }

body[data-aos-duration='1950'] [data-aos], [data-aos][data-aos][data-aos-duration='1950'] {
  transition-duration: 1950ms; }

body[data-aos-delay='1950'] [data-aos], [data-aos][data-aos][data-aos-delay='1950'] {
  transition-delay: 0; }
  body[data-aos-delay='1950'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1950'].aos-animate {
    transition-delay: 1950ms; }

body[data-aos-duration='2000'] [data-aos], [data-aos][data-aos][data-aos-duration='2000'] {
  transition-duration: 2000ms; }

body[data-aos-delay='2000'] [data-aos], [data-aos][data-aos][data-aos-delay='2000'] {
  transition-delay: 0; }
  body[data-aos-delay='2000'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2000'].aos-animate {
    transition-delay: 2000ms; }

body[data-aos-duration='2050'] [data-aos], [data-aos][data-aos][data-aos-duration='2050'] {
  transition-duration: 2050ms; }

body[data-aos-delay='2050'] [data-aos], [data-aos][data-aos][data-aos-delay='2050'] {
  transition-delay: 0; }
  body[data-aos-delay='2050'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2050'].aos-animate {
    transition-delay: 2050ms; }

body[data-aos-duration='2100'] [data-aos], [data-aos][data-aos][data-aos-duration='2100'] {
  transition-duration: 2100ms; }

body[data-aos-delay='2100'] [data-aos], [data-aos][data-aos][data-aos-delay='2100'] {
  transition-delay: 0; }
  body[data-aos-delay='2100'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2100'].aos-animate {
    transition-delay: 2100ms; }

body[data-aos-duration='2150'] [data-aos], [data-aos][data-aos][data-aos-duration='2150'] {
  transition-duration: 2150ms; }

body[data-aos-delay='2150'] [data-aos], [data-aos][data-aos][data-aos-delay='2150'] {
  transition-delay: 0; }
  body[data-aos-delay='2150'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2150'].aos-animate {
    transition-delay: 2150ms; }

body[data-aos-duration='2200'] [data-aos], [data-aos][data-aos][data-aos-duration='2200'] {
  transition-duration: 2200ms; }

body[data-aos-delay='2200'] [data-aos], [data-aos][data-aos][data-aos-delay='2200'] {
  transition-delay: 0; }
  body[data-aos-delay='2200'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2200'].aos-animate {
    transition-delay: 2200ms; }

body[data-aos-duration='2250'] [data-aos], [data-aos][data-aos][data-aos-duration='2250'] {
  transition-duration: 2250ms; }

body[data-aos-delay='2250'] [data-aos], [data-aos][data-aos][data-aos-delay='2250'] {
  transition-delay: 0; }
  body[data-aos-delay='2250'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2250'].aos-animate {
    transition-delay: 2250ms; }

body[data-aos-duration='2300'] [data-aos], [data-aos][data-aos][data-aos-duration='2300'] {
  transition-duration: 2300ms; }

body[data-aos-delay='2300'] [data-aos], [data-aos][data-aos][data-aos-delay='2300'] {
  transition-delay: 0; }
  body[data-aos-delay='2300'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2300'].aos-animate {
    transition-delay: 2300ms; }

body[data-aos-duration='2350'] [data-aos], [data-aos][data-aos][data-aos-duration='2350'] {
  transition-duration: 2350ms; }

body[data-aos-delay='2350'] [data-aos], [data-aos][data-aos][data-aos-delay='2350'] {
  transition-delay: 0; }
  body[data-aos-delay='2350'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2350'].aos-animate {
    transition-delay: 2350ms; }

body[data-aos-duration='2400'] [data-aos], [data-aos][data-aos][data-aos-duration='2400'] {
  transition-duration: 2400ms; }

body[data-aos-delay='2400'] [data-aos], [data-aos][data-aos][data-aos-delay='2400'] {
  transition-delay: 0; }
  body[data-aos-delay='2400'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2400'].aos-animate {
    transition-delay: 2400ms; }

body[data-aos-duration='2450'] [data-aos], [data-aos][data-aos][data-aos-duration='2450'] {
  transition-duration: 2450ms; }

body[data-aos-delay='2450'] [data-aos], [data-aos][data-aos][data-aos-delay='2450'] {
  transition-delay: 0; }
  body[data-aos-delay='2450'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2450'].aos-animate {
    transition-delay: 2450ms; }

body[data-aos-duration='2500'] [data-aos], [data-aos][data-aos][data-aos-duration='2500'] {
  transition-duration: 2500ms; }

body[data-aos-delay='2500'] [data-aos], [data-aos][data-aos][data-aos-delay='2500'] {
  transition-delay: 0; }
  body[data-aos-delay='2500'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2500'].aos-animate {
    transition-delay: 2500ms; }

body[data-aos-duration='2550'] [data-aos], [data-aos][data-aos][data-aos-duration='2550'] {
  transition-duration: 2550ms; }

body[data-aos-delay='2550'] [data-aos], [data-aos][data-aos][data-aos-delay='2550'] {
  transition-delay: 0; }
  body[data-aos-delay='2550'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2550'].aos-animate {
    transition-delay: 2550ms; }

body[data-aos-duration='2600'] [data-aos], [data-aos][data-aos][data-aos-duration='2600'] {
  transition-duration: 2600ms; }

body[data-aos-delay='2600'] [data-aos], [data-aos][data-aos][data-aos-delay='2600'] {
  transition-delay: 0; }
  body[data-aos-delay='2600'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2600'].aos-animate {
    transition-delay: 2600ms; }

body[data-aos-duration='2650'] [data-aos], [data-aos][data-aos][data-aos-duration='2650'] {
  transition-duration: 2650ms; }

body[data-aos-delay='2650'] [data-aos], [data-aos][data-aos][data-aos-delay='2650'] {
  transition-delay: 0; }
  body[data-aos-delay='2650'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2650'].aos-animate {
    transition-delay: 2650ms; }

body[data-aos-duration='2700'] [data-aos], [data-aos][data-aos][data-aos-duration='2700'] {
  transition-duration: 2700ms; }

body[data-aos-delay='2700'] [data-aos], [data-aos][data-aos][data-aos-delay='2700'] {
  transition-delay: 0; }
  body[data-aos-delay='2700'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2700'].aos-animate {
    transition-delay: 2700ms; }

body[data-aos-duration='2750'] [data-aos], [data-aos][data-aos][data-aos-duration='2750'] {
  transition-duration: 2750ms; }

body[data-aos-delay='2750'] [data-aos], [data-aos][data-aos][data-aos-delay='2750'] {
  transition-delay: 0; }
  body[data-aos-delay='2750'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2750'].aos-animate {
    transition-delay: 2750ms; }

body[data-aos-duration='2800'] [data-aos], [data-aos][data-aos][data-aos-duration='2800'] {
  transition-duration: 2800ms; }

body[data-aos-delay='2800'] [data-aos], [data-aos][data-aos][data-aos-delay='2800'] {
  transition-delay: 0; }
  body[data-aos-delay='2800'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2800'].aos-animate {
    transition-delay: 2800ms; }

body[data-aos-duration='2850'] [data-aos], [data-aos][data-aos][data-aos-duration='2850'] {
  transition-duration: 2850ms; }

body[data-aos-delay='2850'] [data-aos], [data-aos][data-aos][data-aos-delay='2850'] {
  transition-delay: 0; }
  body[data-aos-delay='2850'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2850'].aos-animate {
    transition-delay: 2850ms; }

body[data-aos-duration='2900'] [data-aos], [data-aos][data-aos][data-aos-duration='2900'] {
  transition-duration: 2900ms; }

body[data-aos-delay='2900'] [data-aos], [data-aos][data-aos][data-aos-delay='2900'] {
  transition-delay: 0; }
  body[data-aos-delay='2900'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2900'].aos-animate {
    transition-delay: 2900ms; }

body[data-aos-duration='2950'] [data-aos], [data-aos][data-aos][data-aos-duration='2950'] {
  transition-duration: 2950ms; }

body[data-aos-delay='2950'] [data-aos], [data-aos][data-aos][data-aos-delay='2950'] {
  transition-delay: 0; }
  body[data-aos-delay='2950'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2950'].aos-animate {
    transition-delay: 2950ms; }

body[data-aos-duration='3000'] [data-aos], [data-aos][data-aos][data-aos-duration='3000'] {
  transition-duration: 3000ms; }

body[data-aos-delay='3000'] [data-aos], [data-aos][data-aos][data-aos-delay='3000'] {
  transition-delay: 0; }
  body[data-aos-delay='3000'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='3000'].aos-animate {
    transition-delay: 3000ms; }

body[data-aos-easing="linear"] [data-aos], [data-aos][data-aos][data-aos-easing="linear"] {
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75); }

body[data-aos-easing="ease"] [data-aos], [data-aos][data-aos][data-aos-easing="ease"] {
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1); }

body[data-aos-easing="ease-in"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in"] {
  transition-timing-function: cubic-bezier(0.42, 0, 1, 1); }

body[data-aos-easing="ease-out"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out"] {
  transition-timing-function: cubic-bezier(0, 0, 0.58, 1); }

body[data-aos-easing="ease-in-out"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out"] {
  transition-timing-function: cubic-bezier(0.42, 0, 0.58, 1); }

body[data-aos-easing="ease-in-back"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-back"] {
  transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045); }

body[data-aos-easing="ease-out-back"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-back"] {
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275); }

body[data-aos-easing="ease-in-out-back"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-back"] {
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55); }

body[data-aos-easing="ease-in-sine"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-sine"] {
  transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715); }

body[data-aos-easing="ease-out-sine"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-sine"] {
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1); }

body[data-aos-easing="ease-in-out-sine"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-sine"] {
  transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95); }

body[data-aos-easing="ease-in-quad"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-quad"] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }

body[data-aos-easing="ease-out-quad"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-quad"] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }

body[data-aos-easing="ease-in-out-quad"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-quad"] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }

body[data-aos-easing="ease-in-cubic"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-cubic"] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }

body[data-aos-easing="ease-out-cubic"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-cubic"] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }

body[data-aos-easing="ease-in-out-cubic"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-cubic"] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }

body[data-aos-easing="ease-in-quart"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-quart"] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }

body[data-aos-easing="ease-out-quart"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-quart"] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }

body[data-aos-easing="ease-in-out-quart"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-quart"] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }

/**
 * Fade animations:
 * fade
 * fade-up, fade-down, fade-left, fade-right
 * fade-up-right, fade-up-left, fade-down-right, fade-down-left
 */
[data-aos^='fade'][data-aos^='fade'] {
  opacity: 0;
  transition-property: opacity, transform; }
  [data-aos^='fade'][data-aos^='fade'].aos-animate {
    opacity: 1;
    transform: translate3d(0, 0, 0); }

[data-aos='fade-up'] {
  transform: translate3d(0, 100px, 0); }

[data-aos='fade-down'] {
  transform: translate3d(0, -100px, 0); }

[data-aos='fade-right'] {
  transform: translate3d(-100px, 0, 0); }

[data-aos='fade-left'] {
  transform: translate3d(100px, 0, 0); }

[data-aos='fade-up-right'] {
  transform: translate3d(-100px, 100px, 0); }

[data-aos='fade-up-left'] {
  transform: translate3d(100px, 100px, 0); }

[data-aos='fade-down-right'] {
  transform: translate3d(-100px, -100px, 0); }

[data-aos='fade-down-left'] {
  transform: translate3d(100px, -100px, 0); }

/**
 * Zoom animations:
 * zoom-in, zoom-in-up, zoom-in-down, zoom-in-left, zoom-in-right
 * zoom-out, zoom-out-up, zoom-out-down, zoom-out-left, zoom-out-right
 */
[data-aos^='zoom'][data-aos^='zoom'] {
  opacity: 0;
  transition-property: opacity, transform; }
  [data-aos^='zoom'][data-aos^='zoom'].aos-animate {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(1); }

[data-aos='zoom-in'] {
  transform: scale(0.6); }

[data-aos='zoom-in-up'] {
  transform: translate3d(0, 100px, 0) scale(0.6); }

[data-aos='zoom-in-down'] {
  transform: translate3d(0, -100px, 0) scale(0.6); }

[data-aos='zoom-in-right'] {
  transform: translate3d(-100px, 0, 0) scale(0.6); }

[data-aos='zoom-in-left'] {
  transform: translate3d(100px, 0, 0) scale(0.6); }

[data-aos='zoom-out'] {
  transform: scale(1.2); }

[data-aos='zoom-out-up'] {
  transform: translate3d(0, 100px, 0) scale(1.2); }

[data-aos='zoom-out-down'] {
  transform: translate3d(0, -100px, 0) scale(1.2); }

[data-aos='zoom-out-right'] {
  transform: translate3d(-100px, 0, 0) scale(1.2); }

[data-aos='zoom-out-left'] {
  transform: translate3d(100px, 0, 0) scale(1.2); }

/**
 * Slide animations
 */
[data-aos^='slide'][data-aos^='slide'] {
  transition-property: transform; }
  [data-aos^='slide'][data-aos^='slide'].aos-animate {
    transform: translate3d(0, 0, 0); }

[data-aos='slide-up'] {
  transform: translate3d(0, 100%, 0); }

[data-aos='slide-down'] {
  transform: translate3d(0, -100%, 0); }

[data-aos='slide-right'] {
  transform: translate3d(-100%, 0, 0); }

[data-aos='slide-left'] {
  transform: translate3d(100%, 0, 0); }

/**
 * Flip animations:
 * flip-left, flip-right, flip-up, flip-down
 */
[data-aos^='flip'][data-aos^='flip'] {
  backface-visibility: hidden;
  transition-property: transform; }

[data-aos='flip-left'] {
  transform: perspective(2500px) rotateY(-100deg); }
  [data-aos='flip-left'].aos-animate {
    transform: perspective(2500px) rotateY(0); }

[data-aos='flip-right'] {
  transform: perspective(2500px) rotateY(100deg); }
  [data-aos='flip-right'].aos-animate {
    transform: perspective(2500px) rotateY(0); }

[data-aos='flip-up'] {
  transform: perspective(2500px) rotateX(-100deg); }
  [data-aos='flip-up'].aos-animate {
    transform: perspective(2500px) rotateX(0); }

[data-aos='flip-down'] {
  transform: perspective(2500px) rotateX(100deg); }
  [data-aos='flip-down'].aos-animate {
    transform: perspective(2500px) rotateX(0); }

/** PARTIALS **/
@media (min-width: 568px) {
  .hidden-xs-up {
    display: none !important; } }

@media (max-width: 666.98px) {
  .hidden-xs-down {
    display: none !important; } }

@media (min-width: 667px) {
  .hidden-sm-up {
    display: none !important; } }

@media (max-width: 767.98px) {
  .hidden-sm-down {
    display: none !important; } }

@media (min-width: 768px) {
  .hidden-md-up {
    display: none !important; } }

@media (max-width: 991.98px) {
  .hidden-md-down {
    display: none !important; } }

@media (min-width: 992px) {
  .hidden-lg-up {
    display: none !important; } }

@media (max-width: 1349.98px) {
  .hidden-lg-down {
    display: none !important; } }

@media (min-width: 1350px) {
  .hidden-xl-up {
    display: none !important; } }

@media (max-width: 1439.98px) {
  .hidden-xl-down {
    display: none !important; } }

@media (min-width: 1440px) {
  .hidden-xxl-up {
    display: none !important; } }

.hidden-xxl-down {
  display: none !important; }

body {
  font-family: "Lato", sans-serif;
  font-size: 14px;
  opacity: 0;
  transition: opacity 0.5s cubic-bezier(0.5, 0, 0.5, 1); }
  body.dom-loaded {
    opacity: 1; }

#wrapper {
  margin-top: 30px; }

.container-full-width {
  padding: 0 15px; }

a {
  text-decoration: none; }

sup {
  top: -0.3em; }

@media (min-width: 1350px) {
  .container, .l-wrapper, main > .notifications-container, .l-wrapper--boxed,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  main > .notifications-container {
    max-width: 1350px; } }

@media (min-width: 1440px) {
  .container, .l-wrapper, main > .notifications-container, .l-wrapper--boxed,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  main > .notifications-container {
    max-width: 1400px; } }

.page-header h1 {
  margin-bottom: 0; }

.page-footer:empty {
  display: none; }

.left-column > div,
.right-column > div {
  margin-bottom: 1rem; }

/* HEADER */
/* FOOTER */
.l-footer {
  background-color: #fff;
  margin-top: 1rem;
  padding-top: 1rem; }

/* MISC */
ul {
  list-style: none;
  padding-left: 0; }

h1,
.h1,
.products-section-title {
  text-transform: uppercase; }

.quick-view {
  display: inline-block; }

.form-group.form-group_forgot-password {
  margin-top: -0.5rem; }

.bootstrap-touchspin {
  border: 1px solid #ced4da; }
  .bootstrap-touchspin > input {
    max-width: 55px;
    border: none;
    box-shadow: none;
    text-align: center;
    -moz-appearance: textfield; }
    .bootstrap-touchspin > input::-webkit-outer-spin-button, .bootstrap-touchspin > input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0; }
  .bootstrap-touchspin .btn {
    position: relative;
    z-index: 2;
    color: #495057;
    background-color: #fff;
    font-size: 1.25rem;
    line-height: 0; }
  .bootstrap-touchspin .input-group-btn {
    display: flex; }
    .bootstrap-touchspin .input-group-btn:first-child {
      margin-right: -1px;
      border-right: 2px solid #ced4da; }
    .bootstrap-touchspin .input-group-btn:last-child {
      margin-left: -1px;
      border-left: 2px solid #ced4da; }

/* minimizing reflows and avoiding page jumps */
.rc {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.05);
  transition: background 150ms; }
  .rc:after {
    content: "";
    border-color: #f1f1f1;
    position: absolute;
    top: calc(50% - 1rem);
    left: calc(50% - 1rem);
    z-index: 2;
    border-right-color: transparent; }
  .rc--lazyload:after {
    display: none; }
  .rc img {
    object-fit: cover; }

.rc > * {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.rc > a {
  display: block; }

.ratio2_1 {
  padding-top: 50%; }

.ratio1_2 {
  padding-top: 200%; }

.ratio4_3 {
  padding-top: 75%; }

.ratio16_9 {
  padding-top: 56.25%; }

.ratio1_1 {
  padding-top: 100%; }

.ratio3_2 {
  padding-top: 33.33333%; }

/* collapse icons */
.icon-collapse, .link__showsummary .material-icons {
  transition: transform 0.4s ease; }

.icon-collapse[aria-expanded=true], .link__showsummary .material-icons[aria-expanded=true] {
  transform: rotateZ(180deg); }

/* CHECKOUT STEP */
.order-checkout .card-body, .order-checkout .page-content, .order-checkout .card-block, .order-checkout .block-categories, .order-checkout .address-body {
  padding-bottom: 0; }

.step-edit[aria-expanded="true"] {
  display: none; }

.step-title {
  margin-bottom: 0; }

.-complete.-reachable .step-title .done {
  display: inline-block; }

.-current .step-title .done {
  visibility: hidden; }

.-current .card-header, .-current .page-header, .-current .address-header {
  background-color: #fff; }

.accordion .card-body .card, .accordion .page-content .card, .accordion .card-block .card, .accordion .block-categories .card, .accordion .address-body .card, .accordion .card-body .page-wrapper, .accordion .page-content .page-wrapper, .accordion .card-block .page-wrapper, .accordion .block-categories .page-wrapper, .accordion .address-body .page-wrapper, .accordion .card-body .card-block, .accordion .page-content .card-block, .accordion .card-block .card-block, .accordion .block-categories .card-block, .accordion .address-body .card-block, .accordion .card-body .block-categories, .accordion .page-content .block-categories, .accordion .card-block .block-categories, .accordion .block-categories .block-categories, .accordion .address-body .block-categories, .accordion .card-body .account-links .link-item, .account-links .accordion .card-body .link-item, .accordion .page-content .account-links .link-item, .account-links .accordion .page-content .link-item, .accordion .card-block .account-links .link-item, .account-links .accordion .card-block .link-item, .accordion .block-categories .account-links .link-item, .account-links .accordion .block-categories .link-item, .accordion .address-body .account-links .link-item, .account-links .accordion .address-body .link-item, .accordion .card-body .address, .accordion .page-content .address, .accordion .card-block .address, .accordion .block-categories .address, .accordion .address-body .address {
  border: 1px solid rgba(0, 0, 0, 0.125);
  transition: border 0.1s linear; }

.accordion .card-body .card-header, .accordion .page-content .card-header, .accordion .card-block .card-header, .accordion .block-categories .card-header, .accordion .address-body .card-header, .accordion .card-body .page-header, .accordion .page-content .page-header, .accordion .card-block .page-header, .accordion .block-categories .page-header, .accordion .address-body .page-header, .accordion .card-body .address-header, .accordion .page-content .address-header, .accordion .card-block .address-header, .accordion .block-categories .address-header, .accordion .address-body .address-header {
  text-align: center; }

.accordion .selected .card, .accordion .selected .page-wrapper, .accordion .selected .card-block, .accordion .selected .block-categories, .accordion .selected .account-links .link-item, .account-links .accordion .selected .link-item, .accordion .selected .address {
  border-color: #5290b5;
  border-width: 2px; }

.nav-tabs-info {
  font-size: 1.1rem; }
  .nav-tabs-info .active {
    font-weight: bold; }

.address-selector .card, .address-selector .page-wrapper, .address-selector .card-block, .address-selector .block-categories, .address-selector .account-links .link-item, .account-links .address-selector .link-item, .address-selector .address {
  transition: border 0.1s linear; }

.address-selector .card-header, .address-selector .page-header, .address-selector .address-header,
.address-selector .card-body,
.address-selector .page-content,
.address-selector .card-block,
.address-selector .block-categories,
.address-selector .address-body {
  cursor: pointer; }

.card-body_add-address {
  padding: 0.75rem 1.25rem; }
  .card-body_add-address .material-icons {
    opacity: .8; }

.delivery-option {
  padding: 1rem;
  margin-bottom: 1rem;
  background-color: #e9ecef; }

.carrier-extra-content:not(:empty) {
  margin-bottom: 2rem; }
  .carrier-extra-content:not(:empty):empty {
    display: none; }

.carrier-name {
  font-weight: 700; }

.carrier-price {
  font-weight: bold;
  font-size: 1.25rem;
  color: #5290b5; }

.order-line:not(:last-child) {
  margin-bottom: 0.5rem; }

.order-confirmation-table {
  padding: 1rem;
  border: 3px solid rgba(0, 0, 0, 0.125); }

custom-file-label.selected:after {
  content: "" !important; }

.nav-tabs .nav-link {
  color: #000;
  font-size: 1.375rem;
  margin-bottom: 0; }
  @media (max-width: 767.98px) {
    .nav-tabs .nav-link {
      font-size: 1rem;
      padding: .5rem; } }
  .nav-tabs .nav-link.active {
    color: #000;
    font-weight: 900;
    position: relative;
    background-color: transparent; }
  .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
    border-color: transparent; }

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  border: 0;
  border-bottom: 9px solid #5290b5; }

.tab-content {
  padding-top: 1.5rem;
  font-size: 1rem; }
  .tab-content .product-description {
    max-width: 550px;
    text-align: justify; }

.products-section-title {
  text-transform: uppercase;
  text-align: center;
  display: block;
  margin-bottom: 1rem; }

.page-home > * {
  margin-bottom: 2rem; }

@media (max-width: 991.98px) {
  .modal-footer {
    flex-direction: column;
    flex-direction: column-reverse; }
    .modal-footer > .btn {
      margin: 0;
      width: 100%; }
    .modal-footer .btn:not(:last-child) {
      margin-top: 0.5rem; } }

.nav-tabs {
  flex-wrap: nowrap;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar; }
  .nav-tabs::-webkit-scrollbar {
    display: none; }
  .nav-tabs .nav-link {
    white-space: nowrap; }

.notifications-container ul {
  margin-bottom: 0; }

.js-invalid-feedback-browser:empty {
  display: none; }

.form-group label small, .form-group .label small,
.form-group label .small,
.form-group .label .small,
.form-group label .quick-view,
.form-group .label .quick-view, .form-group .label small,
.form-group .label .small,
.form-group .label .quick-view {
  font-size: 0.75rem; }

@media (min-width: 992px) {
  .page-content form,
  .checkout-form form,
  .page-wrapper--order-confirmation form {
    width: 75%;
    max-width: 450px;
    margin: 0 auto; }
  .page-wrapper--order-detail form {
    margin: unset; } }

.form-footer {
  margin-top: 1rem; }

.badge.bright {
  color: #fff; }

.alert-warning {
  background-color: #ffce28;
  border-color: #ffce28; }
  .alert-warning * {
    color: black; }

.alert-danger * {
  color: black; }

.no-js img.lazyload {
  display: none; }

.lazyload,
.lazyloading {
  opacity: 0; }

.lazyloaded {
  opacity: 1;
  transition: opacity 500ms; }

.slick-slide.lazyload {
  display: none !important; }

.slick-slide .lazyload {
  display: none !important; }

.slick-active.lazyload,
.slick-current.lazyload,
.slick-current + .slick-slide.lazyload {
  display: block !important; }

.slick-active .lazyload,
.slick-current .lazyload,
.slick-current + .slick-slide .lazyload {
  display: block !important; }

/** LAYOUT **/
.l-footer {
  background-color: #fff;
  margin-top: 1rem;
  padding-top: 1rem; }

.l-header {
  position: relative;
  background-color: #fff;
  z-index: 1020;
  transition: top 0.3s cubic-bezier(0.5, 0, 0.5, 1), border-bottom 0.3s cubic-bezier(0.5, 0, 0.5, 1);
  border-bottom: 5px solid #fff;
  margin-bottom: 15px; }
  .l-header.is-stuck {
    position: sticky;
    top: -41px; }
    @media (max-width: 991.98px) {
      .l-header.is-stuck {
        top: 0; } }
  .l-header .pre-header {
    padding: 3px 0;
    background-color: #e4e8ec; }
  .l-header .pre-header-nav > ul {
    margin-bottom: 0; }
    .l-header .pre-header-nav > ul > li {
      margin-right: 30px; }
      .l-header .pre-header-nav > ul > li > a {
        color: #000;
        transition: color 0.3s cubic-bezier(0.5, 0, 0.5, 1); }
        .l-header .pre-header-nav > ul > li > a:hover, .l-header .pre-header-nav > ul > li > a:focus {
          color: #5290b5; }
  .l-header .header-top {
    position: relative;
    flex-wrap: wrap;
    padding-top: 10px; }
    @media (max-width: 991.98px) {
      .l-header .header-top {
        padding-bottom: 5px; } }
  .l-header .logo-wrapper {
    padding-bottom: 10px;
    width: 115px;
    transition: width 0.3s cubic-bezier(0.5, 0, 0.5, 1); }
    @media (max-width: 991.98px) {
      .l-header .logo-wrapper {
        padding-bottom: 0;
        width: 75px; } }
    @media (max-width: 666.98px) {
      .l-header .logo-wrapper {
        width: 65px; } }
  .l-header .burger-wrapper {
    margin-right: -.75rem; }
    @media (max-width: 666.98px) {
      .l-header .burger-wrapper svg {
        width: 24px; } }
  .l-header .nav-wrapper {
    width: calc(100% - 115px);
    padding-left: 30px;
    transition: width 0.3s cubic-bezier(0.5, 0, 0.5, 1);
    align-items: center;
    padding: .5rem 0; }
    @media (max-width: 991.98px) {
      .l-header .nav-wrapper {
        width: calc(100% - 75px); } }
    @media (max-width: 666.98px) {
      .l-header .nav-wrapper {
        width: calc(100% - 65px); } }
  .l-header .right-actions {
    margin-left: auto; }
    @media (max-width: 991.98px) {
      .l-header .right-actions {
        margin-top: 0; } }
    .l-header .right-actions .shopping-cart {
      position: relative; }
      .l-header .right-actions .shopping-cart .cart-counter {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        top: -8px;
        right: 5px;
        width: 21px;
        height: 21px;
        border-radius: 100%;
        background-color: #5290b5;
        color: #fff;
        font-size: .75rem; }
        @media (max-width: 666.98px) {
          .l-header .right-actions .shopping-cart .cart-counter {
            right: -5px; } }
    .l-header .right-actions .action-button {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #000; }
      .l-header .right-actions .action-button:hover .icon, .l-header .right-actions .action-button:focus .icon {
        color: #2f405b; }
      .l-header .right-actions .action-button .icon {
        color: #41587c;
        transition: color 0.3s cubic-bezier(0.5, 0, 0.5, 1); }
      .l-header .right-actions .action-button .text,
      .l-header .right-actions .action-button .blockcart__label {
        text-align: center;
        color: #000;
        white-space: nowrap; }
        @media (max-width: 666.98px) {
          .l-header .right-actions .action-button .text,
          .l-header .right-actions .action-button .blockcart__label {
            font-size: 10px; } }

.header-bottom {
  flex-grow: 1;
  padding: 10px 20px;
  background-color: #41587c;
  color: #fff;
  font-weight: 700; }
  .header-bottom .title {
    font-size: 9px;
    text-transform: uppercase;
    margin-right: 20px; }
  .header-bottom .nav-alt {
    margin-bottom: 0; }
  .header-bottom ul {
    margin-bottom: 0; }
  .header-bottom li {
    position: relative; }
    .header-bottom li:not(:last-of-type) {
      margin-right: 25px; }
      .header-bottom li:not(:last-of-type)::after {
        content: "";
        position: absolute;
        width: 1px;
        height: 100%;
        right: -12px;
        top: 0;
        background-color: #5290b5; }
  .header-bottom a {
    color: #fff;
    font-family: "Source Sans 3", sans-serif;
    text-transform: uppercase;
    font-size: 14px; }
    .header-bottom a:hover, .header-bottom a:focus {
      text-decoration: underline; }
    @media (min-width: 992px) and (max-width: 1349.98px) {
      .header-bottom a {
        font-size: 12px; } }
    @media screen and (min-width: 1200px) {
      .header-bottom a {
        font-size: 14px; } }
    .header-bottom a svg {
      color: #fff;
      width: 24px;
      height: 24px;
      margin-right: 5px; }
    .header-bottom a.sub-header-menu-item span {
      position: relative;
      top: 1px; }
  .header-bottom .color {
    width: 16px;
    height: 16px;
    transform: rotate(-7deg);
    margin-right: 10px; }

.fitter + .fake-end {
  width: 50px;
  background-color: #41587c;
  margin-left: 10px; }

.mainmenu .depth-0 {
  display: flex;
  margin-bottom: 0; }
  @media (max-width: 991.98px) {
    .mainmenu .depth-0 {
      display: block; } }
  .mainmenu .depth-0 > li {
    padding: 5px 15px; }
    @media (min-width: 992px) and (max-width: 1349.98px) {
      .mainmenu .depth-0 > li {
        padding: 5px 10px; } }
    @media (max-width: 991.98px) {
      .mainmenu .depth-0 > li {
        padding: 0; } }
    .mainmenu .depth-0 > li:hover > a, .mainmenu .depth-0 > li:focus > a {
      color: #5290b5; }
    .mainmenu .depth-0 > li.menu-item-opened > .submenu-wrapper {
      display: block;
      opacity: 1;
      top: 100%; }
      @media (max-width: 991.98px) {
        .mainmenu .depth-0 > li.menu-item-opened > .submenu-wrapper {
          height: auto; } }
    .mainmenu .depth-0 > li.menu-item-opened > a {
      color: #5290b5; }
      .mainmenu .depth-0 > li.menu-item-opened > a > .chevron svg {
        transform: rotate(-270deg);
        color: #407594; }
    .mainmenu .depth-0 > li > a {
      font-weight: 700;
      text-transform: uppercase;
      font-size: 16px;
      color: #000;
      transition: color 0.3s cubic-bezier(0.5, 0, 0.5, 1); }
      @media (min-width: 992px) and (max-width: 1349.98px) {
        .mainmenu .depth-0 > li > a {
          font-size: 13px; } }
      @media screen and (min-width: 1200px) {
        .mainmenu .depth-0 > li > a {
          font-size: 16px; } }
      @media (max-width: 991.98px) {
        .mainmenu .depth-0 > li > a {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 10px 0;
          border-bottom: 1px solid #d2d2d2; } }
      .mainmenu .depth-0 > li > a > .chevron svg {
        margin-left: 5px;
        color: #5290b5;
        transform: rotate(-90deg);
        transition: color 0.3s cubic-bezier(0.5, 0, 0.5, 1), transform 0.3s cubic-bezier(0.5, 0, 0.5, 1); }
        @media (max-width: 991.98px) {
          .mainmenu .depth-0 > li > a > .chevron svg {
            flex-shrink: 0;
            margin-left: 20px; } }
    .mainmenu .depth-0 > li > .submenu-wrapper {
      position: absolute;
      padding: 30px 0;
      width: 100%;
      left: 0;
      background-color: #fff;
      z-index: 1020;
      display: none;
      opacity: 0;
      top: calc(100% - 10px);
      box-shadow: 0 15px 15px 0 rgba(0, 0, 0, 0.2);
      transition: opacity 0.3s cubic-bezier(0.5, 0, 0.5, 1), top 0.3s cubic-bezier(0.5, 0, 0.5, 1); }
      @media (max-width: 991.98px) {
        .mainmenu .depth-0 > li > .submenu-wrapper {
          position: relative;
          padding: 0;
          margin: 0 -15px;
          width: calc(100% + 30px);
          height: 0;
          box-shadow: none; } }

.mainmenu .highlighted-categories > ul {
  position: relative;
  padding-bottom: 15px;
  margin-bottom: 20px; }
  .mainmenu .highlighted-categories > ul::after {
    content: "";
    position: absolute;
    width: calc(100% - 30px);
    height: 5px;
    top: 100%;
    left: 15px;
    background-color: #f1f1f1; }

.mainmenu .depth-1,
.mainmenu .highlighted-categories > ul {
  display: flex;
  flex-wrap: wrap; }
  .mainmenu .depth-1 > li,
  .mainmenu .highlighted-categories > ul > li {
    width: 25%;
    padding: 0 15px; }
    @media (min-width: 992px) {
      .mainmenu .depth-1 > li,
      .mainmenu .highlighted-categories > ul > li {
        margin-bottom: 20px; } }
    @media (max-width: 991.98px) {
      .mainmenu .depth-1 > li,
      .mainmenu .highlighted-categories > ul > li {
        padding: 0;
        width: 100%; } }
    .mainmenu .depth-1 > li.separator,
    .mainmenu .highlighted-categories > ul > li.separator {
      width: 100%;
      padding: 0 15px; }
      @media (max-width: 991.98px) {
        .mainmenu .depth-1 > li.separator,
        .mainmenu .highlighted-categories > ul > li.separator {
          display: none; } }
      .mainmenu .depth-1 > li.separator .background,
      .mainmenu .highlighted-categories > ul > li.separator .background {
        background-color: #f1f1f1;
        width: 100%;
        height: 5px; }
    .mainmenu .depth-1 > li > a,
    .mainmenu .highlighted-categories > ul > li > a {
      color: #000;
      transition: color 0.3s cubic-bezier(0.5, 0, 0.5, 1); }
      @media (max-width: 991.98px) {
        .mainmenu .depth-1 > li > a,
        .mainmenu .highlighted-categories > ul > li > a {
          display: flex;
          align-items: center;
          padding: 10px 0;
          border-bottom: 1px solid #dfdfdf; } }
      .mainmenu .depth-1 > li > a[href]:hover, .mainmenu .depth-1 > li > a[href]:focus,
      .mainmenu .highlighted-categories > ul > li > a[href]:hover,
      .mainmenu .highlighted-categories > ul > li > a[href]:focus {
        color: #5290b5; }
      .mainmenu .depth-1 > li > a > img,
      .mainmenu .highlighted-categories > ul > li > a > img {
        margin-right: 10px; }
    .mainmenu .depth-1 > li > .menu-item-link-hassubmenu,
    .mainmenu .highlighted-categories > ul > li > .menu-item-link-hassubmenu {
      display: block;
      padding-bottom: 10px;
      margin-bottom: 10px;
      width: 100%;
      border-bottom: 1px solid #dfdfdf;
      font-weight: 600;
      text-transform: uppercase; }
      @media (max-width: 991.98px) {
        .mainmenu .depth-1 > li > .menu-item-link-hassubmenu,
        .mainmenu .highlighted-categories > ul > li > .menu-item-link-hassubmenu {
          border-bottom: none;
          text-transform: none; } }
      .mainmenu .depth-1 > li > .menu-item-link-hassubmenu .chevron,
      .mainmenu .highlighted-categories > ul > li > .menu-item-link-hassubmenu .chevron {
        display: none; }
  .mainmenu .depth-1 .topitem > a,
  .mainmenu .highlighted-categories > ul .topitem > a {
    font-weight: 700;
    text-transform: uppercase; }
    .mainmenu .depth-1 .topitem > a img,
    .mainmenu .highlighted-categories > ul .topitem > a img {
      display: none; }

.mainmenu .depth-2 > li > a {
  display: inline-block;
  margin-bottom: 5px;
  color: #000;
  transition: color 0.3s cubic-bezier(0.5, 0, 0.5, 1); }
  .mainmenu .depth-2 > li > a:hover, .mainmenu .depth-2 > li > a:focus, .mainmenu .depth-2 > li > a:active {
    color: #5290b5; }
  .mainmenu .depth-2 > li > a::before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
    width: 8px;
    height: 2px;
    background-color: #5290b5; }

.mainmenu .about-menu .depth-1 {
  max-width: 75%; }
  @media (max-width: 1349.98px) {
    .mainmenu .about-menu .depth-1 {
      max-width: none; } }
  @media (min-width: 992px) {
    .mainmenu .about-menu .depth-1 > li {
      width: 33%; } }
  .mainmenu .about-menu .depth-1 > li > a {
    display: block;
    margin-bottom: 5px;
    color: #000;
    transition: color 0.3s cubic-bezier(0.5, 0, 0.5, 1); }
    .mainmenu .about-menu .depth-1 > li > a:hover, .mainmenu .about-menu .depth-1 > li > a:focus, .mainmenu .about-menu .depth-1 > li > a:active {
      color: #5290b5; }
    .mainmenu .about-menu .depth-1 > li > a::before {
      content: "";
      display: inline-block;
      vertical-align: middle;
      margin-right: 5px;
      width: 8px;
      height: 2px;
      background-color: #5290b5; }

/** COMPONENT **/
.ps-alert-error {
  margin-bottom: 0; }

.ps-alert-error .item,
.ps-alert-success .item {
  align-items: center;
  border: 2px #dc3545 solid;
  display: flex;
  background-color: #dc3545;
  margin-bottom: 1rem; }
  .ps-alert-error .item i,
  .ps-alert-success .item i {
    border: 15px #dc3545 solid;
    display: flex; }
    .ps-alert-error .item i svg,
    .ps-alert-success .item i svg {
      background-color: #dc3545;
      width: 24px;
      height: 24px; }
  .ps-alert-error .item p,
  .ps-alert-success .item p {
    background-color: #fff;
    margin: 0;
    padding: 1rem;
    width: 100%; }

.ps-alert-success {
  padding: 0.25rem 0.25rem 2.75rem 0.25rem; }
  .ps-alert-success .item {
    border-color: #28a745;
    background-color: #28a745; }
    .ps-alert-success .item i {
      border-color: #28a745; }
      .ps-alert-success .item i svg {
        background-color: #28a745; }

[data-aos='fade-up'] {
  transform: translate3d(0, 20px, 0); }

.banner-wrapper {
  margin-bottom: 50px; }
  @media (min-width: 992px) {
    .banner-wrapper.no-image .banner {
      background: #41587c; } }
  .banner-wrapper .banner {
    background: #41587c; }
    @media (min-width: 992px) {
      .banner-wrapper .banner {
        background: linear-gradient(90deg, #41587c 0%, #41587c 67%, white 67%); } }
  .banner-wrapper .content {
    width: 67%;
    color: #fff; }
    @media (max-width: 991.98px) {
      .banner-wrapper .content {
        order: 2;
        width: 100%; } }
  .banner-wrapper .image {
    width: 33%;
    margin-left: auto; }
    @media (max-width: 991.98px) {
      .banner-wrapper .image {
        order: 1;
        width: 100%; } }
  .banner-wrapper .left,
  .banner-wrapper .right {
    width: 50%;
    padding: 30px; }
    @media (max-width: 991.98px) {
      .banner-wrapper .left,
      .banner-wrapper .right {
        width: 100%; } }
  @media (max-width: 991.98px) {
    .banner-wrapper .left {
      padding: 30px 30px 0 30px;
      margin-bottom: 15px; } }
  @media (max-width: 991.98px) {
    .banner-wrapper .right {
      padding: 0 30px 30px 30px; } }
  .banner-wrapper .title {
    height: calc(50% - 22px); }
    @media (max-width: 991.98px) {
      .banner-wrapper .title {
        height: auto; } }
  .banner-wrapper .ctas .cta-wrapper:not(:last-of-type) {
    margin-bottom: 15px; }
  .banner-wrapper .banner-title {
    font-size: 24px;
    font-weight: 700;
    text-transform: none; }
  .banner-wrapper .banner-desc-title {
    font-size: 22px;
    font-weight: 700; }
  .banner-wrapper .image img {
    width: 100%;
    height: 100%;
    object-fit: cover; }

.block-reassurance-inst-wrapper {
  height: 100%; }

.block-reassurance-inst {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #ffce28;
  background-image: url(../img/static/installer.png);
  background-position: calc(100% + 120px) center;
  background-repeat: no-repeat;
  padding: 30px;
  height: 100%;
  color: #000; }
  .block-reassurance-inst .title {
    font-size: 2.625rem;
    font-weight: 700;
    line-height: 1;
    font-family: "Source Sans 3", sans-serif; }
  .block-reassurance-inst .description {
    font-weight: 700;
    font-size: 1rem; }
    .block-reassurance-inst .description ul {
      font-weight: 700; }
      .block-reassurance-inst .description ul li::before {
        background-color: #fff; }
  .block-reassurance-inst .button {
    align-self: flex-start;
    padding: 10px; }

.social-sharing--product {
  margin: 1rem 0; }

.modal .social-sharing--product {
  margin: 0; }

.social-share-btn {
  width: 2.5rem;
  height: 2.5rem;
  display: block;
  margin: 0 0.5rem;
  border-radius: 100%;
  background-repeat: no-repeat;
  background-position: center center; }
  .social-share-btn.facebook {
    background-image: url(../img/facebook.svg); }
  .social-share-btn.twitter {
    background-image: url(../img/twitter.svg); }
  .social-share-btn.linkedin {
    background-image: url(../img/linkedin.svg); }
  .social-share-btn.pinterest {
    background-image: url(../img/pinterest.svg); }
  .social-share-btn.rss {
    background-image: url(../img/rss.svg); }
  .social-share-btn.youtube {
    background-image: url(../img/youtube.svg); }
  .social-share-btn.vimeo {
    background-image: url(../img/vimeo.svg); }
  .social-share-btn.instagram {
    background-image: url(../img/instagram.svg); }

.social-share-btn--product {
  background-size: 80%;
  background-color: #fff;
  border-radius: 50%;
  transition: box-shadow 0.3s ease-in-out; }
  .social-share-btn--product.facebook {
    background-image: url(../img/facebook-gray.svg); }
    .social-share-btn--product.facebook:hover {
      background-image: url(../img/facebook-blue.svg); }
  .social-share-btn--product.twitter {
    background-image: url(../img/twitter-gray.svg); }
    .social-share-btn--product.twitter:hover {
      background-image: url(../img/twitter-blue.svg); }
  .social-share-btn--product.googleplus {
    background-image: url(../img/gplus-gray.svg); }
    .social-share-btn--product.googleplus:hover {
      background-image: url(../img/gplus-blue.svg); }
  .social-share-btn--product.pinterest {
    background-image: url(../img/pinterest-gray.svg); }
    .social-share-btn--product.pinterest:hover {
      background-image: url(../img/pinterest-blue.svg); }

.social-share-btn--footer {
  transition: background-color 0.3s ease-in-out; }
  .social-share-btn--footer:hover {
    background-color: #5290b5; }

nav.breadcrumbs .breadcrumb {
  padding: 0;
  background-color: transparent; }

nav.breadcrumbs .breadcrumb-item {
  font-size: 12px; }
  nav.breadcrumbs .breadcrumb-item.active {
    color: #000; }
  nav.breadcrumbs .breadcrumb-item + .breadcrumb-item::before {
    content: ">";
    color: #99a1a6; }
  nav.breadcrumbs .breadcrumb-item a {
    color: #99a1a6;
    transition: color 0.3s cubic-bezier(0.5, 0, 0.5, 1); }
    nav.breadcrumbs .breadcrumb-item a:hover {
      color: #565656; }

.button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  padding: 10px 20px;
  font-size: .812rem;
  text-transform: uppercase;
  font-weight: 700;
  transition: background-color 0.3s cubic-bezier(0.5, 0, 0.5, 1), border 0.3s cubic-bezier(0.5, 0, 0.5, 1), color 0.3s cubic-bezier(0.5, 0, 0.5, 1); }
  .button.button-primary {
    background-color: #5290b5;
    border: 1px solid #5290b5;
    text-align: center; }
    .button.button-primary:hover, .button.button-primary:focus, .button.button-primary:active {
      color: #fff;
      background-color: #407594;
      border: 1px solid #407594; }
    .button.button-primary.disabled, .button.button-primary:disabled, .button.button-primary[disabled] {
      opacity: .5;
      cursor: not-allowed; }
      .button.button-primary.disabled:hover, .button.button-primary.disabled:focus, .button.button-primary.disabled:active, .button.button-primary:disabled:hover, .button.button-primary:disabled:focus, .button.button-primary:disabled:active, .button.button-primary[disabled]:hover, .button.button-primary[disabled]:focus, .button.button-primary[disabled]:active {
        background-color: #5290b5;
        border: 1px solid #5290b5; }
  .button.button-white {
    background-color: #fff;
    border: 1px solid #fff;
    text-align: center;
    color: #000; }
    .button.button-white:hover, .button.button-white:focus, .button.button-white:active {
      color: #fff;
      background-color: transparent;
      border: 1px solid #fff; }
    .button.button-white.color-primary {
      color: #5290b5; }
      .button.button-white.color-primary:hover, .button.button-white.color-primary:focus, .button.button-white.color-primary:active {
        color: #fff; }
    .button.button-white.disabled {
      opacity: .5;
      cursor: not-allowed; }
      .button.button-white.disabled:hover, .button.button-white.disabled:focus, .button.button-white.disabled:active {
        background-color: transparent;
        border: 1px solid #fff; }
  .button.button-outline-black {
    border: 1px solid #000;
    background-color: #fff;
    color: #000; }
    .button.button-outline-black:hover, .button.button-outline-black:focus, .button.button-outline-black:active {
      background-color: #000;
      color: #fff; }
      .button.button-outline-black:hover svg, .button.button-outline-black:focus svg, .button.button-outline-black:active svg {
        color: #fff; }
    .button.button-outline-black svg {
      margin-top: -4px;
      margin-right: 20px;
      color: #2e3a59;
      transition: color 0.3s cubic-bezier(0.5, 0, 0.5, 1); }
  .button.button-outline-white {
    border: 1px solid #fff; }
    .button.button-outline-white:hover, .button.button-outline-white:focus, .button.button-outline-white:active {
      background-color: #fff;
      color: #5290b5; }
      .button.button-outline-white:hover svg, .button.button-outline-white:focus svg, .button.button-outline-white:active svg {
        color: #5290b5; }
    .button.button-outline-white svg {
      margin-top: -4px;
      margin-right: 20px;
      color: #fff;
      transition: color 0.3s cubic-bezier(0.5, 0, 0.5, 1); }
  .button.button-outline-primary {
    border: 1px solid #5290b5;
    background-color: #fff;
    color: #5290b5; }
    .button.button-outline-primary:hover, .button.button-outline-primary:focus, .button.button-outline-primary:active {
      background-color: #5290b5;
      color: #fff; }
      .button.button-outline-primary:hover svg, .button.button-outline-primary:focus svg, .button.button-outline-primary:active svg {
        color: #fff; }
  .button.button-outline-tertiary {
    border: 1px solid #41587c;
    background-color: transparent;
    color: #41587c; }
    .button.button-outline-tertiary:hover, .button.button-outline-tertiary:focus, .button.button-outline-tertiary:active {
      background-color: #41587c;
      color: #fff; }
      .button.button-outline-tertiary:hover svg, .button.button-outline-tertiary:focus svg, .button.button-outline-tertiary:active svg {
        color: #fff; }
  .button.button-outline-white-tertiary {
    border: 1px solid #fff;
    background-color: transparent; }
    .button.button-outline-white-tertiary:hover, .button.button-outline-white-tertiary:focus, .button.button-outline-white-tertiary:active {
      background-color: #fff;
      color: #41587c; }
      .button.button-outline-white-tertiary:hover svg, .button.button-outline-white-tertiary:focus svg, .button.button-outline-white-tertiary:active svg {
        color: #41587c; }
    .button.button-outline-white-tertiary svg {
      margin-top: -4px;
      margin-right: 20px;
      color: #fff;
      transition: color 0.3s cubic-bezier(0.5, 0, 0.5, 1); }
  .button.button-white-icon {
    background-color: #fff;
    border: 1px solid #fff;
    padding: 0 15px 0 0;
    height: 44px;
    color: #5290b5;
    text-transform: uppercase; }
    .button.button-white-icon:hover, .button.button-white-icon:focus {
      color: #fff;
      background-color: transparent;
      border-color: #fff; }
    .button.button-white-icon .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 44px;
      height: 100%;
      margin-right: 15px; }
  .button.button-primary-icon {
    background-color: #5290b5;
    border: 1px solid #5290b5;
    padding: 0 15px 0 0;
    height: 44px;
    text-transform: uppercase; }
    .button.button-primary-icon:hover, .button.button-primary-icon:focus {
      color: #fff;
      background-color: #407594;
      border-color: #407594; }
    .button.button-primary-icon .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 44px;
      height: 100%;
      margin-right: 15px; }
    .button.button-primary-icon.disabled, .button.button-primary-icon:disabled, .button.button-primary-icon[disabled] {
      opacity: .5;
      cursor: not-allowed; }
      .button.button-primary-icon.disabled:hover, .button.button-primary-icon.disabled:focus, .button.button-primary-icon.disabled:active, .button.button-primary-icon:disabled:hover, .button.button-primary-icon:disabled:focus, .button.button-primary-icon:disabled:active, .button.button-primary-icon[disabled]:hover, .button.button-primary-icon[disabled]:focus, .button.button-primary-icon[disabled]:active {
        background-color: #5290b5;
        border: 1px solid #5290b5; }
  .button.button-outline-primary-icon {
    background-color: #fff;
    border: 1px solid #5290b5;
    padding: 0 15px 0 0;
    height: 44px;
    color: #5290b5;
    text-transform: uppercase; }
    .button.button-outline-primary-icon:hover, .button.button-outline-primary-icon:focus, .button.button-outline-primary-icon:active {
      background-color: #5290b5;
      color: #fff; }
    .button.button-outline-primary-icon .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 44px;
      height: 100%;
      margin-right: 15px; }
  .button.button-outline-white-tertiary-icon {
    background-color: transparent;
    border: 1px solid #fff;
    padding: 0 15px 0 0;
    height: 44px;
    color: #fff;
    text-transform: uppercase; }
    .button.button-outline-white-tertiary-icon:hover, .button.button-outline-white-tertiary-icon:focus {
      color: #41587c;
      background-color: #fff;
      border-color: #fff; }
    .button.button-outline-white-tertiary-icon .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 44px;
      height: 100%;
      margin-right: 15px; }
  .button.button-border-icon {
    font-size: .75rem;
    color: #5290b5;
    border-bottom: 1px solid #41587c;
    font-weight: 900;
    display: flex;
    align-items: end;
    padding: 5px 0;
    margin-bottom: 15px;
    font-style: italic; }
    .button.button-border-icon svg {
      color: #41587c;
      transition: color 0.3s cubic-bezier(0.5, 0, 0.5, 1); }
    .button.button-border-icon .text {
      flex: 1; }
    .button.button-border-icon:hover, .button.button-border-icon:focus, .button.button-border-icon:active {
      color: #407594;
      border-bottom: 1px solid #2f405b; }
      .button.button-border-icon:hover svg, .button.button-border-icon:focus svg, .button.button-border-icon:active svg {
        color: #2f405b; }
    .button.button-border-icon:focus, .button.button-border-icon:active {
      border-color: transparent; }

.button-outline-primary-icon.button-lighting-specialist {
  margin-right: 30px;
  justify-content: unset; }
  @media (max-width: 1349.98px) {
    .button-outline-primary-icon.button-lighting-specialist {
      flex-direction: column;
      font-size: 14px;
      font-weight: 400;
      text-transform: none;
      background-color: transparent;
      border: 0;
      padding: 0;
      margin-right: .5rem;
      width: 60px;
      height: unset; }
      .button-outline-primary-icon.button-lighting-specialist:hover, .button-outline-primary-icon.button-lighting-specialist:focus {
        background-color: transparent;
        border: 0; }
        .button-outline-primary-icon.button-lighting-specialist:hover .icon, .button-outline-primary-icon.button-lighting-specialist:focus .icon {
          color: #2f405b;
          border: 0; }
      .button-outline-primary-icon.button-lighting-specialist .icon {
        color: #41587c;
        background-color: transparent;
        margin-right: 0;
        transition: color 0.3s cubic-bezier(0.5, 0, 0.5, 1);
        width: unset;
        height: unset; }
      .button-outline-primary-icon.button-lighting-specialist svg {
        width: 25px;
        height: 24px; } }
  @media (max-width: 666.98px) {
    .button-outline-primary-icon.button-lighting-specialist {
      width: 40px; } }
  @media (max-width: 666.98px) {
    .button-outline-primary-icon.button-lighting-specialist .text {
      font-size: 10px; } }
  @media (max-width: 1349.98px) {
    .button-outline-primary-icon.button-lighting-specialist .text.desktop {
      display: none; } }
  .button-outline-primary-icon.button-lighting-specialist .text.mobile {
    color: #000; }
    @media (min-width: 1350px) {
      .button-outline-primary-icon.button-lighting-specialist .text.mobile {
        display: none; } }

.btn-fitter {
  margin-left: 10px;
  background-color: #5290b5;
  border: 1px solid #5290b5;
  padding: .25rem 1rem;
  height: 41px;
  color: #fff;
  text-transform: uppercase;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: .25rem;
  min-width: 150px; }
  @media (max-width: 991.98px) {
    .btn-fitter {
      margin-left: 0;
      width: 100%;
      padding: 1rem 0;
      align-items: end; } }
  .btn-fitter:hover, .btn-fitter:focus {
    color: #fff;
    background-color: #407594;
    border-color: #407594; }
  .btn-fitter span {
    position: relative;
    top: 2px; }

.card-blog-item {
  height: 100%;
  background-color: #f1f1f1;
  transition: box-shadow 0.5s cubic-bezier(0.5, 0, 0.5, 1); }
  .card-blog-item:hover {
    box-shadow: rgba(50, 50, 93, 0.25) 0 50px 100px -20px, rgba(0, 0, 0, 0.3) 0 30px 60px -30px; }
    .card-blog-item:hover .image {
      height: 33%; }
      .card-blog-item:hover .image img {
        width: 100%;
        object-fit: cover;
        object-position: center;
        transform: scale(1.05); }
    .card-blog-item:hover .card-title a {
      color: #565656; }
  .card-blog-item .image {
    overflow: hidden;
    height: 33%; }
    .card-blog-item .image img {
      width: 100%;
      aspect-ratio: 16 / 7;
      object-fit: cover;
      object-position: center;
      transition: transform 0.5s cubic-bezier(0.5, 0, 0.5, 1); }
  .card-blog-item .content {
    flex-grow: 1;
    padding: 15px 20px; }
  .card-blog-item .pre-title {
    margin-bottom: 10px; }
  .card-blog-item .card-title {
    font-size: 18px;
    font-weight: 900;
    transition: color 0.3s cubic-bezier(0.5, 0, 0.5, 1); }
    .card-blog-item .card-title a {
      color: #000;
      transition: color 0.3s cubic-bezier(0.5, 0, 0.5, 1); }
  .card-blog-item .news-description {
    margin-bottom: 15px; }
  .card-blog-item .cta {
    margin-top: auto; }

.row-cards-twelve {
  margin-left: -5px;
  margin-right: -5px;
  row-gap: 30px; }
  .row-cards-twelve > div {
    padding: 5px; }
    @media screen and (min-width: 400px) and (max-width: 767px) {
      .row-cards-twelve > div {
        flex: 0 0 50%;
        max-width: 50%; } }

.row-cards-twelve-slider .slick-slide > div {
  height: 50%; }
  .row-cards-twelve-slider .slick-slide > div:first-of-type {
    height: calc(50% - 30px);
    margin-bottom: 30px; }

.cat-item {
  padding: 5px; }

.card-cat {
  position: relative; }
  .card-cat:hover img, .card-cat:focus img {
    transform: scale(1.05); }
  .card-cat .image {
    border: 1px solid #d2d2d2;
    overflow: hidden; }
  .card-cat img {
    width: 100%;
    aspect-ratio: 4 / 3;
    object-fit: cover;
    transition: transform 0.5s cubic-bezier(0.5, 0, 0.5, 1); }
  .card-cat .card-title {
    margin-bottom: 10px;
    bottom: 0;
    font-size: 14px;
    color: #000;
    text-transform: uppercase;
    font-weight: 700;
    font-family: "Raleway", sans-serif; }
  .card-cat .text-link {
    display: flex;
    justify-content: center;
    margin-top: 15px;
    text-align: center; }

#cart .cart-item .media .discount {
  color: #fff; }
  #cart .cart-item .media .discount.discount_type_destockage {
    background-color: #ffce28;
    color: #fff; }
  #cart .cart-item .media .discount.discount_type_nouveaute {
    background-color: #5290b5;
    color: #fff; }
  #cart .cart-item .media .discount.discount_type_promoencours {
    background-color: #5290b5;
    color: #fff; }
  #cart .cart-item .media .discount.discount_type_remiseverte {
    background-color: #76B864;
    color: #fff; }
  #cart .cart-item .media .discount.discount_type_findeserie {
    background-color: #ffce28;
    color: #fff; }

.product-line-actions {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .product-line-actions .input-group.bootstrap-touchspin {
    max-width: 130px; }

.product-line-grid .product-line__title {
  margin-bottom: 10px;
  display: inline-block;
  color: #000;
  font-size: 16px; }

.product-line-grid .product-price {
  display: flex;
  align-items: center; }
  .product-line-grid .product-price .discount {
    font-size: 0.875rem;
    line-height: 1; }

.product-line-grid .product-discount {
  margin-right: 0.5rem; }

.product-line__img {
  margin-right: 1rem; }

@media (max-width: 991.98px) {
  .product-line__img {
    width: 100px;
    height: auto; }
  .product-line-actions {
    justify-content: flex-end;
    margin-top: 1rem; }
    .product-line-actions .product-price {
      margin: 0 2rem; } }

@media (max-width: 991.98px) {
  .product-line__img {
    width: 50px; }
  .product-line-actions .product-price {
    margin: 0 1rem; } }

.cart__card-body {
  position: relative; }
  .cart__card-body .cart__card-loader {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    align-items: center;
    justify-content: center;
    display: none;
    background-color: rgba(255, 255, 255, 0.85);
    opacity: 0;
    transition: opacity 0.15s linear;
    z-index: 3; }
  .cart__card-body.is--loading .cart__card-loader {
    display: flex;
    opacity: 1; }

.nav-tabs--center {
  justify-content: center; }

@media (max-width: 991.98px) {
  .checkout {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 2;
    padding: 1rem;
    background-color: rgba(0, 0, 0, 0.4); }
    .checkout a {
      width: 100%; } }

.product-line__body {
  padding-right: 0.25rem; }

.total-value, .card-body--summary .cart-total {
  background-color: #f1f1f1;
  font-weight: 700; }

.table__title-head {
  margin-bottom: 0;
  font-size: 1rem; }

/* CART */
.promo-code {
  margin-left: -1.25rem;
  margin-right: -1.25rem; }

.promo-code-alert {
  display: none; }

.cancel-promo {
  text-align: center; }

.card-body .separator, .page-content .separator, .card-block .separator, .block-categories .separator, .address-body .separator {
  margin-left: -1.25rem;
  margin-right: -1.25rem; }

.cart-summary-line {
  display: flex;
  justify-content: space-between; }

.cart-total {
  font-weight: bold; }

.card-body--summary .cart-total {
  padding: 0.25rem; }
  .card-body--summary .cart-total .label {
    margin-bottom: 0; }

.card-body--summary.card-body, .card-body--summary.page-content, .card-body--summary.card-block, .card-body--summary.block-categories, .card-body--summary.address-body {
  padding-bottom: 0; }

.media-list__item:not(:last-child) {
  margin-bottom: 0.5rem; }

.link__showsummary[aria-expanded=true] .material-icons {
  transform: rotateZ(180deg); }

.cart-item:not(:last-child) {
  border-bottom: 1px solid #f1f1f1;
  margin-bottom: 1rem;
  padding-bottom: 1rem; }

.product-line-info {
  margin-bottom: 0.5rem; }

.promo-code-button {
  text-align: center; }
  .promo-code-button .collapse-button[aria-expanded=true] {
    display: none; }

.promo-highlighted {
  text-align: center;
  font-size: 0.875rem;
  margin-top: 1rem;
  margin-bottom: 0.5rem; }

.promo-discounts {
  font-weight: 700;
  text-align: center;
  color: #495057; }
  .promo-discounts .code {
    color: #41587c; }

.form__add-voucher {
  margin-bottom: 1rem; }

.promo-code__content {
  padding: 1.25rem;
  background-color: #e6e6e6; }

.promo-code .collapse-button {
  text-align: center;
  display: block;
  color: #5290b5; }

.block-categories a {
  color: #212529; }

.category-sub__item {
  display: block;
  width: 100%; }

.category-sub__item--0 {
  border-bottom: 1px solid #f1f1f1;
  padding: 0.25rem 0; }

.category-sub__item:not(.category-sub__item--0):not(.category-sub__item--1) {
  padding-left: 0.25rem; }

.block-categories .collapse-icons .remove {
  display: none; }

.block-categories .collapse-icons[aria-expanded=true].remove {
  display: block; }

.block-categories .collapse-icons[aria-expanded=true].add {
  display: none; }

.checkout-process #checkout-personal-information-step a,
.checkout-process #checkout-addresses-step a,
.checkout-process #checkout-payment-step a {
  color: #5290b5;
  transition: color 0.3s cubic-bezier(0.5, 0, 0.5, 1), background-color 0.3s cubic-bezier(0.5, 0, 0.5, 1); }
  .checkout-process #checkout-personal-information-step a:hover, .checkout-process #checkout-personal-information-step a:focus, .checkout-process #checkout-personal-information-step a:active,
  .checkout-process #checkout-addresses-step a:hover,
  .checkout-process #checkout-addresses-step a:focus,
  .checkout-process #checkout-addresses-step a:active,
  .checkout-process #checkout-payment-step a:hover,
  .checkout-process #checkout-payment-step a:focus,
  .checkout-process #checkout-payment-step a:active {
    color: #407594; }

.checkout-process .carrier-price {
  color: #5290b5; }

.order-checkout .link__showsummary {
  color: #5290b5;
  transition: color 0.3s cubic-bezier(0.5, 0, 0.5, 1); }
  .order-checkout .link__showsummary:hover, .order-checkout .link__showsummary:focus, .order-checkout .link__showsummary:active {
    color: #407594; }

.account-links > a {
  margin-bottom: 1rem; }
  .account-links > a:hover .link-item,
  .account-links > a a:focus .link-item, .account-links > a:active .link-item {
    color: #565656; }
    .account-links > a:hover .link-item i,
    .account-links > a a:focus .link-item i, .account-links > a:active .link-item i {
      color: #407594; }

.account-links .link-item {
  padding: 1rem;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  height: 100%;
  color: #000;
  transition: color 0.3s cubic-bezier(0.5, 0, 0.5, 1); }
  .account-links .link-item i {
    margin: 0 auto 1rem;
    font-size: 3rem;
    color: #5290b5;
    transition: color 0.3s cubic-bezier(0.5, 0, 0.5, 1); }

.address {
  height: 100%; }
  .address.billing-address {
    background-color: #f1f1f1; }

.address-header {
  background-color: transparent; }

.address-footer {
  display: flex;
  justify-content: space-between; }
  .address-footer a {
    color: #41587c;
    transition: color 0.3s cubic-bezier(0.5, 0, 0.5, 1); }
    .address-footer a:hover, .address-footer a:focus, .address-footer a:active {
      color: #2f405b; }

.form--100 {
  min-width: 100%;
  width: 100%; }

@media (max-width: 991.98px) {
  .side-nav {
    margin-bottom: 30px; } }

.side-nav ul {
  border: 1px solid #dfdfdf;
  margin-bottom: 0; }

.side-nav li:not(:last-of-type) {
  border-bottom: 1px solid #dfdfdf; }

.side-nav a {
  display: flex;
  padding: 10px;
  width: 100%;
  color: #565656;
  font-weight: 700;
  transition: color 0.3s cubic-bezier(0.5, 0, 0.5, 1), background-color 0.3s cubic-bezier(0.5, 0, 0.5, 1); }
  .side-nav a:hover, .side-nav a:focus, .side-nav a:active, .side-nav a.current {
    background-color: #5290b5;
    color: #fff; }
    .side-nav a:hover i, .side-nav a:focus i, .side-nav a:active i, .side-nav a.current i {
      color: #fff; }

.side-nav i {
  color: #5290b5;
  margin-right: 10px;
  transition: color 0.3s cubic-bezier(0.5, 0, 0.5, 1); }

.footer-ctas a:not(:last-of-type) {
  margin-right: 10px; }

.eci {
  margin-top: 1.5rem; }
  .eci .tag {
    display: inline-flex;
    background-color: #41587c;
    color: #fff;
    text-transform: uppercase;
    padding: .5rem; }
  .eci .grey-box {
    background-color: #f1f1f1;
    padding: .5rem;
    border-radius: .4rem;
    margin-top: 1rem; }
  .eci .eci-price {
    font-size: 1.25rem;
    color: #80cc28;
    vertical-align: bottom; }
    .eci .eci-price strong {
      color: #000; }
  .eci .see-more-link {
    text-align: right;
    width: 100%;
    display: inline-block;
    text-transform: uppercase;
    color: #5290b5;
    font-size: .75rem; }
    .eci .see-more-link:hover, .eci .see-more-link:focus {
      color: #407594; }
  .eci img {
    width: 1.5rem; }

.search-filters {
  padding: 0; }
  .search-filters .facet-block hr {
    margin: 0; }
  .search-filters .facet-header .facet-title {
    display: flex;
    align-items: center;
    padding: 15px 0;
    color: #000;
    font-size: 16px;
    font-weight: 900; }
    .search-filters .facet-header .facet-title.collapsed .minus {
      display: none; }
    .search-filters .facet-header .facet-title.collapsed .plus {
      display: block; }
    .search-filters .facet-header .facet-title .plus {
      display: none; }
    .search-filters .facet-header .facet-title .icon {
      flex-shrink: 0;
      margin-left: auto; }
  .search-filters .collapse-facet .custom-control:last-of-type {
    margin-bottom: 15px; }
  .search-filters .active-filters a:not(:last-of-type) {
    margin-right: 5px;
    margin-bottom: 5px; }
  .search-filters .ui-slider {
    width: calc(100% - 7px); }
  .search-filters .clear-all-wrapper {
    margin-top: 15px; }

div.faceted-overlay {
  z-index: 1040; }

@font-face {
  font-family: 'Lato', sans-serif;
  src: url("../fonts/Lato-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Lato', sans-serif;
  src: url("../fonts/Lato-Light.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Lato', sans-serif;
  src: url("../fonts/Lato-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Lato', sans-serif;
  src: url("../fonts/Lato-Black.woff2") format("woff2");
  font-weight: 900;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Josefin Sans', sans-serif;
  src: url("../fonts/JosefinSans-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Josefin Sans', sans-serif;
  src: url("../fonts/JosefinSans-Light.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Raleway', sans-serif;
  src: url("../fonts/Raleway-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Raleway', sans-serif;
  src: url("../fonts/Raleway-Light.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Raleway', sans-serif;
  src: url("../fonts/Raleway-SemiBold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Raleway', sans-serif;
  src: url("../fonts/Raleway-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Raleway', sans-serif;
  src: url("../fonts/Raleway-Black.woff2") format("woff2");
  font-weight: 900;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Source Sans 3', sans-serif;
  src: url("../fonts/SourceSans3-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Source Sans 3', sans-serif;
  src: url("../fonts/SourceSans3-Light.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Source Sans 3', sans-serif;
  src: url("../fonts/SourceSans3-SemiBold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Source Sans 3', sans-serif;
  src: url("../fonts/SourceSans3-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Source Sans 3', sans-serif;
  src: url("../fonts/SourceSans3-Black.woff2") format("woff2");
  font-weight: 900;
  font-style: normal;
  font-display: swap; }

.pre-footer .item-check {
  display: flex;
  align-items: center;
  color: #000;
  font-size: 1rem;
  margin-bottom: 20px;
  margin-right: 20px; }

.footer-top {
  background-color: #41587c;
  color: #fff;
  padding: 40px 0; }
  .footer-top .title {
    font-size: 1.375rem;
    font-weight: 900; }
    @media (max-width: 991.98px) {
      .footer-top .title {
        margin-top: 20px; }
        .footer-top .title.first-title {
          margin-top: 0; } }
  .footer-top .social-sharing {
    display: flex;
    width: 100%; }
    .footer-top .social-sharing a:first-child {
      margin-left: 0; }
    .footer-top .social-sharing a:last-child {
      margin-right: 0; }
    @media (min-width: 992px) {
      .footer-top .social-sharing {
        height: 100%;
        background-image: url(../img/static/shape.png);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: 75% 0; } }
    @media (min-width: 1350px) {
      .footer-top .social-sharing {
        background-position: 63% 0; } }

.footer-bottom {
  display: flex;
  justify-content: center;
  align-items: center; }
  .footer-bottom .item {
    color: #000; }
    .footer-bottom .item + .item::before {
      content: "|";
      margin: 0 10px; }
  .footer-bottom .link {
    color: #000; }

.footer-nav {
  background-color: #f1f1f1;
  padding: 40px 0; }
  @media (max-width: 991.98px) {
    .footer-nav {
      padding: 20px 0; } }
  .footer-nav .footer-content .top-menu-footer {
    display: flex;
    margin: 0 -10px; }
    @media (max-width: 991.98px) {
      .footer-nav .footer-content .top-menu-footer {
        flex-direction: column; } }
  .footer-nav .footer-content .item-top {
    width: 20%;
    padding: 0 10px;
    font-size: 1.25rem; }
    @media (max-width: 991.98px) {
      .footer-nav .footer-content .item-top {
        width: 100%;
        font-size: 1rem; } }
    .footer-nav .footer-content .item-top[aria-expanded="true"] .chevron svg {
      transform: rotate(180deg); }
    .footer-nav .footer-content .item-top .link {
      color: #000;
      padding: 4px 0;
      display: inline-block; }
  .footer-nav .footer-content .submenu .item-sub {
    font-weight: 400;
    font-size: .875rem; }

.l-footer {
  color: #6c757d; }
  .l-footer .cms-page-link,
  .l-footer .account-list a {
    color: #000;
    padding: 4px 0;
    display: inline-block; }
    .l-footer .cms-page-link:hover,
    .l-footer .account-list a:hover {
      color: #5290b5; }

.footer-container {
  margin-top: 1rem; }

.footer__title {
  color: #000;
  display: block;
  font-weight: 600;
  margin-bottom: 0.5rem;
  font-size: 1.25rem; }
  .footer__title:visited, .footer__title:hover {
    color: #000; }
  .footer__title--mobile {
    display: none;
    margin-bottom: 0;
    padding: 0.5rem 0;
    border-bottom: 1px solid #f1f1f1; }
    .footer__title--mobile:after {
      content: "\e313";
      font-family: 'Material Icons';
      font-feature-settings: 'liga' 1;
      position: absolute;
      right: 15px;
      transition: .3s transform ease-in-out;
      font-size: 1.5rem; }
    .footer__title--mobile[data-toggle="collapse"][aria-expanded="true"]:after {
      transform: rotate(180deg); }

@media (max-width: 991.98px) {
  .footer__title--desktop {
    display: none; }
  .footer__title--mobile {
    display: block; } }

.footer__copyright {
  text-align: center;
  font-size: 0.875rem; }

.header__nav {
  align-items: center; }

.header-nav__nav2 {
  display: flex;
  justify-content: flex-end;
  align-items: center; }

.header-mobile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%; }

.header-mobile__user-cart {
  display: flex;
  justify-content: flex-end;
  align-items: center; }

.header__top {
  display: flex;
  justify-content: space-between;
  position: static; }

div.custom-control .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #5290b5;
  background-color: #5290b5; }

input.password {
  padding: .375rem 46px .375rem .75rem; }
  input.password + .input-group-btn {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    z-index: 4; }
    input.password + .input-group-btn .btn {
      border-radius: 0; }
      input.password + .input-group-btn .btn .icon {
        display: none; }
        input.password + .input-group-btn .btn .icon.displayed {
          display: inline-block; }

.text-notice-form {
  font-size: .875rem;
  color: #99a1a6; }

.form-group label, .form-group .label,
.form-group .label {
  margin-bottom: .25rem; }

.form-group-accept {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0; }

.carousel a {
  display: block; }
  .carousel a:not(:first-child) {
    display: none; }

.slider-caption {
  position: absolute;
  top: 10%;
  left: 90px;
  margin-bottom: 0;
  color: #fff;
  max-width: 340px; }

.slick-slide:not(.slick-current) .img-carousel.lazyload {
  display: none !important; }

.slick-slide figure {
  position: absolute;
  top: 0; }

.slick-dots {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px; }
  .slick-dots li {
    margin: 0 8px; }
    .slick-dots li button {
      padding: 0;
      font-size: 0;
      line-height: 0;
      display: block;
      width: 8px;
      height: 8px;
      color: transparent;
      border: 0;
      border-radius: 100%;
      background: #5290b5; }
    .slick-dots li.slick-active button {
      background-color: #ffce28;
      width: 10px;
      height: 10px; }

.slick-arrow:focus, .slick-arrow:active {
  box-shadow: none !important; }

@media screen and (max-width: 768px) {
  .carousel-home {
    max-width: 350px;
    margin-left: auto;
    margin-right: auto; } }

.carousel-home__right {
  margin-top: 1rem; }
  @media (min-width: 1350px) {
    .carousel-home__right {
      margin-top: 0; } }

.carousel-home .carousel {
  height: 100%;
  min-height: 417px; }
  @media (min-width: 992px) {
    .carousel-home .carousel {
      min-height: 317px; } }
  .carousel-home .carousel .img-carousel {
    aspect-ratio: 904 / 317; }
  .carousel-home .carousel .img-carousel-mobile {
    aspect-ratio: 333 / 413; }
  .carousel-home .carousel .slide-item {
    position: relative;
    height: 100%; }
    .carousel-home .carousel .slide-item::after {
      content: "";
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      top: 0;
      background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 40%, #000000 115%); }
  .carousel-home .carousel .slick-dots {
    position: absolute;
    bottom: 5px;
    left: 50%;
    transform: translateX(-50%); }
    .carousel-home .carousel .slick-dots li.slick-active button {
      background-color: #ffce28;
      box-shadow: none; }
  .carousel-home .carousel .slick-arrow {
    color: #fff; }
    .carousel-home .carousel .slick-arrow svg {
      width: 25px;
      height: 25px; }
    .carousel-home .carousel .slick-arrow.slick-next span {
      transform: rotate(-90deg); }
    .carousel-home .carousel .slick-arrow.slick-prev span {
      transform: rotate(90deg); }
  .carousel-home .carousel .slick-list,
  .carousel-home .carousel .slick-track,
  .carousel-home .carousel .slick-slide,
  .carousel-home .carousel .slick-slide > div,
  .carousel-home .carousel .slick-slide .rc {
    height: 100%; }
  .carousel-home .carousel .slick-slide img {
    width: 100%;
    height: 100%;
    object-fit: cover; }
  .carousel-home .carousel .slider-caption {
    position: absolute;
    top: auto;
    bottom: 10%;
    left: 90px;
    margin-bottom: 0;
    color: #fff;
    max-width: 400px;
    height: auto;
    min-height: 200px;
    z-index: 1; }
    @media (max-width: 991.98px) {
      .carousel-home .carousel .slider-caption {
        bottom: 60px;
        left: 68px;
        width: 100%;
        min-height: auto;
        padding: 0 51px; } }
    @media (max-width: 666.98px) {
      .carousel-home .carousel .slider-caption {
        left: 0; } }
  .carousel-home .carousel .slider-title {
    font-size: 2.25rem;
    font-weight: 900;
    margin-bottom: 0; }
    @media (max-width: 991.98px) {
      .carousel-home .carousel .slider-title {
        font-size: 1.3rem; } }
  .carousel-home .carousel .slider-description {
    font-size: .875rem;
    color: #fff; }
  .carousel-home .carousel .slider-link {
    color: #fff;
    font-size: .75rem;
    font-weight: 700;
    text-transform: uppercase;
    display: flex;
    align-items: center; }
    .carousel-home .carousel .slider-link span {
      border-bottom: 1px solid #fff;
      font-style: italic; }
    .carousel-home .carousel .slider-link svg {
      padding-top: 5px;
      transform: rotate(180deg); }

.slick-slide:not(.slick-current) .img-carousel.lazyload {
  display: none !important; }

.slick-slide figure {
  position: absolute;
  top: 0; }

.modal-filters .btn-outline-success {
  color: #000;
  border-color: #5290b5;
  background-color: #5290b5;
  font-weight: 700; }
  .modal-filters .btn-outline-success:hover {
    background-color: #407594;
    border-color: #407594;
    color: #000; }

.modal-menu .sub-nav {
  margin: 1rem 0;
  background-color: #41587c;
  padding: 1rem; }
  .modal-menu .sub-nav .title {
    font-size: 9px;
    text-transform: uppercase;
    margin-right: 20px;
    margin-bottom: 5px; }
  .modal-menu .sub-nav ul {
    margin-bottom: 0; }
  .modal-menu .sub-nav li:not(:last-of-type) a {
    border-bottom: 1px solid #fff; }
  .modal-menu .sub-nav li a {
    display: flex;
    padding: 10px 0;
    color: #fff;
    font-family: "Source Sans 3", sans-serif;
    text-transform: uppercase; }
    .modal-menu .sub-nav li a svg {
      width: 24px;
      height: 24px;
      margin-right: 5px; }
  .modal-menu .sub-nav .color {
    width: 16px;
    height: 16px;
    transform: rotate(-7deg);
    margin-right: 10px; }

.modal-menu .pre-header-nav {
  display: flex;
  justify-content: center;
  margin-top: 1rem; }
  .modal-menu .pre-header-nav ul {
    margin-bottom: 0;
    width: 100%; }
    @media (max-width: 991.98px) {
      .modal-menu .pre-header-nav ul {
        flex-direction: column; } }
  @media (max-width: 991.98px) {
    .modal-menu .pre-header-nav li {
      border-top: 1px solid #ffce28;
      height: 2rem;
      display: flex;
      flex-direction: column;
      justify-content: center; }
      .modal-menu .pre-header-nav li:last-of-type {
        border-top: 1px solid #ffce28;
        border-bottom: 1px solid #ffce28; }
      .modal-menu .pre-header-nav li:not(:last-of-type) {
        margin-right: 7px; } }
  .modal-menu .pre-header-nav li a {
    transition: color 0.3s cubic-bezier(0.5, 0, 0.5, 1);
    color: #000; }
    .modal-menu .pre-header-nav li a:hover {
      color: #5290b5; }

.notifications-container .alert {
  border: 0;
  color: #fff; }

.notifications-container .alert-success {
  background-color: #2ea745; }

.modal.fade .modal-dialog__offcanvas {
  width: 350px;
  height: 100%;
  margin: 0;
  max-width: 90%;
  position: absolute;
  top: 0;
  transform: translateX(-100%);
  transition: transform .3s ease-out; }

.modal.show .modal-dialog__offcanvas {
  transform: translateX(0); }

.modal.fade .modal-dialog__offcanvas.modal-dialog__offcanvas--right {
  right: 0;
  transform: translateX(100%); }

.modal.show .modal-dialog__offcanvas.modal-dialog__offcanvas--right {
  transform: translateX(0); }

.modal-dialog__offcanvas .modal-content {
  height: inherit;
  border-width: 0;
  border-radius: 0; }

.modal-dialog__offcanvas .modal-header {
  border-radius: inherit; }

.modal-dialog__offcanvas .modal-body {
  overflow-y: auto;
  height: inherit; }

.pagination-wrapper {
  height: 58px;
  border-top: 1px solid #d2d2d2;
  border-bottom: 1px solid #d2d2d2; }
  .pagination-wrapper .pagination-summary {
    align-items: center;
    width: 100%; }
  .pagination-wrapper nav {
    flex-shrink: 0; }

ul.pagination, .product-comments__pagination ul {
  margin-bottom: 0; }
  ul.pagination li, .product-comments__pagination ul li {
    height: 100%; }
    ul.pagination li:not(:last-of-type), .product-comments__pagination ul li:not(:last-of-type) {
      margin-right: 2px; }
    ul.pagination li:hover .page-link, .product-comments__pagination ul li:hover .page-link, ul.pagination .product-comments__pagination li:hover span, .product-comments__pagination ul.pagination li:hover span, .product-comments__pagination ul li:hover span {
      background-color: #d2d2d2; }
    ul.pagination li.active .page-link, .product-comments__pagination ul li.active .page-link, ul.pagination .product-comments__pagination li.active span, .product-comments__pagination ul.pagination li.active span, .product-comments__pagination ul li.active span {
      color: #fff;
      background-color: #5290b5; }
  ul.pagination .page-link, .product-comments__pagination ul .page-link, ul.pagination .product-comments__pagination li span, .product-comments__pagination li ul.pagination span, .product-comments__pagination ul li span, .product-comments__pagination li ul span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    padding: 0;
    border: 0;
    font-size: 16px;
    font-weight: 700;
    color: #000;
    transition: background-color 0.3s cubic-bezier(0.5, 0, 0.5, 1); }

@media (min-width: 992px) {
  .product-comment-modal .modal-dialog {
    max-width: 774px; } }

.product-comment-modal .modal-footer {
  margin-right: -1rem;
  margin-left: -1rem; }

.product-comment-modal .modal-body {
  padding-bottom: 0; }

.modal--product-comment-posted-modal .modal-header,
.modal--report-comment-posted .modal-header {
  color: #2ea745; }

.product-comment-post-error-message .modal-header {
  color: #dc3545; }

.grade-stars,
.comments-note {
  display: flex; }

.star {
  color: #f1ab00; }

/** product list **/
.product-list-reviews .grade-stars {
  justify-content: center; }

.product-list-reviews .star .material-icons {
  font-size: 1rem; }

/** product page **/
.product-comments {
  margin-top: 2rem;
  margin-bottom: 2rem; }

.product-comments-additional-info {
  margin: 1rem 0; }

.comments-note {
  margin-bottom: 0.5rem; }
  .comments-note .grade-stars {
    margin-left: 0.25rem; }

.comments__header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }

.product-comment-list-item {
  background: #fff;
  margin-bottom: 1rem;
  padding: 1rem; }

.comment-infos .grade-stars {
  margin-bottom: 1rem; }

.comment-infos .comment-date,
.comment-infos .comment-author {
  font-size: 0.875rem; }

/* comment list */
.product-comments__pagination li:not(.disabled) span:not(.current) {
  cursor: pointer; }

/* MODAL COMMENT */
/* radio input star ratig */
.starability-basic {
  display: block;
  position: relative;
  width: 150px;
  min-height: 60px;
  padding: 0;
  border: none;
  min-height: auto; }
  .starability-basic > input {
    position: absolute;
    margin-right: -100%;
    opacity: 0; }
  .starability-basic > input:checked ~ label, .starability-basic > input:checked ~ .label,
  .starability-basic > input:focus ~ label,
  .starability-basic > input:focus ~ .label {
    background-position: 0 0; }
  .starability-basic > input:checked + label, .starability-basic > input:checked + .label,
  .starability-basic > input:focus + label,
  .starability-basic > input:focus + .label {
    background-position: 0 -30px; }
  .starability-basic > input[disabled]:hover + label, .starability-basic > input[disabled]:hover + .label {
    cursor: default; }
  .starability-basic > input:not([disabled]):hover ~ label, .starability-basic > input:not([disabled]):hover ~ .label {
    background-position: 0 0; }
  .starability-basic > input:not([disabled]):hover + label, .starability-basic > input:not([disabled]):hover + .label {
    background-position: 0 -30px; }
  .starability-basic > input:not([disabled]):hover + label::before, .starability-basic > input:not([disabled]):hover + .label::before {
    opacity: 1; }
  
  .starability-basic > input:focus + label,
  .starability-basic > input:focus + .label {
    outline: 1px dotted #999; }
  .starability-basic .starability-focus-ring {
    position: absolute;
    left: 0;
    width: 100%;
    height: 30px;
    outline: 2px dotted #999;
    pointer-events: none;
    opacity: 0; }
  .starability-basic > .input-no-rate:focus ~ .starability-focus-ring {
    opacity: 1; }
  .starability-basic > label, .starability-basic > .label {
    position: relative;
    display: inline-block;
    float: left;
    width: 30px;
    height: 30px;
    font-size: 0.1em;
    color: transparent;
    cursor: pointer;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAA8CAMAAABGivqtAAAAxlBMVEUAAACZmZn2viTHuJ72viOampqampr1viSampr3vySampqdnZ34wiX1vSSampr1vSOZmZmampr1viT2vSOampr2viT2viSampr2viSampr2vyX4vyWbm5v3vSSdnZ32wSadnZ36wCWcnJyZmZn/wSr/2ySampr2vSP2viSZmZn2vSSZmZn2vST2viSampr2viSbm5ubm5uZmZn1vSSampqbm5v2vSWampqampr3vSf5wiT5vyagoKD/xCmkpKT/yCSZmZn1vSO4V2dEAAAAQHRSTlMA+vsG9fO6uqdgRSIi7+3q39XVqZWVgnJyX09HPDw1NTAwKRkYB+jh3L6+srKijY2Ef2lpYllZUU5CKigWFQ4Oneh1twAAAZlJREFUOMuV0mdzAiEQBmDgWq4YTWIvKRqT2Htv8P//VJCTGfYQZnw/3fJ4tyO76KE0m1b2fZu+U/pu4QGlA7N+Up5PIz9d+cmkbSrSNr9seT3GKeNYIyeO5j16S28exY5suK0U/QKmmeCCX6xs22hJLVkitMImxCvEs8EG3SCRCN/ViFPqnq5epIzZ07QJJvkM9Tkz1xnkmXbfSvR7f4H8AtXBkLGj74mMvjM1+VHZpAZ4LM4K/LBWEI9jwP71v1ZEQ6dyvQMf8A/1pmdZnKce/VH1iIsdte4U8VEtY23xOujxtFpWDgKbfjD2YeEhY0OzfjGeLyO/XfnNpAcmcjDwKOXRfU1IyiTRyEkaiz67pb9oJHJb9vVqKfgjLBPyF5Sq9T0KmSUhQmtiQrJGPHVi0DoSabj31G2gW3buHd0pY85lNdcCk8xlNDPXMuSyNiwl+theIb9C7RLIpKvviYy+M6H8qGwSAp6Is19+GP6KxwnggJ/kq6Jht5rnRQA4z9zyRRaXssvyqp5I6Vutv0vkpJaJtnjpz/8B19ytIayazLoAAAAASUVORK5CYII=");
    background-repeat: no-repeat;
    background-position: 0 -30px; }
    .starability-basic > label::before, .starability-basic > .label::before {
      content: '';
      position: absolute;
      display: block;
      height: 30px;
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAA8CAMAAABGivqtAAAAxlBMVEUAAACZmZn2viTHuJ72viOampqampr1viSampr3vySampqdnZ34wiX1vSSampr1vSOZmZmampr1viT2vSOampr2viT2viSampr2viSampr2vyX4vyWbm5v3vSSdnZ32wSadnZ36wCWcnJyZmZn/wSr/2ySampr2vSP2viSZmZn2vSSZmZn2vST2viSampr2viSbm5ubm5uZmZn1vSSampqbm5v2vSWampqampr3vSf5wiT5vyagoKD/xCmkpKT/yCSZmZn1vSO4V2dEAAAAQHRSTlMA+vsG9fO6uqdgRSIi7+3q39XVqZWVgnJyX09HPDw1NTAwKRkYB+jh3L6+srKijY2Ef2lpYllZUU5CKigWFQ4Oneh1twAAAZlJREFUOMuV0mdzAiEQBmDgWq4YTWIvKRqT2Htv8P//VJCTGfYQZnw/3fJ4tyO76KE0m1b2fZu+U/pu4QGlA7N+Up5PIz9d+cmkbSrSNr9seT3GKeNYIyeO5j16S28exY5suK0U/QKmmeCCX6xs22hJLVkitMImxCvEs8EG3SCRCN/ViFPqnq5epIzZ07QJJvkM9Tkz1xnkmXbfSvR7f4H8AtXBkLGj74mMvjM1+VHZpAZ4LM4K/LBWEI9jwP71v1ZEQ6dyvQMf8A/1pmdZnKce/VH1iIsdte4U8VEtY23xOujxtFpWDgKbfjD2YeEhY0OzfjGeLyO/XfnNpAcmcjDwKOXRfU1IyiTRyEkaiz67pb9oJHJb9vVqKfgjLBPyF5Sq9T0KmSUhQmtiQrJGPHVi0DoSabj31G2gW3buHd0pY85lNdcCk8xlNDPXMuSyNiwl+theIb9C7RLIpKvviYy+M6H8qGwSAp6Is19+GP6KxwnggJ/kq6Jht5rnRQA4z9zyRRaXssvyqp5I6Vutv0vkpJaJtnjpz/8B19ytIayazLoAAAAASUVORK5CYII=");
      background-position: 0 30px;
      pointer-events: none;
      opacity: 0; }
    .starability-basic > label:nth-of-type(5)::before, .starability-basic > .label:nth-of-type(5)::before {
      width: 120px;
      left: -120px; }
    .starability-basic > label:nth-of-type(4)::before, .starability-basic > .label:nth-of-type(4)::before {
      width: 90px;
      left: -90px; }
    .starability-basic > label:nth-of-type(3)::before, .starability-basic > .label:nth-of-type(3)::before {
      width: 60px;
      left: -60px; }
    .starability-basic > label:nth-of-type(2)::before, .starability-basic > .label:nth-of-type(2)::before {
      width: 30px;
      left: -30px; }
    .starability-basic > label:nth-of-type(1)::before, .starability-basic > .label:nth-of-type(1)::before {
      width: 0px;
      left: 0px; }
    @media screen and (min-resolution: 192dpi) {
      .starability-basic > label, .starability-basic > .label {
        background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAB4CAMAAACZ62E6AAABAlBMVEUAAACZmZmampr2vSObm5v/yiufn5+ampr1viP1viSZmZn2viOZmZmampqampr2viSampqampqcnJz5vyScnJz3wSf/wyn/xiujo6Oqqqr/0C/1vSOampr2viP2viOampr2viP2vST2viOampqampqampr1vyP3viSampr2vyT4vyX3viSbm5ubm5v5wCT8xSmgoKCampqampr3vyb2wiWenp72viOampqZmZmampr2viP2viP1viSampqbm5v2vyT3viObm5v4vyadnZ34wSSbm5v2viSZmZn2viP2vST2viP2viT1viOZmZn2viT2viX3viT3vyb2vyOZmZn1vSOZmZlNN+fKAAAAVHRSTlMA9uz4PQwS8O7r5+fTw4yMelw2MB0dFRELBgbS+/Hfu7uxqKWdg4N7ZmZMPi8pKRgPs0w7Nhb14drKw6Gck21tXkNDIyMZ1rDLycTBtaqVknlfV0sGP8ZwAAADW0lEQVRYw9zWvYqDQBSG4TPDoCAqKhYKQgoVLFaIgZCkiCBBUqVazv3fyu4aEXWdM85Uy779A+LP58AfTQgw73AwtxFiZIwbxMbUfuB3H4b49YNfZrbGodoI52+cm9hH9sbZwwAXOFbo2zjDsSzWxnecuuvaM8MpdtbEPs7y9azF5phZWrjERaWOPdpLbB81cICrgv3W4mvMLbU6RmFQeA5u5HhFEEbHLdWLsMxvHJXxW16Goh+ZqPyny1Az5j79SsCJoWHsBNAxQ9sNF26bWFuMC8v1LY+mmeTadjaqtaNnnXoxWBcde1nNWnzdb68xrOqvu22/MTzuPutujpJ122NvluSb8tTWk85CclDZQwLS0oa2TQpEKacsJy0kSJaQOKJxROKKxhWJ7zS+k9ijsUdim8Y2ZWNUFBP4pMKfOv8onX9WrsI5gd3VVLXtatxcuU0znGUHCUAS2DgrS6mT6hTzrXEjfIZj5Dk2xKkihqm4wKlQfQRqalhUP9UHo3FIPAG/Et44JVLsDDf0JHmB3OEByOwZES8hSAsviGjBdh3ylh6plmMnW4IyAUVJWcE/76vTell1EIaiMBwIAcWBA9GC0lIdKFXQQUsHVVCklN7ojf3+z3JOxYqK2TH555+K6CJJQtRbr9XtDmCnjH0AX9Va8J+liIMvDtRsCk2pEs6hKVexR2g7KuDihwt5a9MfprY0fkLXU9ZmFLpoJolN6GXKWWfZx0tHCocwKJSxC22ItYUEjmBUJHFjfYz1xQxlfaLiZsBExq2IPtbkNbLtOwwuGgjTLkH43mYtSzam7+1Bsr3nm5uExBQUozEh9V7N7uvmwZcqdpm0C6vJW63bZEuXtbrV2zpDzhrpYLBWMnY1mjV7JWFtMio7zbWniWFxvHnWm1yGxXmOPXP+L3YV2ysjnNhaZNeMcHPvuL27BMnVMaujljBAYyje4niH4g2ONyh+4PiB4gOODyjWcKxh1gZBNoJjEY4R/BLhF4IDEQ4QPBoEoyxH4+bxrUsHyxwxQlg0WHXqYifVLmo67cKY/UtaXFxBV26TLjuHrkp8BPJTMij1xQejdkgO24nf7dBOCRcbzQuNOR9Qs64GzzrfQa8It2oFAA6Zrga9xEeq1KHmLUHIiCAWInsg1x/MLqkMsItF8QAAAABJRU5ErkJggg==");
        background-size: 30px auto; } }
  @media screen and (-ms-high-contrast: active) {
    .starability-basic {
      width: auto; }
      .starability-basic > input {
        position: static;
        margin-right: 0;
        opacity: 1; }
      .starability-basic .input-no-rate {
        display: none; }
      .starability-basic > label, .starability-basic > .label {
        display: inline;
        float: none;
        width: auto;
        height: auto;
        font-size: 1em;
        color: inherit;
        background: none; }
        .starability-basic > label::before, .starability-basic > .label::before, .starability-basic > label::after, .starability-basic > .label::after {
          display: none; } }

.product-criterion {
  display: flex;
  justify-items: center;
  justify-content: flex-end;
  margin-bottom: 1rem; }
  .product-criterion__name {
    width: auto;
    font-size: 1rem;
    margin-bottom: 0;
    margin-right: 0.25rem; }
  .product-criterion label, .product-criterion .label {
    margin-bottom: 0; }

.products {
  align-items: stretch;
  margin-left: -5px;
  margin-right: -5px; }

.product-miniature {
  padding: 0 5px; }

@media (min-width: 667px) {
  .product-miniature {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; } }

@media (min-width: 992px) {
  .product-miniature {
    flex: 0 0 25%;
    max-width: 25%; }
  .layout-full-width .product-miniature {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; } }

@media (max-width: 320px) {
  .product-miniature {
    flex: 0 0 100%;
    max-width: 100%; } }

.slick-slide .product-miniature {
  max-width: 100%; }

/* Product miniature*/
.card-product.card, .card-product.page-wrapper, .card-product.card-block, .card-product.block-categories, .account-links .card-product.link-item, .card-product.address {
  height: 100%;
  position: relative;
  border: 0;
  transition: box-shadow 0.5s cubic-bezier(0.5, 0, 0.5, 1); }
  .card-product.card:hover, .card-product.page-wrapper:hover, .card-product.card-block:hover, .card-product.block-categories:hover, .account-links .card-product.link-item:hover, .card-product.address:hover {
    box-shadow: rgba(0, 0, 0, 0.1) 0 4px 6px -1px, rgba(0, 0, 0, 0.06) 0 2px 4px -1px; }
    .card-product.card:hover .highlighted-informations, .card-product.page-wrapper:hover .highlighted-informations, .card-product.card-block:hover .highlighted-informations, .card-product.block-categories:hover .highlighted-informations, .account-links .card-product.link-item:hover .highlighted-informations, .card-product.address:hover .highlighted-informations {
      transform: translateY(0); }
    .card-product.card:hover .quick-view, .card-product.page-wrapper:hover .quick-view, .card-product.card-block:hover .quick-view, .card-product.block-categories:hover .quick-view, .account-links .card-product.link-item:hover .quick-view, .card-product.address:hover .quick-view {
      visibility: visible;
      opacity: 1; }
    .card-product.card:hover .thumbnail img, .card-product.page-wrapper:hover .thumbnail img, .card-product.card-block:hover .thumbnail img, .card-product.block-categories:hover .thumbnail img, .account-links .card-product.link-item:hover .thumbnail img, .card-product.address:hover .thumbnail img {
      transform: scale(1.05); }
  .card-product.card .discount, .card-product.page-wrapper .discount, .card-product.card-block .discount, .card-product.block-categories .discount, .account-links .card-product.link-item .discount, .card-product.address .discount {
    display: none; }
  .card-product.card .rc, .card-product.page-wrapper .rc, .card-product.card-block .rc, .card-product.block-categories .rc, .account-links .card-product.link-item .rc, .card-product.address .rc {
    background-image: linear-gradient(180deg, #fff 0%, #ededed 100%); }
  .card-product.card .brand-logo, .card-product.page-wrapper .brand-logo, .card-product.card-block .brand-logo, .card-product.block-categories .brand-logo, .account-links .card-product.link-item .brand-logo, .card-product.address .brand-logo {
    position: absolute;
    top: 5px;
    left: 5px;
    max-width: 100px;
    max-height: 50px; }
    .card-product.card .brand-logo img, .card-product.page-wrapper .brand-logo img, .card-product.card-block .brand-logo img, .card-product.block-categories .brand-logo img, .account-links .card-product.link-item .brand-logo img, .card-product.address .brand-logo img {
      max-width: 100%;
      max-height: 100%; }
  .card-product.card .thumbnail, .card-product.page-wrapper .thumbnail, .card-product.card-block .thumbnail, .card-product.block-categories .thumbnail, .account-links .card-product.link-item .thumbnail, .card-product.address .thumbnail {
    border: 1px solid #dfdfdf;
    border-bottom: 5px solid #dfdfdf; }
    .card-product.card .thumbnail img, .card-product.page-wrapper .thumbnail img, .card-product.card-block .thumbnail img, .card-product.block-categories .thumbnail img, .account-links .card-product.link-item .thumbnail img, .card-product.address .thumbnail img {
      transition: transform 0.5s cubic-bezier(0.5, 0, 0.5, 1); }
  .card-product.card .card-body, .card-product.page-wrapper .card-body, .card-product.card-block .card-body, .card-product.block-categories .card-body, .account-links .card-product.link-item .card-body, .card-product.address .card-body, .card-product.card .page-content, .card-product.page-wrapper .page-content, .card-product.card-block .page-content, .card-product.block-categories .page-content, .account-links .card-product.link-item .page-content, .card-product.address .page-content, .card-product.card .card-block, .card-product.page-wrapper .card-block, .card-product.card-block .card-block, .card-product.block-categories .card-block, .account-links .card-product.link-item .card-block, .card-product.address .card-block, .card-product.card .block-categories, .card-product.page-wrapper .block-categories, .card-product.card-block .block-categories, .card-product.block-categories .block-categories, .account-links .card-product.link-item .block-categories, .card-product.address .block-categories, .card-product.card .address-body, .card-product.page-wrapper .address-body, .card-product.card-block .address-body, .card-product.block-categories .address-body, .account-links .card-product.link-item .address-body, .card-product.address .address-body {
    padding: 10px; }
  .card-product.card .references, .card-product.page-wrapper .references, .card-product.card-block .references, .card-product.block-categories .references, .account-links .card-product.link-item .references, .card-product.address .references {
    margin-top: auto;
    color: #565656;
    font-size: 12px;
    border-bottom: 1px solid #d2d2d2; }
  .card-product.card .price-pre-mention, .card-product.page-wrapper .price-pre-mention, .card-product.card-block .price-pre-mention, .card-product.block-categories .price-pre-mention, .account-links .card-product.link-item .price-pre-mention, .card-product.address .price-pre-mention {
    font-size: 10px; }
  .card-product.card .price, .card-product.page-wrapper .price, .card-product.card-block .price, .card-product.block-categories .price, .account-links .card-product.link-item .price, .card-product.address .price {
    color: #5290b5;
    font-size: 18px;
    font-weight: 900; }
  .card-product.card .regular-price, .card-product.page-wrapper .regular-price, .card-product.card-block .regular-price, .card-product.block-categories .regular-price, .account-links .card-product.link-item .regular-price, .card-product.address .regular-price {
    color: #99a1a6;
    text-decoration: line-through;
    font-size: 12px; }
  .card-product.card .quick-view, .card-product.page-wrapper .quick-view, .card-product.card-block .quick-view, .card-product.block-categories .quick-view, .account-links .card-product.link-item .quick-view, .card-product.address .quick-view {
    position: absolute;
    bottom: 5px;
    right: 5px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s cubic-bezier(0.5, 0, 0.5, 1); }

.card-img-top {
  overflow: hidden; }
  .card-img-top .product-thumbnail img {
    width: 100%; }

.highlighted-informations {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  transform: translateY(100%);
  transition: transform .3s; }
  .card-product:hover .highlighted-informations {
    transform: translateY(0); }

.quick-view {
  cursor: pointer; }

.product-flags {
  position: absolute;
  top: 1px;
  margin-bottom: 0;
  z-index: 1;
  right: 1px;
  display: flex;
  flex-direction: column;
  align-items: flex-end; }

.page-content--product .product-flags {
  left: 0; }

.product-flag-item {
  display: flex;
  align-items: center;
  gap: 0.25rem; }
  .product-flag-item.discount_type_destockage span {
    color: #ffce28; }
  .product-flag-item.discount_type_destockage .product-flag {
    background: #ffce28; }
  .product-flag-item.discount_type_nouveaute span {
    color: #5290b5; }
  .product-flag-item.discount_type_nouveaute .product-flag {
    background: #5290b5; }
  .product-flag-item.discount_type_promoencours span {
    color: #5290b5; }
  .product-flag-item.discount_type_promoencours .product-flag {
    background: #5290b5; }
  .product-flag-item.discount_type_remiseverte span {
    color: #76B864; }
  .product-flag-item.discount_type_remiseverte .product-flag {
    background: #76B864; }
  .product-flag-item.discount_type_findeserie span {
    color: #ffce28; }
  .product-flag-item.discount_type_findeserie .product-flag {
    background: #ffce28; }

.product-flag {
  top: 0;
  padding: 0.25rem;
  color: #fff;
  background: #5290b5;
  text-transform: uppercase;
  font-weight: 600; }
  .product-flag:not(:last-of-type) {
    margin-bottom: 5px; }
  .product-flag.discount-product {
    background: #5290b5; }

.color {
  width: 1.25rem;
  height: 1.25rem;
  display: inline-block;
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(0, 0, 0, 0.05);
  cursor: pointer;
  background-size: contain; }

.product-title {
  color: #000;
  font-size: 14px;
  font-weight: 900;
  text-decoration: none;
  text-transform: uppercase;
  margin-bottom: 5px; }
  .product-title a {
    color: #000;
    transition: color 0.3s cubic-bezier(0.5, 0, 0.5, 1); }
    .product-title a:hover {
      color: #565656; }
  .card-product:hover .product-title {
    color: #565656; }
    .card-product:hover .product-title a {
      color: #565656; }

.slider-wrapper-content {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }
  @media (min-width: 992px) {
    .slider-wrapper-content {
      width: 60%; } }
  @media (min-width: 1350px) {
    .slider-wrapper-content {
      width: 50%; } }

.content-info-product {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }
  @media (min-width: 992px) {
    .content-info-product {
      width: 40%; } }
  @media (min-width: 1350px) {
    .content-info-product {
      width: 50%; } }
  .content-info-product .discount-label {
    text-transform: uppercase;
    font-size: 1.325rem;
    font-weight: 600; }
    .content-info-product .discount-label.discount_type_destockage {
      color: #ffce28; }
    .content-info-product .discount-label.discount_type_nouveaute {
      color: #5290b5; }
    .content-info-product .discount-label.discount_type_promoencours {
      color: #5290b5; }
    .content-info-product .discount-label.discount_type_remiseverte {
      color: #76B864; }
    .content-info-product .discount-label.discount_type_findeserie {
      color: #ffce28; }

.images-container {
  overflow: hidden;
  display: flex;
  flex-direction: column; }
  @media (min-width: 768px) {
    .images-container {
      display: flex;
      flex-direction: row; } }
  .images-container .thumbnail-container {
    width: 100%;
    display: none; }
    @media (min-width: 768px) {
      .images-container .thumbnail-container {
        display: block;
        width: 100px; } }
    .images-container .thumbnail-container .slick-current .product-thumb {
      border: 2px solid #5290b5; }
    .images-container .thumbnail-container .product-thumbs {
      height: 100%; }
    .images-container .thumbnail-container .product-thumb {
      margin: 10px;
      border: 2px solid transparent;
      transition: border 0.3s cubic-bezier(0.5, 0, 0.5, 1); }
      @media (min-width: 768px) {
        .images-container .thumbnail-container .product-thumb {
          margin: 0; } }
      @media (min-width: 1440px) {
        .images-container .thumbnail-container .product-thumb {
          margin-bottom: 17px; } }

.slider-container {
  width: 100%;
  overflow: hidden; }
  @media (min-width: 768px) {
    .slider-container {
      width: calc(100% - 100px);
      margin-left: 12px; } }

.slider-container-full {
  width: 100%;
  overflow: hidden; }

/* PRODUCT PAGE */
.product-img:not(:first-child) .rc {
  display: none; }

#product-modal .slick-slide .image-figure img {
  margin: 0 auto; }

#product-modal .slick-slide > div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: #f1f1f1; }

.product-video {
  display: flex !important;
  justify-content: center;
  align-items: center;
  background-color: #f1f1f1; }
  .product-video .youtube-container {
    width: 100%; }

.slick-initialized .product-img .rc {
  display: block; }

.product-thumbs {
  margin: 0 auto; }
  .product-thumbs[data-count="2"] {
    width: 190px; }
  .product-thumbs .slick-slide {
    height: auto !important; }
  .product-thumbs .slick-slide > div {
    cursor: pointer; }
  .product-thumbs:not(.slick-initialized) > div:not(:first-child) {
    display: none; }
  .product-thumbs .slick-track {
    display: block !important; }
  .product-thumbs .slick-arrow {
    height: auto;
    position: absolute;
    left: 50%; }
    .product-thumbs .slick-arrow.slick-next {
      bottom: -10px;
      top: auto;
      transform: translateX(-50%); }
    .product-thumbs .slick-arrow.slick-prev {
      transform: translateX(-50%) rotate(180deg);
      top: -20px; }

.product-thumb {
  height: auto;
  border: 1px solid #d2d2d2; }
  .product-thumb.slick-current {
    border: 2px solid #2fb5d2; }

.btn-zoom {
  position: absolute;
  right: 1rem;
  bottom: 1rem;
  cursor: pointer;
  display: none;
  padding: 0;
  line-height: 1;
  color: #ffce28;
  transition: color 0.3s cubic-bezier(0.5, 0, 0.5, 1); }
  .btn-zoom:hover, .btn-zoom:focus, .btn-zoom:active {
    color: #f4bc00; }
  .btn-zoom .material-icons {
    font-size: 2rem; }

.images-container:hover .btn-zoom {
  display: block; }

.label-color {
  line-height: 1;
  margin-right: 0.5rem; }

.product-variants__grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px; }

.product-variants .product-variants-item .label-variants {
  font-size: 1rem;
  font-weight: 600; }

.product-variants .product-variants-item .custom-select {
  background: url("../img/static/arrow.svg");
  background-repeat: no-repeat;
  background-position: right; }

.btn-all-variants {
  color: #5290b5;
  margin: 15px 0 25px;
  display: block;
  font-size: 1rem;
  transition: color 0.3s cubic-bezier(0.5, 0, 0.5, 1); }
  .btn-all-variants:hover, .btn-all-variants:focus, .btn-all-variants:active {
    color: #407594; }

.product-variants-item .color {
  width: 2rem;
  height: 2rem;
  border: 2px solid transparent; }

.input-color:checked + span, .input-color:hover + span, .input-radio:checked + span, .input-radio:hover + span {
  border-color: #232323; }

.product__product-price .current-price {
  display: flex;
  align-items: center; }

.discount {
  text-transform: uppercase;
  color: #fff;
  background-color: #ffce28;
  font-weight: 600;
  display: inline-block;
  padding: 0.25rem; }
  .discount.discount_type_destockage {
    background-color: #ffce28; }
  .discount.discount_type_nouveaute {
    background-color: #5290b5; }
  .discount.discount_type_promoencours {
    background-color: #5290b5; }
  .discount.discount_type_remiseverte {
    background-color: #76B864; }
  .discount.discount_type_findeserie {
    background-color: #ffce28; }

.btn-add-to-cart {
  text-transform: uppercase;
  font-weight: 600;
  white-space: nowrap; }

.product-features-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
  font-size: .875rem; }

.features-list {
  width: 100%; }
  .features-list td {
    padding: 15px 10px; }
    .features-list td.value {
      font-weight: 700; }
  .features-list tr:nth-child(odd) {
    background-color: #f1f1f1; }

.cart-content {
  padding: 1rem;
  background: #f8f9fa; }

.pack-product-container {
  padding: 0 1rem;
  font-size: 0.875rem; }

.mask {
  width: 55px; }

.thumb-mask {
  padding: 1rem 0; }

.pack-product__img {
  border: 1px solid #f1f1f1; }

.pack-product-quantity {
  border-left: 1px solid #f1f1f1; }

.pack-product-price {
  padding-right: 0.5rem; }

.pack-product-quantity {
  padding-left: 1rem; }

.modal-cart__image {
  margin-right: 1rem;
  max-width: 200px;
  height: auto; }

.modal-cart__name {
  color: #5290b5;
  font-weight: 700; }

.product-total {
  font-weight: 700; }

.cart-content > p:not(:last-child) {
  margin-bottom: 0.5rem; }

@media (max-width: 991.98px) {
  .modal-cart__image {
    max-width: 120px; }
  .blockcart-modal .divide-right {
    display: flex;
    justify-content: center; }
  .blockcart-modal .modal-footer {
    position: fixed;
    bottom: 0;
    background: #fff;
    justify-content: center;
    left: 0;
    width: 100%;
    box-shadow: 0px -0.25rem 0.5rem rgba(0, 0, 0, 0.15); }
  .blockcart-modal .modal-body {
    margin-bottom: 4.25rem; } }

@media (max-width: 666.98px) {
  .modal-cart__image {
    max-width: 70px; } }

.btn-add-to-cart .btn-add-to-cart__spinner {
  width: 1.25rem;
  height: 1.25rem;
  border-width: 2px;
  display: none; }

.btn-add-to-cart.is--loading .btn-add-to-cart__spinner {
  display: inline-block; }

.btn-add-to-cart.is--loading .btn-add-to-cart__icon {
  display: none; }

.products-selection {
  justify-content: space-between;
  align-items: center; }
  .products-selection .total-products {
    display: flex;
    align-items: center;
    width: 100%;
    height: 58px;
    border-top: 1px solid #d2d2d2;
    border-bottom: 1px solid #d2d2d2; }

.pack-miniature-item {
  margin-bottom: 0.5rem; }

.ratio1_1 {
  display: block; }

.pack-product-name {
  margin: 0 1.5rem;
  flex-grow: 1; }

.product-miniature {
  margin-bottom: 1rem; }

.product__card-img {
  position: relative; }

.product__card-desc {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%; }

.highlighted-informations {
  text-align: center;
  padding: 0.5rem; }

.quantity__label {
  margin-bottom: 0;
  margin-top: 1rem; }

.container-price-add-to-cart {
  border-top: 5px solid #f1f1f1;
  padding-top: 25px; }
  .container-price-add-to-cart .wrapper {
    max-width: 450px;
    margin-left: auto; }

.product-add-to-cart .product-quantity {
  row-gap: 15px;
  column-gap: 15px;
  margin: 10px 0; }

.product-add-to-cart .alert {
  border: 0; }

.product-add-to-cart .alert-no-return {
  color: #000; }

.product-add-to-cart__info {
  font-weight: 600; }

.product-availability {
  font-size: .875rem; }
  .product-availability.in-stock .product-availability__color {
    background-color: #28a745; }
  .product-availability.out-of-stock .product-availability__color {
    background-color: #dc3545; }
  .product-availability.last .product-availability__color {
    background-color: #ffce28; }
  .product-availability__color {
    width: 14px;
    height: 14px;
    border-radius: 100%;
    display: inline-block;
    margin-right: 5px; }

.product-customization {
  margin-bottom: 1.5rem; }

.product-discounts {
  margin-bottom: 1.5rem; }

.product__quantity {
  margin-bottom: 0; }

.sort-by-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0; }
  .sort-by-row select {
    height: 58px;
    width: auto;
    max-width: 100%; }

.total__item-show {
  text-align: center;
  font-size: 0.875rem;
  margin-top: 1rem; }

.variant-links {
  display: flex;
  justify-content: center; }

.product__download {
  text-transform: uppercase; }

.card--address {
  height: 100%;
  text-align: center;
  justify-content: center; }

.invoice__address {
  margin-top: 1rem; }

.invoice-address {
  height: 100%;
  text-align: center;
  justify-content: center; }

.product-prices {
  margin-bottom: 15px; }
  .product-prices .current-price {
    display: flex;
    flex-wrap: wrap; }
  .product-prices .product-price {
    display: flex;
    align-items: baseline; }
  .product-prices .current-price-display {
    color: #5290b5;
    font-size: 1.5rem;
    font-weight: 900;
    margin-right: 10px;
    line-height: 1; }
  .product-prices .regular-price {
    text-decoration: line-through;
    margin-right: 10px; }
  .product-prices .tax-shipping-delivery-label {
    color: #5290b5;
    font-size: 1.5rem;
    font-weight: 900;
    line-height: 1; }

.product-tabs {
  margin: 50px 0; }

.page-product-block-reassurance {
  margin-bottom: 30px; }

.product-manufacturer {
  max-width: 90px; }

.page-product-block-reassurance .block-reassurance {
  border: 1px solid #f1f1f1;
  padding: 25px; }
  @media (min-width: 992px) {
    .page-product-block-reassurance .block-reassurance {
      margin-left: -15px; } }

.header-search {
  display: none;
  width: 160px;
  min-width: 160px; }
  @media (min-width: 992px) {
    .header-search {
      display: block; } }
  @media (min-width: 1200px) {
    .header-search {
      width: auto; } }

div#search_widget {
  margin: 0 auto;
  float: none;
  min-width: auto; }
  @media (min-width: 1350px) {
    div#search_widget {
      width: 320px; } }
  @media (max-width: 991.98px) {
    div#search_widget {
      border: 1px solid #d2d2d2;
      margin-bottom: 1rem; } }
  div#search_widget form {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 360px;
    overflow: hidden; }
    div#search_widget form button {
      display: flex;
      justify-content: center;
      align-items: center;
      top: 0;
      left: 0;
      width: 45px;
      height: 100%;
      border: 0;
      background-color: #41587c;
      color: #fff;
      padding: 10px 0;
      border-bottom-right-radius: .25rem;
      border-top-right-radius: .25rem;
      transition: background-color 0.3s cubic-bezier(0.5, 0, 0.5, 1), color 0.3s cubic-bezier(0.5, 0, 0.5, 1); }
      div#search_widget form button:hover, div#search_widget form button:focus {
        background-color: #5290b5;
        color: #fff; }
        div#search_widget form button:hover::after, div#search_widget form button:focus::after {
          height: 100%; }
      div#search_widget form button::after {
        content: "";
        position: absolute;
        width: 1px;
        height: calc(100% - 4px);
        left: 100%;
        background-color: #5290b5;
        transition: height 0.3s cubic-bezier(0.5, 0, 0.5, 1); }
    div#search_widget form input {
      padding: 7px 20px;
      background-color: #fff;
      border-top-left-radius: .25rem;
      border-bottom-left-radius: .25rem;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-top: 1px solid #d2d2d2;
      border-bottom: 1px solid #d2d2d2;
      border-left: 1px solid #d2d2d2; }

.search-widget__group {
  position: relative; }

.search-widget__input-right {
  padding-right: 50px; }

.search-widget__btn {
  position: absolute;
  right: 0;
  top: 0; }

@media (max-width: 991.98px) {
  .search-widget {
    max-width: unset; } }

.ul.ui-autocomplete.searchbar-autocomplete {
  z-index: 1060; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  display: flex !important;
  position: relative;
  left: 0;
  top: 0;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  height: inherit !important;
  float: left;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.slick-arrow {
  position: absolute;
  top: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 0;
  padding-bottom: 0;
  z-index: 1; }
  .slick-arrow > i {
    font-size: 2rem;
    line-height: 1;
    background: #fff;
    border-radius: 50%; }

.slick-prev {
  left: 0; }

.slick-next {
  right: 0; }

.slick-slide figure {
  margin: 0; }

.slick__arrow-outside .slick-prev {
  transform: translateX(-50px); }

.slick__arrow-outside .slick-next {
  transform: translateX(50px); }

.slick__arrow-large .slick-arrow i {
  font-size: 3rem;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
  background: transparent;
  color: #fff; }

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block; }
  .switch label, .switch .label {
    display: flex;
    align-items: center;
    gap: 5px;
    font-weight: 700; }
  .switch input {
    opacity: 0;
    width: 0;
    height: 0; }
  .switch .slider {
    position: relative;
    display: block;
    width: 60px;
    height: 34px;
    cursor: pointer;
    background-color: #d2d2d2;
    transition: .4s; }
    .switch .slider::before {
      position: absolute;
      content: "";
      height: 26px;
      width: 26px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      transition: .4s; }
    .switch .slider.round {
      border-radius: 34px; }
      .switch .slider.round::before {
        border-radius: 50%; }
  .switch input:checked + .slider {
    background-color: #5290b5; }
    .switch input:checked + .slider::before {
      transform: translateX(26px); }
  .switch input:focus + .slider {
    box-shadow: 0 0 1px #5290b5; }

table.table.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f1f1f1; }

.home-title {
  position: relative;
  margin-top: 3rem;
  margin-bottom: -30px;
  font-size: 2.25rem;
  font-weight: 300;
  text-transform: none;
  font-family: "Josefin Sans", sans-serif;
  z-index: 1; }

.page-title {
  font-size: 2rem;
  font-weight: 900; }

.product-title-page {
  margin-bottom: 1rem;
  font-size: 1.875rem;
  font-weight: 700;
  text-transform: none; }

.page-title-small {
  font-size: 22px;
  font-weight: 900;
  text-transform: none;
  margin-bottom: 30px; }

.squared-title {
  position: relative;
  display: flex;
  font-size: 22px;
  font-weight: 900;
  text-transform: none;
  margin-bottom: 20px;
  z-index: 1; }
  .squared-title::before {
    content: "";
    display: block;
    flex-shrink: 0;
    margin-right: 10px;
    width: 21px;
    height: 28px;
    background-image: url("../img/title-gim.svg"); }

.title-big {
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 20px; }


.h1,
.products-section-title,
.h2,
.h3,
.h4,
.h5,
.h6,
.products-section-title,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Lato", sans-serif; }

img[data-lazy] {
  display: none; }

.text-underline {
  text-decoration: underline; }

.cursor-pointer {
  cursor: pointer; }

@media (max-width: 991.98px) {
  .visible--desktop {
    display: none; } }

@media (min-width: 992px) {
  .visible--mobile {
    display: none; } }

.d--none {
  display: none; }

.d--block {
  display: block; }

.full-bleed {
  width: 100vw;
  margin-left: 50%;
  transform: translateX(-50%); }

.d--flex-between {
  display: flex;
  justify-content: space-between; }

.mb--0 {
  margin-bottom: 0; }

.text--center {
  text-align: center; }

.text--right {
  text-align: right; }

.u-a-i-c {
  align-items: center; }

.u-m-l-2 {
  margin-left: 0.5rem; }

.u-link-body {
  color: #212529; }

.u-bor-bot {
  border-bottom: 1px solid #f1f1f1; }

.vb-blog .title-big {
  margin-bottom: 10px; }

.vb-blog .subtitle {
  margin-bottom: 30px; }

.post-image img {
  object-fit: cover;
  object-position: center;
  height: 20rem;
  overflow: hidden; }
  @media (max-width: 991.98px) {
    .post-image img {
      height: 12rem; } }

.img-ratio-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  width: 100%; }
  @media (max-width: 991.98px) {
    .img-ratio-wrapper {
      padding-bottom: 25%; } }
  .img-ratio-wrapper img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0; }

.vb-card .row-cards {
  justify-content: center; }

@media (max-width: 991.98px) {
  .vb-card .col-item {
    margin-bottom: 30px; } }

.vb-card .card-image {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  transition: box-shadow 0.5s cubic-bezier(0.5, 0, 0.5, 1); }
  .vb-card .card-image:hover, .vb-card .card-image:focus {
    box-shadow: rgba(50, 50, 93, 0.25) 0 50px 100px -20px, rgba(0, 0, 0, 0.3) 0 30px 60px -30px; }
    .vb-card .card-image:hover .text-content, .vb-card .card-image:focus .text-content {
      padding: 15px 20px; }
    .vb-card .card-image:hover .title, .vb-card .card-image:focus .title {
      color: #565656; }
    .vb-card .card-image:hover .image img, .vb-card .card-image:focus .image img {
      transform: scale(1.05); }
  .vb-card .card-image .image {
    overflow: hidden;
    margin-bottom: 20px; }
    .vb-card .card-image .image img {
      width: 100%;
      transition: transform 0.5s cubic-bezier(0.5, 0, 0.5, 1); }
  .vb-card .card-image .button {
    display: flex; }
  .vb-card .card-image .text-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 15px 0;
    transition: padding 0.3s cubic-bezier(0.5, 0, 0.5, 1); }
  .vb-card .card-image .subtitle {
    margin-top: 5px; }
  .vb-card .card-image .title {
    font-size: 18px;
    font-weight: 900;
    margin-bottom: 0;
    transition: color 0.3s cubic-bezier(0.5, 0, 0.5, 1); }
  .vb-card .card-image .cta {
    margin-top: auto; }
    .vb-card .card-image .cta a::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }
      @media (max-width: 666.98px) {
        .vb-card .card-image .cta a::after {
          display: none; } }

.vb-category-slider .container, .vb-category-slider .l-wrapper, .vb-category-slider main > .notifications-container, .vb-category-slider .l-wrapper--boxed {
  position: relative; }

.vb-category-slider .carousel {
  margin: 0 -5px; }
  .vb-category-slider .carousel a {
    display: flex; }

.vb-category-slider .gimmick {
  color: #d2d2d2;
  position: absolute;
  top: -45px;
  left: -60px; }
  @media (max-width: 767.98px) {
    .vb-category-slider .gimmick {
      display: none; } }

.vb-gallery .image {
  position: relative;
  overflow: hidden; }
  .vb-gallery .image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center; }

.vb-gallery .legend {
  position: absolute;
  padding: 0 15px;
  width: 100%;
  bottom: 15px;
  color: #fff; }

.vb-gallery .col-middle .image {
  height: 210px; }
  @media (max-width: 767.98px) {
    .vb-gallery .col-middle .image {
      height: auto;
      margin-bottom: 30px; } }
  .vb-gallery .col-middle .image:first-of-type {
    margin-bottom: 30px; }

.vb-gallery .col-left .image,
.vb-gallery .col-right .image {
  height: 450px; }
  @media (max-width: 767.98px) {
    .vb-gallery .col-left .image,
    .vb-gallery .col-right .image {
      height: auto; } }

@media (max-width: 767.98px) {
  .vb-gallery .col-left .image {
    margin-bottom: 30px; } }

.vb-product-slider.grey-bg {
  background-color: #f1f1f1; }

.vb-product-slider .carousel {
  margin: 0 -5px; }

.vb-product-slider .product-miniature {
  flex: none;
  max-width: none; }

.vb-product-category-slider.grey-bg {
  background-color: #f1f1f1; }

.vb-product-category-slider .carousel {
  margin: 0 -5px; }

.vb-product-category-slider .product-miniature {
  flex: none;
  max-width: none; }

.vb-six-cards {
  margin: 30px 0;
  background-color: #f1f1f1; }
  .vb-six-cards .row-cards {
    margin-left: -2px;
    margin-right: -2px; }
    .vb-six-cards .row-cards > div {
      padding-left: 2px;
      padding-right: 2px; }
      @media (max-width: 991.98px) {
        .vb-six-cards .row-cards > div {
          margin-bottom: 4px; } }
  .vb-six-cards .card-image-simple {
    height: 100%;
    overflow: hidden; }
    .vb-six-cards .card-image-simple:hover .image img {
      transform: scale(1.05); }
    .vb-six-cards .card-image-simple .image {
      height: 100%; }
      .vb-six-cards .card-image-simple .image img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: transform 0.5s cubic-bezier(0.5, 0, 0.5, 1); }
  .vb-six-cards .card-fitter-wrapper {
    height: 100%; }
  .vb-six-cards .card-fitter {
    height: 100%;
    background-color: #ffce28;
    transition: background-color 0.3s cubic-bezier(0.5, 0, 0.5, 1); }
    .vb-six-cards .card-fitter:hover, .vb-six-cards .card-fitter:focus {
      background-color: #f4bc00; }
  .vb-six-cards .card-image-simple,
  .vb-six-cards .card-fitter {
    position: relative;
    color: #fff; }
    @media (max-width: 666.98px) {
      .vb-six-cards .card-image-simple,
      .vb-six-cards .card-fitter {
        height: 200px; } }
    .vb-six-cards .card-image-simple .overlay,
    .vb-six-cards .card-fitter .overlay {
      position: absolute;
      opacity: .75;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(180deg, transparent 75%, #000);
      z-index: 1; }
      @media (max-width: 666.98px) {
        .vb-six-cards .card-image-simple .overlay,
        .vb-six-cards .card-fitter .overlay {
          background-image: linear-gradient(180deg, transparent 50%, #000); } }
    .vb-six-cards .card-image-simple .text-content,
    .vb-six-cards .card-fitter .text-content {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 10px 20px;
      text-align: center;
      z-index: 2; }
      .vb-six-cards .card-image-simple .text-content .card-title,
      .vb-six-cards .card-fitter .text-content .card-title {
        font-size: 14px;
        font-family: "Raleway", sans-serif;
        font-weight: 700;
        text-transform: uppercase; }

.vb-text-image-layer .cta {
  margin-top: 30px; }

.vb-text-image-layer .image img {
  width: 100%; }

.vb-text-layer {
  padding: 50px 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  color: #fff; }
  .vb-text-layer .cta {
    margin-top: 20px; }

.vb-testimony {
  margin: 30px 0;
  background-color: #f1f1f1; }
  .vb-testimony .image {
    height: 100%; }
    .vb-testimony .image img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .vb-testimony .quotation {
    padding: 50px 50px 50px 75px; }
    .vb-testimony .quotation .title {
      position: relative;
      font-size: 22px;
      font-weight: 900; }
      .vb-testimony .quotation .title svg {
        position: absolute;
        color: #5290b5;
        top: -15px;
        left: -55px; }
    .vb-testimony .quotation .quote {
      font-size: 18px;
      font-style: italic; }
    .vb-testimony .quotation .author {
      margin-top: 20px;
      margin-bottom: 0; }
    .vb-testimony .quotation .cta {
      margin-top: 20px; }

.vb-video iframe {
  width: 100%;
  aspect-ratio: 16 / 9;
  border: 0; }

.vb-block {
  padding: 30px 0; }

div.visual-composer-page {
  background-color: transparent;
  padding: 0;
  text-align: left; }
  div.visual-composer-page .top {
    display: flex;
    justify-content: space-between;
    padding-bottom: 30px; }
    div.visual-composer-page .top .text {
      display: flex;
      flex-direction: column;
      justify-content: center; }
    div.visual-composer-page .top .introduction {
      font-weight: 600; }
    div.visual-composer-page .top .image {
      flex-shrink: 0;
      margin-left: 30px; }

.wysiwyg-content a {
  color: #5290b5;
  font-weight: 400;
  text-decoration: underline; }
  .wysiwyg-content a:hover {
    color: #407594; }
  .wysiwyg-content a.btn-default {
    text-decoration: none; }

.wysiwyg-content > *:last-child {
  margin-bottom: 0; }

.wysiwyg-content h1 {
  font-size: 2.2rem; }

.wysiwyg-content h2 {
  font-size: 1.8rem; }

.wysiwyg-content h3 {
  font-size: 1.4rem; }

.wysiwyg-content h4 {
  font-size: 1.3rem; }

.wysiwyg-content h5 {
  font-size: 1.2rem; }

.wysiwyg-content h6 {
  font-size: 1.1rem; }

.wysiwyg-content p {
  font-size: 14px;
  font-weight: 400; }

.wysiwyg-content ul,
.wysiwyg-content ol {
  font-weight: 400;
  padding-left: 20px; }

.wysiwyg-content ul {
  list-style: none; }
  .wysiwyg-content ul li {
    position: relative;
    padding-left: 25px;
    margin-bottom: 5px; }
    .wysiwyg-content ul li::before {
      content: "";
      width: 13px;
      height: 3px;
      background-color: #5290b5;
      display: block;
      position: absolute;
      left: 0;
      top: 10px; }

/** PAGES **/
#authentication .squared-title {
  justify-content: start;
  margin: 0 auto 30px;
  width: 100%;
  max-width: 480px; }

#authentication .form-wrapper {
  margin: 0 auto 50px;
  padding: 30px;
  width: 100%;
  max-width: 480px;
  background-color: #f1f1f1; }
  #authentication .form-wrapper .no-account {
    margin-top: 20px;
    font-size: .875rem; }
    #authentication .form-wrapper .no-account a {
      color: #41587c;
      transition: color 0.3s cubic-bezier(0.5, 0, 0.5, 1); }
      #authentication .form-wrapper .no-account a:hover, #authentication .form-wrapper .no-account a:focus, #authentication .form-wrapper .no-account a:active {
        color: #2f405b; }
  #authentication .form-wrapper .forgot-password {
    text-align: right; }
    #authentication .form-wrapper .forgot-password a {
      color: #41587c;
      transition: color 0.3s cubic-bezier(0.5, 0, 0.5, 1); }
      #authentication .form-wrapper .forgot-password a:hover, #authentication .form-wrapper .forgot-password a:focus, #authentication .form-wrapper .forgot-password a:active {
        color: #2f405b; }
  #authentication .form-wrapper .form-control {
    border: 0; }

#authentication .register-form a {
  color: #41587c;
  transition: color 0.3s cubic-bezier(0.5, 0, 0.5, 1); }
  #authentication .register-form a:hover, #authentication .register-form a:focus, #authentication .register-form a:active {
    color: #2f405b; }

#module-prestashop_visual_composer-blog .blog-menu {
  display: flex; }
  @media (max-width: 991.98px) {
    #module-prestashop_visual_composer-blog .blog-menu {
      overflow-x: auto; } }
  #module-prestashop_visual_composer-blog .blog-menu li {
    white-space: nowrap; }
    #module-prestashop_visual_composer-blog .blog-menu li:not(:last-of-type) {
      margin-right: 5px; }
  #module-prestashop_visual_composer-blog .blog-menu a {
    display: block;
    padding: 5px 15px;
    background-color: #5290b5;
    color: #fff;
    font-weight: 700;
    text-transform: uppercase;
    transition: background-color 0.3s cubic-bezier(0.5, 0, 0.5, 1); }
    #module-prestashop_visual_composer-blog .blog-menu a:hover, #module-prestashop_visual_composer-blog .blog-menu a.active {
      background-color: #407594; }

@media (min-width: 992px) {
  #cart .cart-grid {
    margin-bottom: 50px; } }

@media (min-width: 992px) {
  #cart .cart-grid-right {
    border-left: 5px solid #f1f1f1; } }

@media (min-width: 992px) {
  #cart .side-wrapper-right {
    padding-left: 30px; } }

@media (min-width: 992px) {
  #cart .side-wrapper-left {
    padding-right: 30px; } }

#cart .cart-item {
  border-bottom: 1px solid #dfdfdf;
  margin-bottom: 1rem;
  padding-bottom: 1rem; }
  #cart .cart-item .media img {
    border: 2px solid #dfdfdf; }
  #cart .cart-item .media .product-line-info {
    margin-bottom: 0; }
  #cart .cart-item .media .price-ecotax {
    margin-bottom: 0;
    color: #99a1a6;
    font-size: .75rem; }
  #cart .cart-item .media .regular-price {
    text-decoration: line-through;
    color: #99a1a6; }
  #cart .cart-item .media .discount {
    background-color: #5290b5;
    color: #fff;
    font-weight: 900; }
  #cart .cart-item .media .current-price-display {
    font-weight: 700; }
  #cart .cart-item .bootstrap-touchspin {
    box-shadow: none;
    border-radius: 3px;
    border: 1px solid #d2d2d2; }
    #cart .cart-item .bootstrap-touchspin .form-control {
      height: auto;
      padding: .1rem 0.75rem; }
    #cart .cart-item .bootstrap-touchspin .btn {
      background-color: transparent;
      padding: .1rem 0.75rem; }
    #cart .cart-item .bootstrap-touchspin .input-group-prepend .btn {
      border-right: 1px solid #d2d2d2; }
    #cart .cart-item .bootstrap-touchspin .input-group-append .btn {
      border-left: 1px solid #d2d2d2; }
  #cart .cart-item .remove-from-cart {
    color: #41587c;
    transition: color 0.3s cubic-bezier(0.5, 0, 0.5, 1); }
    #cart .cart-item .remove-from-cart:hover {
      color: #2f405b; }
  @media (max-width: 991.98px) {
    #cart .cart-item .cart-line-product-actions {
      margin-left: 20px; } }

#cart .need-help {
  margin: 30px 0;
  padding: 20px 20px 10px;
  border: 1px solid #dfdfdf;
  border-radius: 3px; }
  #cart .need-help p {
    margin-bottom: 0; }
  #cart .need-help .ctas {
    margin-top: 20px; }
    #cart .need-help .ctas .button {
      margin-bottom: 10px; }
      #cart .need-help .ctas .button:not(:last-of-type) {
        margin-right: 10px; }

#cart .block-title {
  font-size: 1.375rem;
  font-weight: 900; }

#cart .cart-summary {
  border: 0; }
  @media (max-width: 991.98px) {
    #cart .cart-summary {
      margin-top: 30px; } }
  #cart .cart-summary .card-header, #cart .cart-summary .page-header, #cart .cart-summary .address-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;
    border-bottom: 0;
    padding: 0;
    margin-bottom: 20px; }
  #cart .cart-summary .card-body, #cart .cart-summary .page-content, #cart .cart-summary .card-block, #cart .cart-summary .block-categories, #cart .cart-summary .address-body {
    padding: 0; }
    #cart .cart-summary .card-body .cart-summary-line, #cart .cart-summary .page-content .cart-summary-line, #cart .cart-summary .card-block .cart-summary-line, #cart .cart-summary .block-categories .cart-summary-line, #cart .cart-summary .address-body .cart-summary-line {
      border-bottom: 1px solid #dfdfdf;
      padding: 10px 0; }
      #cart .cart-summary .card-body .cart-summary-line label, #cart .cart-summary .page-content .cart-summary-line label, #cart .cart-summary .card-block .cart-summary-line label, #cart .cart-summary .block-categories .cart-summary-line label, #cart .cart-summary .address-body .cart-summary-line label, #cart .cart-summary .card-body .cart-summary-line .label, #cart .cart-summary .page-content .cart-summary-line .label, #cart .cart-summary .card-block .cart-summary-line .label, #cart .cart-summary .block-categories .cart-summary-line .label, #cart .cart-summary .address-body .cart-summary-line .label {
        margin-bottom: 0; }
      #cart .cart-summary .card-body .cart-summary-line .value, #cart .cart-summary .page-content .cart-summary-line .value, #cart .cart-summary .card-block .cart-summary-line .value, #cart .cart-summary .block-categories .cart-summary-line .value, #cart .cart-summary .address-body .cart-summary-line .value {
        color: #5290b5; }
    #cart .cart-summary .card-body .cart-total, #cart .cart-summary .page-content .cart-total, #cart .cart-summary .card-block .cart-total, #cart .cart-summary .block-categories .cart-total, #cart .cart-summary .address-body .cart-total {
      background-color: transparent;
      border-bottom: 0; }
  #cart .cart-summary .card-footer, #cart .cart-summary .page-footer, #cart .cart-summary .address-footer {
    padding: 0;
    border-top: 0;
    background-color: transparent; }
    #cart .cart-summary .card-footer .button, #cart .cart-summary .page-footer .button, #cart .cart-summary .address-footer .button {
      width: 100%; }
    #cart .cart-summary .card-footer .cta-quote, #cart .cart-summary .page-footer .cta-quote, #cart .cart-summary .address-footer .cta-quote {
      margin-top: 10px; }

#cart .privilege {
  margin-bottom: 20px;
  padding: 20px;
  background-color: #c8dce7;
  font-size: 1.125rem; }

#cart .free-shipping-tips {
  display: flex;
  align-items: center;
  margin: 20px 0;
  padding: 20px;
  border: 1px solid #dfdfdf;
  border-radius: 3px; }
  #cart .free-shipping-tips .picto {
    flex-shrink: 0;
    margin-right: 20px; }
  #cart .free-shipping-tips .text {
    font-size: 1.125rem;
    line-height: 1.1; }

#cart .sticker_availability {
  color: #fff;
  padding: 0.2rem;
  text-transform: uppercase; }
  #cart .sticker_availability-green {
    background-color: #90af69; }
  #cart .sticker_availability-blue {
    background-color: #41587c; }
  #cart .sticker_availability-yellow {
    background-color: #ffce28; }
  #cart .sticker_availability-red {
    background-color: #dc3545; }

.availability_message_add_cart {
  color: #5290b5;
  font-size: 15px; }

#category #main {
  padding-left: 75px; }
  @media (max-width: 1349.98px) {
    #category #main {
      padding-left: 0; } }

#checkout .address-item .card, #checkout .address-item .page-wrapper, #checkout .address-item .card-block, #checkout .address-item .block-categories, #checkout .address-item .account-links .link-item, .account-links #checkout .address-item .link-item, #checkout .address-item .address {
  height: 100%; }

#checkout .address-item .card-footer, #checkout .address-item .page-footer, #checkout .address-item .address-footer {
  display: flex;
  justify-content: space-between; }

#contact .page-wrapper {
  border: 0; }
  @media (max-width: 991.98px) {
    #contact .page-wrapper .page-content {
      padding: 0;
      margin-bottom: 30px; } }

#contact .contact-rich {
  background-color: #f1f1f1;
  padding: 1.25rem;
  height: 100%; }
  #contact .contact-rich .block {
    display: flex; }
    #contact .contact-rich .block .icon {
      margin-right: 5px; }
    #contact .contact-rich .block i {
      font-size: 16px; }
    #contact .contact-rich .block a {
      color: #000;
      font-weight: 700;
      text-decoration: underline;
      transition: color 0.3s cubic-bezier(0.5, 0, 0.5, 1); }
      #contact .contact-rich .block a:hover, #contact .contact-rich .block a:focus {
        color: #5290b5; }

#contact .grey-bg {
  background-color: #f1f1f1; }

#module-psgdpr-gdpr .psgdprinfo17 .btn {
  float: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  padding: 10px 20px;
  font-size: .812rem;
  text-transform: uppercase;
  font-weight: 700;
  transition: background-color 0.3s cubic-bezier(0.5, 0, 0.5, 1), border 0.3s cubic-bezier(0.5, 0, 0.5, 1), color 0.3s cubic-bezier(0.5, 0, 0.5, 1);
  background-color: #5290b5;
  border: 1px solid #5290b5;
  text-align: center; }
  #module-psgdpr-gdpr .psgdprinfo17 .btn:hover, #module-psgdpr-gdpr .psgdprinfo17 .btn:focus, #module-psgdpr-gdpr .psgdprinfo17 .btn:active {
    color: #fff;
    background-color: #407594;
    border: 1px solid #407594; }
  #module-psgdpr-gdpr .psgdprinfo17 .btn:first-of-type {
    margin-left: 0; }

#index #wrapper {
  margin-top: 0; }

#index .l-header {
  margin-bottom: 0; }

#index .slider-block-right {
  display: flex;
  flex-direction: column;
  height: 100%; }
  #index .slider-block-right .title {
    font-size: 1.375rem;
    font-weight: 900; }
  #index .slider-block-right .img-icon {
    width: 18px; }
  #index .slider-block-right .description p {
    font-size: .875rem; }
  #index .slider-block-right ul {
    list-style: none !important; }

#index .block-black {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  background-color: #e9ecef;
  padding: 20px;
  color: #000; }
  #index .block-black .title {
    font-size: 1.375rem;
    font-weight: 900;
    text-transform: none; }
  #index .block-black .button {
    padding: 11px; }
  #index .block-black img {
    width: 100%;
    height: auto; }
  #index .block-black .wysiwyg-content p:not(:last-child) {
    margin-bottom: 10px; }
  #index .block-black .wysiwyg-content a {
    font-weight: 700;
    color: #fff; }
  #index .block-black .wysiwyg-content li::before {
    content: "✔";
    width: unset;
    height: unset;
    background-color: transparent;
    color: #000;
    top: 0; }

#index .block-reassurance,
#index .block-reassurance-inst-wrapper {
  padding: 40px 0; }
  @media (max-width: 991.98px) {
    #index .block-reassurance,
    #index .block-reassurance-inst-wrapper {
      padding: 20px 0; } }

#identity .box {
  background-color: #f1f1f1;
  padding: 20px;
  height: 100%; }
  #identity .box .box-title {
    font-weight: 700; }
  #identity .box ul {
    margin-bottom: 0; }
  #identity .box li {
    padding: 5px 0; }
    #identity .box li:last-of-type {
      padding-bottom: 0; }

#order-confirmation #content-hook_payment_return a {
  color: #5290b5;
  transition: color 0.3s cubic-bezier(0.5, 0, 0.5, 1); }
  #order-confirmation #content-hook_payment_return a:hover, #order-confirmation #content-hook_payment_return a:focus, #order-confirmation #content-hook_payment_return a:active {
    color: #407594; }

@media (max-width: 1439.98px) {
  #history .table-wrapper {
    overflow-x: scroll; } }

#history .table-orders {
  min-width: 900px; }
  #history .table-orders thead th {
    border-top: 0; }
  #history .table-orders .view-link {
    font-weight: 700;
    color: #5290b5;
    text-decoration: underline; }

#history .orders-mobile .order {
  padding-bottom: 15px;
  margin-bottom: 15px; }
  #history .orders-mobile .order:not(:last-of-type) {
    border-bottom: 1px solid #d2d2d2; }

#history .orders-mobile .order-ref {
  color: #000;
  transition: color 0.3s cubic-bezier(0.5, 0, 0.5, 1); }
  #history .orders-mobile .order-ref:hover, #history .orders-mobile .order-ref:focus, #history .orders-mobile .order-ref:active {
    color: #5290b5; }

#product {
  overflow-x: hidden; }
  #product .modal .slick-arrow {
    color: #5290b5; }
  #product .modal .slick-prev svg {
    transform: rotate(90deg); }
  #product .modal .slick-next svg {
    transform: rotate(-90deg); }
  #product .product-accessories {
    margin: 50px 0;
    padding: 50px 0;
    position: relative; }
    #product .product-accessories::before {
      content: "";
      position: absolute;
      width: 200vw;
      height: 100%;
      top: 0;
      left: -50vw;
      background-color: #f1f1f1; }
    #product .product-accessories .slick-track {
      margin-left: unset;
      margin-right: unset; }
  #product .doc-item {
    background-color: #f1f1f1;
    padding: 10px; }
    #product .doc-item:not(:last-of-type) {
      margin-bottom: 5px; }
    #product .doc-item ul {
      margin-bottom: 0; }
    #product .doc-item li a {
      display: flex;
      align-items: center; }
    #product .doc-item li svg {
      margin-right: 5px; }
    #product .doc-item .item-title {
      margin-bottom: 5px;
      font-weight: 700; }

.product-availability__message {
  font-weight: bold;
  font-size: 15px; }

#module-mieko_sav-form .sav-wysiwyg .container, #module-mieko_sav-form .sav-wysiwyg .l-wrapper, #module-mieko_sav-form .sav-wysiwyg main > .notifications-container, #module-mieko_sav-form .sav-wysiwyg .l-wrapper--boxed {
  padding: 0; }
