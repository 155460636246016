body {
  font-family: $font-family-main;
  font-size: 14px;
  opacity: 0;
  transition: opacity $anim-speed-slow $anim-curve;

  &.dom-loaded {
    opacity: 1;
  }
}

#wrapper {
  margin-top: 30px;
}

.container-full-width {
  padding: 0 15px;
}

a {
  text-decoration: none;
}

sup {
  top:-0.3em;
}

.container,
.container-lg,
.container-md,
.container-sm,
.container-xl,
main > .notifications-container {

  @include media-breakpoint-up(xl) {
    max-width: 1350px;
  }

  @include media-breakpoint-up(xxl) {
    max-width: 1400px;
  }
}

.l-wrapper {
  @if $enable-fluid-layout {
    @extend .container-fluid;
  } @else {
    @extend .container;
  }

  &--boxed {
    @extend .container;
  }
}

.page-wrapper,.card-block{
  @extend .card;
}

.page-header {
  @extend .card-header;

  h1 {
    margin-bottom: 0;
  }
}

.page-content,.card-block{
  @extend .card-body;
}
.card-block{
  box-shadow:$card-box-shadow;;
}
.page-footer{
  @extend .card-footer;
  &:empty{
    display: none;
  }

}

.left-column > div,
.right-column > div{
  margin-bottom: $spacer;
}
/* HEADER */

/* FOOTER */
.l-footer{
  background-color: $footer-bg;
  margin-top: $footer-margin-top;
  padding-top: $footer-padding-top;
}

/* MISC */
ul {
  list-style: none;
  padding-left: 0;
}
h1,.h1{
  text-transform: $h1-text-transform;

}
.quick-view{
  display: inline-block;
  @extend .small;
}
.form-group.form-group_forgot-password{
  margin-top: -($form-group-margin-bottom/2);
}

//touch spin
.bootstrap-touchspin {
  border:$input-border-width solid $input-border-color;

  > input {
    max-width: 55px;
    border:none;
    box-shadow: none;
    text-align: center;
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  .btn {
    position: relative;
    z-index: 2;
    color:$input-color;
    background-color: $white;
    font-size: 1.25rem;
    line-height: 0;
  }

  .input-group-btn {
    display: flex;

    &:first-child {
      margin-right:-$input-border-width;
      border-right: 2px solid $input-border-color;
    }

    &:last-child {
      margin-left:-$input-border-width;
      border-left: 2px solid $input-border-color;
    }
  }
}

/* minimizing reflows and avoiding page jumps */

.rc {
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.05);
  transition: background 150ms;
  &:after{
    @extend .spinner-border;
    content: "";
    border-color: $gray-300;
    position: absolute;
    top: calc(50% - #{($spinner-height/2)});
    left: calc(50% - #{($spinner-width/2)});
    z-index: 2;
    border-right-color: transparent;
  }
  &--lazyload:after{
    display: none;
  }

  img {
    object-fit: cover;
  }
}

.rc > * {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.rc > a {
  display: block;
}
.ratio2_1{
  padding-top: 50%;
}
.ratio1_2{
  padding-top: 200%;
}
.ratio4_3{
  padding-top: 75%;
}
.ratio16_9{
  padding-top: percentage(9/16);
}
.ratio1_1{
  padding-top: 100%;
}
.ratio3_2{
  padding-top: percentage(1-2/3);
}

/* collapse icons */
.icon-collapse{
  transition: transform 0.4s ease;


}
.icon-collapse[aria-expanded=true]{
  transform: rotateZ(180deg);
}

/* CHECKOUT STEP */
.order-checkout .card-body{
  padding-bottom: 0;

}
.step-edit[aria-expanded="true"]{
  display: none;
}
.step-title
{
  margin-bottom: 0;
}
.-complete.-reachable .step-title .done {
display: inline-block;
}
.-current .step-title .done {
  visibility: hidden;
}
.-current .card-header{
  background-color: $white;
}


.accordion {

  .card-body .card {
    border: 1px solid $card-border-color;
    transition: border 0.1s linear;

  }

  .card-body .card-header {
    text-align: center;
  }

  .selected .card {
    border-color: $primary;
    border-width: 2px;
  }
}
  //Personnal info
  .nav-tabs-info{
    font-size: $font-size-base*1.1;
    .active{
      font-weight: bold;
    }
  }

  //Addresses

  .address-selector
  {
    .card{
      transition: border 0.1s linear;

    }
    .card-header,
    .card-body{
      cursor: pointer;

    }
  }
  .card-body_add-address{
    padding: $card-spacer-y $card-spacer-x;
    .material-icons{
      opacity: .8;
    }
  }
  // SHipping
.delivery-option{
  padding: $spacer;
  margin-bottom: $spacer;
  background-color: $gray-200;
}
.carrier-extra-content:not(:empty){
  margin-bottom: $spacer*2;
  &:empty{
    display: none;
  }
}
.carrier-name{
  font-weight: $font-weight-bold;
}
  .carrier-price{
    font-weight: bold;
    font-size: $font-size-lg;
    color: $primary;
  }
//order list final recap
.order-line:not(:last-child){
  margin-bottom: $spacer/2;
}
.order-confirmation-table{
  padding: $spacer;
  border: 3px solid $card-border-color;
}


//forms
custom-file-label.selected:after{
  content: "" !important;

}
.label{
  @extend label;
}

//nav tabs
.nav-tabs {

  .nav-link {
    color: $black;
    font-size: 1.375rem;
    margin-bottom: 0;

    @include media-breakpoint-down(sm) {
      font-size: 1rem;
      padding: .5rem;
    }

    &.active {
      color: $black;
      font-weight: 900;
      position: relative;
      background-color: transparent;
    }

    &:hover,
    &:focus {
      border-color: transparent;
    }
  }

  .nav-link.active,
  .nav-item.show .nav-link {
    border: 0;
    border-bottom: 9px solid $primary;
  }
}

.tab-content {
  padding-top: 1.5rem;
  font-size: 1rem;

  .product-description {
    max-width: 550px;
    text-align: justify;
  }
}

//products-section-title
.products-section-title{
  text-transform: $section-title-text-transform;
  text-align: $section-title-text-align;
  display: block;
  margin-bottom: $section-title-margin-bottom;
  @extend .h1;
  font-family: $section-title-font-family;
  color:$section-title-color;
  font-size:$section-title-size;
  font-weight:$section-title-font-weight;
}

//home content spacing
.page-home > *{
  margin-bottom: $spacer-y;
}

@include media-breakpoint-mobile() {
  .modal-footer{
    flex-direction: column;
    flex-direction:column-reverse;
    & > .btn{
      margin: 0;
      width : 100%
    }
    & .btn:not(:last-child){
      margin-top: $spacer/2;
    }

  }
}

.nav-tabs {
  flex-wrap: nowrap;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;

  &::-webkit-scrollbar {
    display: none;
  }
  .nav-link {
    white-space: nowrap;
  }
}

//forms
main > .notifications-container{
  @extend .l-wrapper;
}
.notifications-container{
  ul{
    margin-bottom: 0;
  }
}
.js-invalid-feedback-browser:empty{
  display: none;
}
.form-group{
  label,.label{
  small,
  .small{
    font-size: .75 * $font-size-base;
  }
  }
}
@include media-breakpoint-desktop{

.page-content,
.checkout-form,
.page-wrapper--order-confirmation{
  form{
    width: 75%;
    max-width: 450px;
    margin: 0 auto;
  }
}
.page-wrapper--order-detail{
  form{
    margin: unset;
  }
}
}
.form-footer{
  margin-top: $spacer;
}

// badge order history
.badge.bright{
  color:#fff;
}

.alert-warning {
  background-color: $secondary;
  border-color: $secondary;
  * {
    color: black;
  }
}

.alert-danger {
  * {
    color: black;
  }
}
