.notifications-container {

  .alert {
    border: 0;
    color: $white;
  }

  .alert-success {
    background-color: $success;
  }
}
