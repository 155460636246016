.vb-gallery {

  .image {
    position: relative;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  .legend {
    position: absolute;
    padding: 0 15px;
    width: 100%;
    bottom: 15px;
    color: $white;
  }

  .col-middle {

    .image {
      height: 210px;

      @include media-breakpoint-down(sm) {
        height: auto;
        margin-bottom: 30px;
      }

      &:first-of-type {
        margin-bottom: 30px;
      }
    }
  }

  .col-left,
  .col-right {

    .image {
      height: 450px;

      @include media-breakpoint-down(sm) {
        height: auto;
      }
    }
  }

  .col-left {

    .image {

      @include media-breakpoint-down(sm) {
        margin-bottom: 30px;
      }
    }
  }
}
