#cart {

  .cart-grid {

    @include media-breakpoint-desktop {
      margin-bottom: 50px;
    }
  }

  .cart-grid-right {

    @include media-breakpoint-desktop {
      border-left: 5px solid $grey-bg;
    }
  }

  .side-wrapper-right {
    @include media-breakpoint-desktop {
      padding-left: 30px;
    }
  }

  .side-wrapper-left {

    @include media-breakpoint-desktop {
      padding-right: 30px;
    }
  }

  .cart-item {
    border-bottom: 1px solid $grey-border-light;
    margin-bottom: 1rem;
    padding-bottom: 1rem;

    .media {

      img {
        border: 2px solid $grey-border-light;
      }

      .product-line-info {
        margin-bottom: 0;
      }

      .price-ecotax {
        margin-bottom: 0;
        color: $grey-text-light;
        font-size: .75rem;
      }

      .regular-price {
        text-decoration: line-through;
        color: $grey-text-light;
      }

      .discount {
        background-color: $primary;
        color: $white;
        font-weight: $font-black;
      }

      .current-price-display {
        font-weight: $font-bold;
      }
    }

    .bootstrap-touchspin {
      box-shadow: none;
      border-radius: 3px;
      border: 1px solid $grey-border;

      .form-control {
        height: auto;
        padding: .1rem 0.75rem;
      }

      .btn {
        background-color: transparent;
        padding: .1rem 0.75rem;
      }

      .input-group-prepend .btn {
        border-right: 1px solid $grey-border;
      }

      .input-group-append .btn {
        border-left: 1px solid $grey-border;
      }
    }

    .remove-from-cart {
      color: $tertiary;
      transition: color $anim-speed $anim-curve;

      &:hover {
        color: $tertiary-hover;
      }
    }

    .cart-line-product-actions {

      @include media-breakpoint-mobile {
        margin-left: 20px;
      }
    }
  }

  .need-help {
    margin: 30px 0;
    padding: 20px 20px 10px;
    border: 1px solid $grey-border-light;
    border-radius: 3px;

    p {
      margin-bottom: 0;
    }

    .ctas {
      margin-top: 20px;

      .button {
        margin-bottom: 10px;

        &:not(:last-of-type) {
          margin-right: 10px;
        }
      }
    }
  }

  .block-title {
    font-size: 1.375rem;
    font-weight: $font-black;
  }

  .cart-summary {
    border: 0;

    @include media-breakpoint-mobile {
      margin-top: 30px;
    }

    .card-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: transparent;
      border-bottom: 0;
      padding: 0;
      margin-bottom: 20px;
    }

    .card-body {
      padding: 0;

      .cart-summary-line {
        border-bottom: 1px solid $grey-border-light;
        padding: 10px 0;

        label {
          margin-bottom: 0;
        }

        .value {
          color: $primary;
        }
      }

      .cart-total {
        background-color: transparent;
        border-bottom: 0;
      }
    }

    .card-footer {
      padding: 0;
      border-top: 0;
      background-color: transparent;

      .button {
        width: 100%;
      }

      .cta-quote {
        margin-top: 10px;
      }
    }
  }

  .privilege {
    margin-bottom: 20px;
    padding: 20px;
    background-color: lighten($primary, 33%);
    font-size: 1.125rem;
  }

  .free-shipping-tips {
    display: flex;
    align-items: center;
    margin: 20px 0;
    padding: 20px;
    border: 1px solid $grey-border-light;
    border-radius: 3px;

    .picto {
      flex-shrink: 0;
      margin-right: 20px;
    }

    .text {
      font-size: 1.125rem;
      line-height: 1.1;
    }
  }

  .sticker_availability {
    color: $white;
    padding: 0.2rem;
    text-transform: uppercase;

    &-green {
      background-color: #90af69;
    }

    &-blue {
      background-color: $tertiary;
    }

    &-yellow {
      background-color: $secondary;
    }

    &-red {
      background-color: $danger;
    }
  }
}

.availability_message_add_cart {
  color: $primary;
  font-size: 15px;
}
