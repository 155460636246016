#module-psgdpr-gdpr {

  .psgdprinfo17 {

    .btn {
      float: none;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      color: $white;
      padding: 10px 20px;
      font-size: .812rem;
      text-transform: uppercase;
      font-weight: $font-bold;
      transition: background-color $anim-speed $anim-curve, border $anim-speed $anim-curve, color $anim-speed $anim-curve;
      background-color: $primary;
      border: 1px solid $primary;
      text-align: center;

      &:hover,
      &:focus,
      &:active {
        color: $white;
        background-color: $primary-hover;
        border: 1px solid $primary-hover;
      }

      &:first-of-type {
        margin-left: 0;
      }
    }
  }
}
