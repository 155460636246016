.vb-category-slider {

  .container {
    position: relative;
  }

  .carousel {
    margin: 0 -5px;

    a {
      display: flex;
    }
  }

  .gimmick {
    color: $grey-border;
    position: absolute;
    top: -45px;
    left: -60px;

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
}
