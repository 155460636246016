.button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: $white;
  padding: 10px 20px;
  font-size: .812rem;
  text-transform: uppercase;
  font-weight: $font-bold;
  transition: background-color $anim-speed $anim-curve, border $anim-speed $anim-curve, color $anim-speed $anim-curve;

  // Colors
  &.button-primary {
    background-color: $primary;
    border: 1px solid $primary;
    text-align: center;

    &:hover,
    &:focus,
    &:active {
      color: $white;
      background-color: $primary-hover;
      border: 1px solid $primary-hover;
    }

    &.disabled,
    &:disabled,
    &[disabled] {
      opacity: .5;
      cursor: not-allowed;

      &:hover,
      &:focus,
      &:active {
        background-color: $primary;
        border: 1px solid $primary;
      }
    }
  }

  &.button-white {
    background-color: $white;
    border: 1px solid $white;
    text-align: center;
    color: $black;

    &:hover,
    &:focus,
    &:active {
      color: $white;
      background-color: transparent;
      border: 1px solid $white;
    }

    &.color-primary {
      color: $primary;

      &:hover,
      &:focus,
      &:active {
        color: $white;
      }
    }

    &.disabled {
      opacity: .5;
      cursor: not-allowed;

      &:hover,
      &:focus,
      &:active {
        background-color: transparent;
        border: 1px solid $white;
      }
    }
  }

  // Outline
  &.button-outline-black {
    border: 1px solid $black;
    background-color: $white;
    color: $black;

    &:hover,
    &:focus,
    &:active {
      background-color: $black;
      color: $white;

      svg {
        color: $white;
      }
    }

    svg {
      margin-top: -4px;
      margin-right: 20px;
      color: $picto-dark;
      transition: color $anim-speed $anim-curve;
    }
  }

  &.button-outline-white {
    border: 1px solid $white;

    &:hover,
    &:focus,
    &:active {
      background-color: $white;
      color: $primary;

      svg {
        color: $primary;
      }
    }

    svg {
      margin-top: -4px;
      margin-right: 20px;
      color: $white;
      transition: color $anim-speed $anim-curve;
    }
  }

  &.button-outline-primary {
    border: 1px solid $primary;
    background-color: $white;
    color: $primary;

    &:hover,
    &:focus,
    &:active {
      background-color: $primary;
      color: $white;

      svg {
        color: $white;
      }
    }
  }

  &.button-outline-tertiary {
    border: 1px solid $tertiary;
    background-color: transparent;
    color: $tertiary;

    &:hover,
    &:focus,
    &:active {
      background-color: $tertiary;
      color: $white;

      svg {
        color: $white;
      }
    }
  }

  &.button-outline-white-tertiary {
    border: 1px solid $white;
    background-color: transparent;

    &:hover,
    &:focus,
    &:active {
      background-color: $white;
      color: $tertiary;

      svg {
        color: $tertiary;
      }
    }

    svg {
      margin-top: -4px;
      margin-right: 20px;
      color: $white;
      transition: color $anim-speed $anim-curve;
    }
  }

  // Icons
  &.button-white-icon {
    background-color: $white;
    border: 1px solid $white;
    padding: 0 15px 0 0;
    height: 44px;
    color: $primary;
    text-transform: uppercase;

    &:hover,
    &:focus {
      color: $white;
      background-color: transparent;
      border-color: $white;
    }

    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 44px;
      height: 100%;
      margin-right: 15px;
    }
  }

  &.button-primary-icon {
    background-color: $primary;
    border: 1px solid $primary;
    padding: 0 15px 0 0;
    height: 44px;
    text-transform: uppercase;

    &:hover,
    &:focus {
      color: $white;
      background-color: $primary-hover;
      border-color: $primary-hover;
    }

    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 44px;
      height: 100%;
      margin-right: 15px;
    }

    &.disabled,
    &:disabled,
    &[disabled] {
      opacity: .5;
      cursor: not-allowed;

      &:hover,
      &:focus,
      &:active {
        background-color: $primary;
        border: 1px solid $primary;
      }
    }
  }

  &.button-outline-primary-icon {
    background-color: $white;
    border: 1px solid $primary;
    padding: 0 15px 0 0;
    height: 44px;
    color: $primary;
    text-transform: uppercase;

    &:hover,
    &:focus,
    &:active {
      background-color: $primary;
      color: $white;
    }

    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 44px;
      height: 100%;
      margin-right: 15px;
    }
  }

  &.button-outline-white-tertiary-icon {
    background-color: transparent;
    border: 1px solid $white;
    padding: 0 15px 0 0;
    height: 44px;
    color: $white;
    text-transform: uppercase;

    &:hover,
    &:focus {
      color: $tertiary;
      background-color: $white;
      border-color: $white;
    }

    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 44px;
      height: 100%;
      margin-right: 15px;
    }
  }

  // Button border
  &.button-border-icon {
    font-size: .75rem;
    color: $primary;
    border-bottom: 1px solid $tertiary;
    font-weight: $font-black;
    display: flex;
    align-items: end;
    padding: 5px 0;
    margin-bottom: 15px;
    font-style: italic;

    svg {
      color: $tertiary;
      transition: color $anim-speed $anim-curve;
    }

    .text {
      flex: 1;
    }

    &:hover,
    &:focus,
    &:active {
      color: $primary-hover;
      border-bottom: 1px solid $tertiary-hover;

      svg {
        color: $tertiary-hover;
      }
    }

    &:focus,
    &:active {
      border-color: transparent;
    }
  }
}

// Menu specialist
.button-outline-primary-icon.button-lighting-specialist {
  margin-right: 30px;
  justify-content: unset;

  @include media-breakpoint-down(lg) {
    flex-direction: column;
    font-size: 14px;
    font-weight: $font-regular;
    text-transform: none;
    background-color: transparent;
    border: 0;
    padding: 0;
    margin-right: .5rem;
    width: 60px;
    height: unset;

    &:hover,
    &:focus {
      background-color: transparent;
      border: 0;

      .icon {
        color: $tertiary-hover;
        border: 0;
      }
    }

    .icon {
      color: $tertiary;
      background-color: transparent;
      margin-right: 0;
      transition: color $anim-speed $anim-curve;
      width: unset;
      height: unset;
    }

    svg {
      width: 25px;
      height: 24px;
    }
  }

  @include media-breakpoint-down(xs) {
    width: 40px;
  }

  .text {

    @include media-breakpoint-down(xs) {
      font-size: 10px;
    }

    &.desktop {

      @include media-breakpoint-down(lg) {
        display: none;
      }
    }

    &.mobile {
      color: $black;

      @include media-breakpoint-up(xl) {
        display: none;
      }
    }
  }
}

.btn-fitter {
  margin-left: 10px;
  background-color: $primary;
  border: 1px solid $primary;
  padding: .25rem 1rem;
  height: 41px;
  color: $white;
  text-transform: uppercase;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: .25rem;
  min-width: 150px;

  @include media-breakpoint-mobile {
    margin-left: 0;
    width: 100%;
    padding: 1rem 0;
    align-items: end;
  }

  &:hover,
  &:focus {
    color: $white;
    background-color: $primary-hover;
    border-color: $primary-hover;
  }

  span {
    position: relative;
    top: 2px;
  }
}
