.home-title {
  position: relative;
  margin-top: 3rem;
  margin-bottom: -30px;
  font-size: 2.25rem;
  font-weight: $font-light;
  text-transform: none;
  font-family: $font-family-quaternary;
  z-index: 1;
}

.page-title {
  font-size: 2rem;
  font-weight: $font-black;
}

.product-title-page {
  margin-bottom: 1rem;
  font-size: 1.875rem;
  font-weight: $font-bold;
  text-transform: none;
}

.page-title-small {
  font-size: 22px;
  font-weight: $font-black;
  text-transform: none;
  margin-bottom: 30px;
}

.squared-title {
  position: relative;
  display: flex;
  font-size: 22px;
  font-weight: $font-black;
  text-transform: none;
  margin-bottom: 20px;
  z-index: 1;

  &::before {
    content: "";
    display: block;
    flex-shrink: 0;
    margin-right: 10px;
    width: 21px;
    height: 28px;
    background-image: url("../img/title-gim.svg");
  }
}

.title-big {
  font-size: 30px;
  font-weight: $font-bold;
  margin-bottom: 20px;
}
