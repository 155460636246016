.l-header {
  position: relative;
  background-color: $header-bg;
  z-index: $zindex-sticky;
  transition: top $anim-speed $anim-curve, border-bottom $anim-speed $anim-curve;
  border-bottom: 5px solid $white;
  margin-bottom: 15px;

  &.is-stuck {
    position: sticky;
    top: -41px;

    @include media-breakpoint-mobile {
      top: 0;
    }
  }

  .pre-header {
    padding: 3px 0;
    background-color: $grey-bg-dark;
  }

  .pre-header-nav {

    > ul {
      margin-bottom: 0;

      > li {
        margin-right: 30px;

        > a {
          color: $black;
          transition: color $anim-speed $anim-curve;

          &:hover,
          &:focus {
            color: $primary;
          }
        }
      }
    }
  }

  .header-top {
    position: relative;
    flex-wrap: wrap;
    padding-top: 10px;

    @include media-breakpoint-mobile {
      padding-bottom: 5px;
    }
  }

  .logo-wrapper {
    padding-bottom: 10px;
    width: 115px;
    transition: width $anim-speed $anim-curve;

    @include media-breakpoint-mobile {
      padding-bottom: 0;
      width: 75px;
    }

    @include media-breakpoint-down(xs) {
      width: 65px;
    }
  }

  .burger-wrapper {
    margin-right: -.75rem;

    svg {

      @include media-breakpoint-down(xs) {
        width: 24px;
      }
    }
  }

  .nav-wrapper {
    width: calc(100% - 115px);
    padding-left: 30px;
    transition: width $anim-speed $anim-curve;
    align-items: center;
    padding: .5rem 0;

    @include media-breakpoint-mobile {
      width: calc(100% - 75px);
    }

    @include media-breakpoint-down(xs) {
      width: calc(100% - 65px);
    }
  }

  .right-actions {
    margin-left: auto;

    @include media-breakpoint-mobile {
      margin-top: 0;
    }

    .shopping-cart {
      position: relative;

      .cart-counter {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        top: -8px;
        right: 5px;
        width: 21px;
        height: 21px;
        border-radius: 100%;
        background-color: $primary;
        color: $white;
        font-size: .75rem;

        @include media-breakpoint-down(xs) {
          right: -5px;
        }
      }
    }

    .action-button {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: $black;

      &:hover,
      &:focus {

        .icon {
          color: $tertiary-hover;
        }
      }

      .icon {
        color: $tertiary;
        transition: color $anim-speed $anim-curve;
      }

      .text,
      .blockcart__label {
        text-align: center;
        color: $black;
        white-space: nowrap;

        @include media-breakpoint-down(xs) {
          font-size: 10px;
        }
      }
    }
  }
}

.header-bottom {
  flex-grow: 1;
  padding: 10px 20px;
  background-color: $tertiary;
  color: $white;
  font-weight: $font-bold;

  .title {
    font-size: 9px;
    text-transform: uppercase;
    margin-right: 20px;
  }

  .nav-alt {
    margin-bottom: 0;
  }

  ul {
    margin-bottom: 0;
  }

  li {
    position: relative;

    &:not(:last-of-type) {
      margin-right: 25px;

      &::after {
        content: "";
        position: absolute;
        width: 1px;
        height: 100%;
        right: -12px;
        top: 0;
        background-color: $primary;
      }
    }
  }

  a {
    color: $white;
    font-family: $font-family-secondary;
    text-transform: uppercase;
    font-size: 14px;

    &:hover,
    &:focus {
      text-decoration: underline;
    }

    @include media-breakpoint-only(lg) {
      font-size: 12px;
    }

    @media screen and (min-width: 1200px) {
      font-size: 14px;
    }

    svg {
      color: $white;
      width: 24px;
      height: 24px;
      margin-right: 5px;
    }

    &.sub-header-menu-item {
      span {
        position: relative;
        top: 1px;
      }
    }
  }

  .color {
    width: 16px;
    height: 16px;
    transform: rotate(-7deg);
    margin-right: 10px;
  }
}

// Menu Fitter
.fitter + .fake-end {
  width: 50px;
  background-color: $tertiary;
  margin-left: 10px;
}
