.vb-product-slider {

  &.grey-bg {
    background-color: $grey-bg;
  }

  .carousel {
    margin: 0 -5px;
  }

  .product-miniature {
    flex: none;
    max-width: none;
  }
}
