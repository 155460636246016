.checkout-process {

  #checkout-personal-information-step,
  #checkout-addresses-step,
  #checkout-payment-step {

    a {
      color: $primary;
      transition: color $anim-speed $anim-curve, background-color $anim-speed $anim-curve;

      &:hover,
      &:focus,
      &:active {
        color: $primary-hover;
      }
    }
  }

  .carrier-price {
    color: $primary;
  }
}

.order-checkout {

  .link__showsummary {
    color: $primary;
    transition: color $anim-speed $anim-curve;

    &:hover,
    &:focus,
    &:active {
      color: $primary-hover;
    }
  }
}
