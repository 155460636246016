.vb-text-layer {
  padding: 50px 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  color: $white;

  .cta {
    margin-top: 20px;
  }
}
