.eci {
  margin-top: 1.5rem;

  .tag {
    display: inline-flex;
    background-color: $tertiary;
    color: $white;
    text-transform: uppercase;
    padding: .5rem;
  }

  .grey-box {
    background-color: $grey-bg;
    padding: .5rem;
    border-radius: .4rem;
    margin-top: 1rem;
  }

  .eci-price {
    font-size: 1.25rem;
    color: $eci;
    vertical-align: bottom;

    strong {
      color: $black;
    }
  }

  .see-more-link {
    text-align: right;
    width: 100%;
    display: inline-block;
    text-transform: uppercase;
    color: $primary;
    font-size: .75rem;

    &:hover,
    &:focus {
      color: $primary-hover;
    }
  }

  img {
    width: 1.5rem;
  }
}
