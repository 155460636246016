.vb-text-image-layer {

  .cta {
    margin-top: 30px;
  }

  .image {

    img {
      width: 100%;
    }
  }
}
