#checkout {

  .address-item {

    .card {
      height: 100%;
    }

    .card-footer {
      display: flex;
      justify-content: space-between;
    }
  }
}
