#contact {

  .page-wrapper {
    border: 0;

    .page-content {

      @include media-breakpoint-mobile {
        padding: 0;
        margin-bottom: 30px;
      }
    }
  }

  .contact-rich {
    background-color: $grey-bg;
    padding: 1.25rem;
    height: 100%;

    .block {
      display: flex;

      .icon {
        margin-right: 5px;
      }

      i {
        font-size: 16px;
      }

      a {
        color: $black;
        font-weight: $font-bold;
        text-decoration: underline;
        transition: color $anim-speed $anim-curve;

        &:hover,
        &:focus {
          color: $primary;
        }
      }
    }
  }

  .grey-bg {
    background-color: $grey-bg;
  }
}
