.banner-wrapper {
  margin-bottom: 50px;

  &.no-image {

    .banner {

      @include media-breakpoint-desktop {
        background: $tertiary;
      }
    }
  }

  .banner {
    background: $tertiary;

    @include media-breakpoint-desktop {
      background: linear-gradient(90deg, rgba($tertiary, 1) 0%, rgba($tertiary, 1) 67%, rgba($white, 1) 67%);
    }
  }

  .content {
    width: 67%;
    color: $white;

    @include media-breakpoint-mobile {
      order: 2;
      width: 100%;
    }
  }

  .image {
    width: 33%;
    margin-left: auto;

    @include media-breakpoint-mobile {
      order: 1;
      width: 100%;
    }
  }

  .left,
  .right {
    width: 50%;
    padding: 30px;

    @include media-breakpoint-mobile {
      width: 100%;
    }
  }

  .left {

    @include media-breakpoint-mobile {
      padding: 30px 30px 0 30px;
      margin-bottom: 15px;
    }
  }

  .right {

    @include media-breakpoint-mobile {
      padding: 0 30px 30px 30px;
    }
  }

  .title {
    height: calc(50% - 22px);

    @include media-breakpoint-mobile {
      height: auto;
    }
  }

  .ctas {

    .cta-wrapper {

      &:not(:last-of-type) {
        margin-bottom: 15px;
      }
    }
  }

  .banner-title {
    font-size: 24px;
    font-weight: $font-bold;
    text-transform: none;
  }

  .banner-desc-title {
    font-size: 22px;
    font-weight: $font-bold;
  }

  .image {

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
