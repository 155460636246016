.vb-six-cards {
  margin: 30px 0;
  background-color: $grey-bg;

  .row-cards {
    margin-left: -2px;
    margin-right: -2px;

    > div {
      padding-left: 2px;
      padding-right: 2px;

      @include media-breakpoint-down(md) {
        margin-bottom: 4px;
      }
    }
  }

  .card-image-simple {
    height: 100%;
    overflow: hidden;

    &:hover {

      .image {

        img {
          transform: scale(1.05);
        }
      }
    }

    .image {
      height: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: transform $anim-speed-slow $anim-curve;
      }
    }
  }

  .card-fitter-wrapper {
    height: 100%;
  }

  .card-fitter {
    height: 100%;
    background-color: $secondary;
    transition: background-color $anim-speed $anim-curve;

    &:hover,
    &:focus {
      background-color: $secondary-hover;
    }
  }

  .card-image-simple,
  .card-fitter {
    position: relative;
    color: $white;

    @include media-breakpoint-down(xs) {
      height: 200px;
    }

    .overlay {
      position: absolute;
      opacity: .75;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(180deg, transparent 75%, $black);
      z-index: 1;

      @include media-breakpoint-down(xs) {
        background-image: linear-gradient(180deg, transparent 50%, $black);
      }
    }

    .text-content {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 10px 20px;
      text-align: center;
      z-index: 2;

      .card-title {
        font-size: 14px;
        font-family: $font-family-tertiary;
        font-weight: $font-bold;
        text-transform: uppercase;
      }
    }
  }
}
