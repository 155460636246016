.block-reassurance-inst-wrapper {
  height: 100%;
}

.block-reassurance-inst {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: $secondary;
  background-image: url(../img/static/installer.png);
  background-position: calc(100% + 120px) center;
  background-repeat: no-repeat;
  padding: 30px;
  height: 100%;
  color: $black;

  .title {
    font-size: 2.625rem;
    font-weight: $font-bold;
    line-height: 1;
    font-family: $font-family-secondary;
  }

  .description {
    font-weight: $font-bold;
    font-size: 1rem;

    ul {
      font-weight: $font-bold;

      li {

        &::before {
          background-color: $white;
        }
      }
    }
  }

  .button {
    align-self: flex-start;
    padding: 10px;
  }
}
