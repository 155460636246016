#category {

  #main {
    padding-left: 75px;

    @include media-breakpoint-down(lg) {
      padding-left: 0;
    }
  }
}
