.card-blog-item {
  height: 100%;
  background-color: $grey-bg;
  transition: box-shadow $anim-speed-slow $anim-curve;

  &:hover {
    box-shadow: $card-hover-box-shadow-big;

    .image {
      height: 33%;

      img {
        width: 100%;
        object-fit: cover;
        object-position: center;
        transform: scale(1.05);
      }
    }

    .card-title {

      a {
        color: $grey-text;
      }
    }
  }

  .image {
    overflow: hidden;
    height: 33%;

    img {
      width: 100%;
      aspect-ratio: 16 / 7;
      object-fit: cover;
      object-position: center;
      transition: transform $anim-speed-slow $anim-curve;
    }
  }

  .content {
    flex-grow: 1;
    padding: 15px 20px;
  }

  .pre-title {
    margin-bottom: 10px;
  }

  .card-title {
    font-size: 18px;
    font-weight: $font-black;
    transition: color $anim-speed $anim-curve;

    a {
      color: $black;
      transition: color $anim-speed $anim-curve;
    }
  }

  .news-description {
    margin-bottom: 15px;
  }

  .cta {
    margin-top: auto;
  }
}
