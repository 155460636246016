.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
.products-section-title,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-family-main;
}
