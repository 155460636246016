.vb-blog {
  .title-big {
    margin-bottom: 10px;
  }

  .subtitle {
    margin-bottom: 30px;
  }
}

.post-image {
  img {
    object-fit: cover;
    object-position: center;
    height: 20rem;
    overflow: hidden;

    @include media-breakpoint-mobile {
      height: 12rem;
    }
  }
}

.img-ratio-wrapper {
  position: relative;
  padding-bottom: 56.25%; // 16 / 9
  @include media-breakpoint-mobile {
    padding-bottom: 25%; // 16 / 4
  }
  width: 100%;

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
}
