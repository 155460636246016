#identity {

  .box {
    background-color: $grey-bg;
    padding: 20px;
    height: 100%;

    .box-title {
      font-weight: $font-bold;
    }

    ul {
      margin-bottom: 0;
    }

    li {
      padding: 5px 0;

      &:last-of-type {
        padding-bottom: 0;
      }
    }
  }
}
