nav.breadcrumbs {

  .breadcrumb {
    padding: 0;
    background-color: transparent;
  }

  .breadcrumb-item {
    font-size: 12px;

    &.active {
      color: $black;
    }

    + .breadcrumb-item {

      &::before {
        content: ">";
        color: $grey-text-light;
      }
    }

    a {
      color: $grey-text-light;
      transition: color $anim-speed $anim-curve;

      &:hover {
        color: $grey-text;
      }
    }
  }
}
