// Listing
#module-prestashop_visual_composer-blog {

  .blog-menu {
    display: flex;

    @include media-breakpoint-mobile {
          overflow-x: auto;
    }

    li {

      white-space: nowrap;
      &:not(:last-of-type) {
        margin-right: 5px;
      }
    }

    a {
      display: block;
      padding: 5px 15px;
      background-color: $primary;
      color: $white;
      font-weight: $font-bold;
      text-transform: uppercase;
      transition: background-color $anim-speed $anim-curve;

      &:hover,
      &.active {
        background-color: $primary-hover;
      }
    }
  }
}
