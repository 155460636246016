#index {

  #wrapper {
    margin-top: 0;
  }

  .l-header {
    margin-bottom: 0;
  }

  .slider-block-right {
    display: flex;
    flex-direction: column;
    height: 100%;

    .title {
      font-size: 1.375rem;
      font-weight: $font-black;
    }

    .img-icon {
      width: 18px;
    }

    .description p {
      font-size: .875rem;
    }

    ul {
      list-style: none !important;
    }
  }

  .block-black {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    background-color: $gray-200;
    padding: 20px;
    color: $black;

    .title {
      font-size: 1.375rem;
      font-weight: $font-black;
      text-transform: none;
    }

    .button {
      padding: 11px;
    }

    img {
      width: 100%;
      height: auto;
    }

    .wysiwyg-content {

      p {

        &:not(:last-child) {
          margin-bottom: 10px;
        }
      }

      a {
        font-weight: $font-bold;
        color: $white;
      }

      li::before {
        content: "✔";
        width: unset;
        height: unset;
        background-color: transparent;
        color: $black;
        top: 0;
      }
    }
  }

  .block-reassurance,
  .block-reassurance-inst-wrapper {
    padding: 40px 0;

    @include media-breakpoint-mobile {
      padding: 20px 0;
    }
  }
}
