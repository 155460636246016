div.custom-control {

  .custom-control-input {

    &:checked {

      & ~ .custom-control-label {

        &::before {
          border-color: $primary;
          background-color: $primary;
        }
      }
    }
  }
}

input.password {
  padding: .375rem 46px .375rem .75rem;

  + .input-group-btn {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    z-index: 4;

    .btn {
      border-radius: 0;

      .icon {
        display: none;

        &.displayed {
          display: inline-block;
        }
      }
    }
  }
}

.text-notice-form {
  font-size: .875rem;
  color: $grey-text-light;
}

.form-group {

  label,
  .label {
    margin-bottom: .25rem;
  }
}

.form-group-accept { //This class is for a honey pot for bots in forms
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}
