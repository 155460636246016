// Bootstrap
table.table {

  &.table-striped {

    tbody tr:nth-of-type(odd) {
      background-color: $grey-bg;
    }
  }
}
