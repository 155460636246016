.modal-filters {

  .btn-outline-success {
    color: $black;
    border-color: $primary;
    background-color: $primary;
    font-weight: $font-bold;

    &:hover {
      background-color: $primary-hover;
      border-color: $primary-hover;
      color: $black;
    }
  }
}
