.vb-testimony {
  margin: 30px 0;
  background-color: $grey-bg;

  .image {
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .quotation {
    padding: 50px 50px 50px 75px;

    .title {
      position: relative;
      font-size: 22px;
      font-weight: $font-black;

      svg {
        position: absolute;
        color: $primary;
        top: -15px;
        left: -55px;
      }
    }

    .quote {
      font-size: 18px;
      font-style: italic;
    }

    .author {
      margin-top: 20px;
      margin-bottom: 0;
    }

    .cta {
      margin-top: 20px;
    }
  }
}
