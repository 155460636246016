.vb-card  {

  .row-cards {
    justify-content: center;
  }

  .col-item {

    @include media-breakpoint-down(md) {
      margin-bottom: 30px;
    }
  }

  .card-image {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    transition: box-shadow $anim-speed-slow $anim-curve;

    &:hover,
    &:focus {
      box-shadow: $card-hover-box-shadow-big;

      .text-content {
        padding: 15px 20px;
      }

      .title {
        color: $grey-text;
      }

      .image {

        img {
          transform: scale(1.05);
        }
      }
    }

    .image {
      overflow: hidden;
      margin-bottom: 20px;

      img {
        width: 100%;
        transition: transform $anim-speed-slow $anim-curve;
      }
    }

    .button {
      display: flex;
    }

    .text-content {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      padding: 15px 0;
      transition: padding $anim-speed $anim-curve;
    }

    .subtitle {
      margin-top: 5px;
    }

    .title {
      font-size: 18px;
      font-weight: $font-black;
      margin-bottom: 0;
      transition: color $anim-speed $anim-curve;
    }

    .cta {
      margin-top: auto;

      a {

        &::after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;

          @include media-breakpoint-down(xs) {
            display: none;
          }
        }
      }
    }
  }
}
