.pre-footer {

  .item-check {
    display: flex;
    align-items: center;
    color: $black;
    font-size: 1rem;
    margin-bottom: 20px;
    margin-right: 20px;
  }
}

.footer-top {
  background-color: $tertiary;
  color: $white;
  padding: 40px 0;

  .title {
    font-size: 1.375rem;
    font-weight: $font-black;

    @include media-breakpoint-mobile {
      margin-top: 20px;

      &.first-title {
        margin-top: 0;
      }
    }

  }

  .social-sharing {
    display: flex;
    width: 100%;

    a {

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    @include media-breakpoint-up(lg) {
      height: 100%;
      background-image: url(../img/static/shape.png);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: 75% 0;
    }

    @include media-breakpoint-up(xl) {
      background-position: 63% 0;
    }
  }
}

.footer-bottom {
  display: flex;
  justify-content: center;
  align-items: center;

  .item {
    color: $black;

    + .item {

      &::before {
        content: "|";
        margin: 0 10px;
      }
    }
  }

  .link {
    color: $black;
  }
}

.footer-nav {
  background-color: $grey-bg;
  padding: 40px 0;

  @include media-breakpoint-mobile {
    padding: 20px 0;
  }

  .footer-content {

    .top-menu-footer {
      display: flex;
      margin: 0 -10px;

      @include media-breakpoint-mobile {
        flex-direction: column;
      }
    }

    .item-top {
      width: 20%;
      padding: 0 10px;
      font-size: 1.25rem;

      @include media-breakpoint-mobile {
        width: 100%;
        font-size: 1rem;
      }

      &[aria-expanded="true"] {

        .chevron svg {
          transform: rotate(180deg);
        }
      }

      .link {
        color: $black;
        padding: 4px 0;
        display: inline-block;
      }
    }

    .submenu {

      .item-sub {
        font-weight: $font-regular;
        font-size: .875rem;
      }
    }
  }
}

.l-footer {
  color: $text-muted;

  .cms-page-link,
  .account-list a {
    color: $black;
    padding: 4px 0;
    display: inline-block;

    &:hover {
      color: $primary;
    }
  }
}

.footer-container{
  margin-top: $footer-container-margin-top;
}

.footer__title{
  color:$black;
  display: block;
  font-weight: $font-semi-bold;
  margin-bottom: $spacer/2;
  font-size: 1.25rem;

  &:visited,
  &:hover{
    color: $black;
  }

  &--mobile{
    display: none;
    margin-bottom: 0;
    padding: $spacer/2 0;
    border-bottom: 1px solid $border-color;

    &:after{
      content:"\e313";
      font-family: 'Material Icons';
      font-feature-settings: 'liga' 1;
      position: absolute;
      right: $grid-gutter-width/2;
      transition: .3s transform ease-in-out;
      font-size: $font-size-base *1.5;
    }
    &[data-toggle="collapse"][aria-expanded="true"]:after {
      transform:         rotate(180deg);
    }
  }
}

@include media-breakpoint-mobile {
  .footer__title{
    &--desktop{

      display: none;
    }
    &--mobile{
      display: block;
    }
  }
}
.footer__copyright{
  text-align: center;
  font-size: $font-size-sm;
}
.header__nav{
  align-items: center;
}
.header-nav__nav2{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.header-mobile{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.header-mobile__user-cart{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.header__top{
  display: flex;
  justify-content: space-between;
  position: static;
}
