#authentication {

  .squared-title {
    justify-content: start;
    margin: 0 auto 30px;
    width: 100%;
    max-width: 480px;
  }

  .form-wrapper {
    margin: 0 auto 50px;
    padding: 30px;
    width: 100%;
    max-width: 480px;
    background-color: $grey-bg;

    .no-account {
      margin-top: 20px;
      font-size: .875rem;

      a {
        color: $tertiary;
        transition: color $anim-speed $anim-curve;

        &:hover,
        &:focus,
        &:active {
          color: $tertiary-hover;
        }
      }
    }

    .forgot-password {
      text-align: right;

      a {
        color: $tertiary;
        transition: color $anim-speed $anim-curve;

        &:hover,
        &:focus,
        &:active {
          color: $tertiary-hover;
        }
      }
    }

    .form-control {
      border: 0;
    }
  }

  // Registration
  .register-form {

    a {
      color: $tertiary;
      transition: color $anim-speed $anim-curve;

      &:hover,
      &:focus,
      &:active {
        color: $tertiary-hover;
      }
    }
  }
}
