.row-cards-twelve {
  margin-left: -5px;
  margin-right: -5px;
  row-gap: 30px;

  > div {
    padding: 5px;

    @media screen and (min-width: 400px) and (max-width: 767px) {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
}

.row-cards-twelve-slider {

  .slick-slide {

    > div {
      height: 50%;

      &:first-of-type {
        height: calc(50% - 30px);
        margin-bottom: 30px;
      }
    }
  }
}

.cat-item {
  padding: 5px;
}

.card-cat {
  position: relative;

  &:hover,
  &:focus {

    img {
      transform: scale(1.05);
    }
  }

  .image {
    border: 1px solid $grey-border;
    overflow: hidden;
  }

  img {
    width: 100%;
    aspect-ratio: 4 / 3;
    object-fit: cover;
    transition: transform $anim-speed-slow $anim-curve;
  }

  .card-title {
    margin-bottom: 10px;
    bottom: 0;
    font-size: 14px;
    color: $black;
    text-transform: uppercase;
    font-weight: $font-bold;
    font-family: $font-family-tertiary;
  }

  .text-link {
    display: flex;
    justify-content: center;
    margin-top: 15px;
    text-align: center;
  }
}
