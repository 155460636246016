.mainmenu {

  .depth-0 {
    display: flex;
    margin-bottom: 0;

    @include media-breakpoint-mobile {
      display: block;
    }

    > li {
      padding: 5px 15px;

      @include media-breakpoint-only(lg) {
        padding: 5px 10px;
      }

      @include media-breakpoint-mobile {
        padding: 0;
      }

      &:hover,
      &:focus {

        > a {
          color: $primary;
        }
      }

      &.menu-item-opened {

        > .submenu-wrapper {
          display: block;
          opacity: 1;
          top: 100%;

          @include media-breakpoint-mobile {
            height: auto;
          }
        }

        > a {
          color: $primary;

          > .chevron svg {
            transform: rotate(-270deg);
            color: $primary-hover;
          }
        }
      }

      > a {
        font-weight: $font-bold;
        text-transform: uppercase;
        font-size: 16px;
        color: $black;
        transition: color $anim-speed $anim-curve;

        @include media-breakpoint-only(lg) {
          font-size: 13px;
        }

        @media screen and (min-width: 1200px) {
          font-size: 16px;
        }

        @include media-breakpoint-mobile {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 10px 0;
          border-bottom: 1px solid $grey-border;
        }

        > .chevron svg {
          margin-left: 5px;
          color: $primary;
          transform: rotate(-90deg);
          transition: color $anim-speed $anim-curve, transform $anim-speed $anim-curve;

          @include media-breakpoint-mobile {
            flex-shrink: 0;
            margin-left: 20px;
          }
        }
      }

      > .submenu-wrapper {
        position: absolute;
        padding: 30px 0;
        width: 100%;
        left: 0;
        background-color: $white;
        z-index: $zindex-sticky;
        display: none;
        opacity: 0;
        top: calc(100% - 10px);
        box-shadow: 0 15px 15px 0 rgba($black, .2);
        transition: opacity $anim-speed $anim-curve, top $anim-speed $anim-curve;

        @include media-breakpoint-mobile {
          position: relative;
          padding: 0;
          margin: 0 -15px;
          width: calc(100% + 30px);
          height: 0;
          box-shadow: none;
        }
      }
    }
  }

  .highlighted-categories {

    > ul {
      position: relative;
      padding-bottom: 15px;
      margin-bottom: 20px;

      &::after {
        content: "";
        position: absolute;
        width: calc(100% - 30px);
        height: 5px;
        top: 100%;
        left: 15px;
        background-color: $grey-bg;
      }
    }
  }

  .depth-1,
  .highlighted-categories > ul {
    display: flex;
    flex-wrap: wrap;

    > li {
      width: 25%;
      padding: 0 15px;

      @include media-breakpoint-desktop {
        margin-bottom: 20px;
      }

      @include media-breakpoint-mobile {
        padding: 0;
        width: 100%;
      }

      &.separator {
        width: 100%;
        padding: 0 15px;

        @include media-breakpoint-mobile {
          display: none;
        }

        .background {
          background-color: $grey-bg;
          width: 100%;
          height: 5px;
        }
      }

      > a {
        color: $black;
        transition: color $anim-speed $anim-curve;

        @include media-breakpoint-mobile {
          display: flex;
          align-items: center;
          padding: 10px 0;
          border-bottom: 1px solid $grey-border-light;
        }

        &[href] {

          &:hover,
          &:focus {
            color: $primary;
          }
        }

        > img {
          margin-right: 10px;
        }
      }

      > .menu-item-link-hassubmenu {
        display: block;
        padding-bottom: 10px;
        margin-bottom: 10px;
        width: 100%;
        border-bottom: 1px solid $grey-border-light;
        font-weight: 600;
        text-transform: uppercase;

        @include media-breakpoint-mobile {
          border-bottom: none;
          text-transform: none;
        }

        .chevron {
          display: none;
        }
      }
    }

    .topitem {

      > a {
        font-weight: $font-bold;
        text-transform: uppercase;

        img {
          display: none;
        }
      }
    }
  }

  .depth-2 {

    > li {

      > a {
        display: inline-block;
        margin-bottom: 5px;
        color: $black;
        transition: color $anim-speed $anim-curve;

        &:hover,
        &:focus,
        &:active {
          color: $primary;
        }

        &::before {
          content: "";
          display: inline-block;
          vertical-align: middle;
          margin-right: 5px;
          width: 8px;
          height: 2px;
          background-color: $primary;
        }
      }
    }
  }


  .about-menu {

    .depth-1 {
      max-width: 75%;

      @include media-breakpoint-down(lg) {
        max-width: none;
      }

      > li {
        @include media-breakpoint-desktop {
          width: 33%;
        }

        > a {
          display: block;
          margin-bottom: 5px;
          color: $black;
          transition: color $anim-speed $anim-curve;

          &:hover,
          &:focus,
          &:active {
            color: $primary;
          }

          &::before {
            content: "";
            display: inline-block;
            vertical-align: middle;
            margin-right: 5px;
            width: 8px;
            height: 2px;
            background-color: $primary;
          }
        }
      }
    }
  }
}
