.pagination-wrapper {
  height: 58px;
  border-top: 1px solid $grey-border;
  border-bottom: 1px solid $grey-border;

  .pagination-summary {
    align-items: center;
    width: 100%;
  }

  nav {
    flex-shrink: 0;
  }
}

ul.pagination {
  margin-bottom: 0;

  li {
    height: 100%;

    &:not(:last-of-type) {
      margin-right: 2px;
    }

    &:hover {

      .page-link {
        background-color: $grey-border;
      }
    }

    &.active {

      .page-link {
        color: $white;
        background-color: $primary;
      }
    }
  }

  .page-link {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    padding: 0;
    border: 0;
    font-size: 16px;
    font-weight: $font-bold;
    color: $black;
    transition: background-color $anim-speed $anim-curve;
  }
}
