@import "./visual-composer/blog";
@import "./visual-composer/card";
@import "./visual-composer/category-slider";
@import "./visual-composer/gallery";
@import "./visual-composer/product-slider";
@import "./visual-composer/product-category-slider";
@import "./visual-composer/six-cards";
@import "./visual-composer/text-image-layer";
@import "./visual-composer/text-layer";
@import "./visual-composer/testimony";
@import "./visual-composer/video";

.vb-block {
  padding: 30px 0;
}

div.visual-composer-page {
  background-color: transparent;
  padding: 0;
  text-align: left;

  .top {
    display: flex;
    justify-content: space-between;
    padding-bottom: 30px;

    .text {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .introduction {
      font-weight: $font-semi-bold;
    }

    .image {
      flex-shrink: 0;
      margin-left: 30px;
    }
  }
}

