.search-filters {
  padding: 0;

  .facet-block {

    hr {
      margin: 0;
    }
  }

  .facet-header {

    .facet-title {
      display: flex;
      align-items: center;
      padding: 15px 0;
      color: $black;
      font-size: 16px;
      font-weight: $font-black;

      &.collapsed {

        .minus {
          display: none;
        }

        .plus {
          display: block;
        }
      }

      .plus {
        display: none;
      }

      .icon {
        flex-shrink: 0;
        margin-left: auto;
      }
    }
  }

  .collapse-facet {

    .custom-control {

      &:last-of-type {
        margin-bottom: 15px;
      }
    }
  }

  .active-filters {

    a {

      &:not(:last-of-type) {
        margin-right: 5px;
        margin-bottom: 5px;
      }
    }
  }

  .ui-slider {
    width: calc(100% - 7px);
  }

  .clear-all-wrapper {
    margin-top: 15px;
  }
}

div.faceted-overlay {
  z-index: $zindex-modal-backdrop;
}
