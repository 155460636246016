#history {

  .table-wrapper {

    @include media-breakpoint-down(xl) {
      overflow-x: scroll;
    }
  }

  .table-orders {
    min-width: 900px;

    thead {

      th {
        border-top: 0;
      }
    }

    .view-link {
      font-weight: $font-bold;
      color: $primary;
      text-decoration: underline;
    }
  }

  .orders-mobile {

    .order {
      padding-bottom: 15px;
      margin-bottom: 15px;

      &:not(:last-of-type) {
        border-bottom: 1px solid $grey-border;
      }
    }

    .order-ref {
      color: $black;
      transition: color $anim-speed $anim-curve;

      &:hover,
      &:focus,
      &:active {
        color: $primary;
      }
    }
  }
}
