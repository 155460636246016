// Colors
$black: #000;
$grey-text: #565656;
$grey-banner: #656565;
$grey-text-light: #99a1a6;
$grey-border: #d2d2d2;
$grey-border-light: #dfdfdf;
$grey-bg-dark: #e4e8ec;
$grey-gradient: #ededed;
$grey-bg: #f1f1f1;
$white: #fff;

$primary: #5290b5;
$primary-hover: darken($primary, 10%);
$secondary: #ffce28;
$secondary-hover: darken($secondary, 10%);
$tertiary: #41587c;
$tertiary-hover: darken($tertiary, 10%);

$picto-dark: #2e3a59;
$eci: #80cc28;

$success: #2ea745;

// Gradients
$product-gradient: linear-gradient(180deg, $white 0%, $grey-gradient 100%);

// Fonts
$font-family-main: 'Lato', sans-serif;
$font-family-secondary: 'Source Sans 3', sans-serif;
$font-family-tertiary: 'Raleway', sans-serif;
$font-family-quaternary: 'Josefin Sans', sans-serif;

$font-light: 300;
$font-regular: 400;
$font-semi-bold: 600;
$font-bold: 700;
$font-black: 900;

// Transitions
$anim-speed: .3s;
$anim-speed-slow: .5s;
$anim-curve: cubic-bezier(.5, 0, .5, 1);
$anim-curve-links: cubic-bezier(.075,.82,.165,1);

// Z-indexes
$zindex-dropdown: 1000;
$zindex-sticky: 1020;
$zindex-fixed: 1030;
$zindex-modal-backdrop: 1040;
$zindex-modal: 1050;
$zindex-popover: 1060;
$zindex-tooltip: 1070;

// Box-shadows
$card-hover-box-shadow-small: rgba($black, .1) 0 4px 6px -1px, rgba($black, .06) 0 2px 4px -1px;
$card-hover-box-shadow-big: rgba(50, 50, 93, .25) 0 50px 100px -20px, rgba($black, .3) 0 30px 60px -30px;
$btn-box-shadow: 0 2px 4px rgba(96, 97, 112, 0.16), 0 0 1px rgba(40, 41, 61, .04);

// Breakpoints
$grid-breakpoints: (
  xs: 568px,
  sm: 667px,
  md: 768px,
  lg: 992px,
  xl: 1350px,
  xxl: 1440px,
);

// colors type promos
$color-destockage: $secondary;
$color-nouveaute: $primary;
$color-promo-en-cours: $primary;
$color-remise-verte: #76B864;
$color-fin-de-serie: $secondary;
