.modal-menu {

  .sub-nav {
    margin: 1rem 0;
    background-color: $tertiary;
    padding: 1rem;

    .title {
      font-size: 9px;
      text-transform: uppercase;
      margin-right: 20px;
      margin-bottom: 5px;
    }

    ul {
      margin-bottom: 0;
    }

    li {

      &:not(:last-of-type) {

        a {
          border-bottom: 1px solid $white;
        }
      }

      a {
        display: flex;
        padding: 10px 0;
        color: $white;
        font-family: $font-family-secondary;
        text-transform: uppercase;

        svg {
          width: 24px;
          height: 24px;
          margin-right: 5px;
        }
      }
    }

    .color {
      width: 16px;
      height: 16px;
      transform: rotate(-7deg);
      margin-right: 10px;
    }
  }

  .pre-header-nav {
    display: flex;
    justify-content: center;
    margin-top: 1rem;

    ul {
      margin-bottom: 0;
      width: 100%;

      @include media-breakpoint-mobile {
        flex-direction: column;
      }
    }

    li {
      @include media-breakpoint-mobile {
        border-top: 1px solid $secondary;
        height: 2rem;
        display: flex;
        flex-direction: column;
        justify-content: center;

        &:last-of-type {
          border-top: 1px solid $secondary;
          border-bottom: 1px solid $secondary;
        }

        &:not(:last-of-type) {
          margin-right: 7px;
        }
      }

      a {
        transition: color $anim-speed $anim-curve;
        color: $black;

        &:hover {
          color: $primary;
        }
      }
    }
  }
}
