#order-confirmation {

  #content-hook_payment_return {

    a {
      color: $primary;
      transition: color $anim-speed $anim-curve;

      &:hover,
      &:focus,
      &:active {
        color: $primary-hover;
      }
    }
  }
}
