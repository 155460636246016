.account-links {
  @extend .align-items-stretch;

  > a {
    margin-bottom: $spacer;

    &:hover,
    a:focus,
    &:active {

      .link-item {
        color: $grey-text;

        i {
          color: $primary-hover;
        }
      }
    }
  }

  .link-item {
    @extend .card;
    box-shadow:$card-box-shadow;;
    padding: $spacer;
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    height: 100%;
    color: $black;
    transition: color $anim-speed $anim-curve;

    i {
      margin: 0 auto $spacer;
      font-size: $font-size-base*3;
      color: $primary;
      transition: color $anim-speed $anim-curve;
    }
  }
}

.address {
  @extend .card;
  height: 100%;

  &.billing-address {
    background-color: $grey-bg;
  }
}

.address-header {
  @extend .card-header;
  background-color: transparent;
}

.address-body {
  @extend .card-body;
}

.address-footer {
  @extend .card-footer;
  display: flex;
  justify-content: space-between;

  a {
    color: $tertiary;
    transition: color $anim-speed $anim-curve;

    &:hover,
    &:focus,
    &:active {
      color: $tertiary-hover;
    }
  }
}

.form--100 {
  min-width: 100%;
  width: 100%;
}

.side-nav {

  @include media-breakpoint-mobile {
    margin-bottom: 30px;
  }

  ul {
    border: 1px solid $grey-border-light;
    margin-bottom: 0;
  }

  li {

    &:not(:last-of-type) {
      border-bottom: 1px solid $grey-border-light;
    }
  }

  a {
    display: flex;
    padding: 10px;
    width: 100%;
    color: $grey-text;
    font-weight: $font-bold;
    transition: color $anim-speed $anim-curve, background-color $anim-speed $anim-curve;

    &:hover,
    &:focus,
    &:active,
    &.current {
      background-color: $primary;
      color: $white;

      i {
        color: $white;
      }
    }
  }

  i {
    color: $primary;
    margin-right: 10px;
    transition: color $anim-speed $anim-curve;
  }
}

.footer-ctas {

  a {

    &:not(:last-of-type) {
      margin-right: 10px;
    }
  }
}
