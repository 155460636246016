#product {
  overflow-x: hidden;

  .modal {

    .slick-arrow {
      color: $primary;
    }

    .slick-prev {

      svg {
        transform: rotate(90deg);
      }
    }

    .slick-next {

      svg {
        transform: rotate(-90deg);
      }
    }
  }

  .product-accessories {
    margin: 50px 0;
    padding: 50px 0;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      width: 200vw;
      height: 100%;
      top: 0;
      left: -50vw;
      background-color: $grey-bg;
    }

    .slick-track {
      margin-left: unset;
      margin-right: unset;
    }
  }

  .doc-item {
    background-color: $grey-bg;
    padding: 10px;

    &:not(:last-of-type) {
      margin-bottom: 5px;
    }

    ul {
      margin-bottom: 0;
    }

    li {

      a {
        display: flex;
        align-items: center;
      }

      svg {
        margin-right: 5px;
      }
    }

    .item-title {
      margin-bottom: 5px;
      font-weight: $font-bold;
    }
  }
}

.product-availability {
  &__message {
    font-weight: bold;
    font-size: 15px;
  }
}
