.header-search {
  display: none;
  width: 160px;
  min-width: 160px;

  @media (min-width: $screen-desktop) {
    display: block;
  }

  @media (min-width: 1200px) {
    width: auto;
  }
}
div#search_widget {
  margin: 0 auto;
  float: none;
  min-width: auto;

  @media (min-width: 1350px) {
    width: 320px;
  }

  @include media-breakpoint-mobile {
    border: 1px solid $grey-border;
    margin-bottom: 1rem;
  }

  form {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 360px;
    overflow: hidden;

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      top: 0;
      left: 0;
      width: 45px;
      height: 100%;
      border: 0;
      background-color: $tertiary;
      color: $white;
      padding: 10px 0;
      border-bottom-right-radius: .25rem;
      border-top-right-radius: .25rem;
      transition: background-color $anim-speed $anim-curve, color $anim-speed $anim-curve;

      &:hover,
      &:focus {
        background-color: $primary;
        color: $white;

        &::after {
          height: 100%;
        }
      }

      &::after {
        content: "";
        position: absolute;
        width: 1px;
        height: calc(100% - 4px);
        left: 100%;
        background-color: $primary;
        transition: height $anim-speed $anim-curve;
      }
    }

    input {
      padding: 7px 20px;
      background-color: $white;
      border-top-left-radius: .25rem;
      border-bottom-left-radius: .25rem;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-top: 1px solid $grey-border;
      border-bottom: 1px solid $grey-border;
      border-left: 1px solid $grey-border;
    }
  }
}

.search-widget__group {
  position: relative;
}

.search-widget__input-right {
  padding-right: 50px ;
}

.search-widget__btn {
  position: absolute;
  right: 0;
  top: 0;
}

@include media-breakpoint-mobile {

  .search-widget {
    max-width: unset;
  }
}
