/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;

  label {
    display: flex;
    align-items: center;
    gap: 5px;
    font-weight: $font-bold;
  }

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: relative;
    display: block;
    width: 60px;
    height: 34px;
    cursor: pointer;
    background-color: $grey-border;
    transition: .4s;

    &::before {
      position: absolute;
      content: "";
      height: 26px;
      width: 26px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      transition: .4s;
    }

    &.round {
      border-radius: 34px;

      &::before {
        border-radius: 50%;
      }
    }
  }

  input {

    &:checked + .slider {
      background-color: $primary;

      &::before {
        transform: translateX(26px);
      }
    }

    &:focus + .slider {
      box-shadow: 0 0 1px $primary;
    }
  }
}
