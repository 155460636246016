.products {
  @extend .row;
  align-items:stretch;
  margin-left: -5px;
  margin-right: -5px;
}

.product-miniature {
  padding: 0 5px;
  @extend .col-6;
}

@include media-breakpoint-up(sm) {

  .product-miniature {
    @include make-col(4)
  }
}

@include media-breakpoint-desktop {

  .product-miniature{
    @include make-col(3);
  }

  .layout-full-width .product-miniature {
    @include make-col(2);
  }
}

@media (max-width: 320px) {
  .product-miniature{
    @include make-col(12);
  }
}

.slick-slide .product-miniature {
  max-width: 100%;
}

/* Product miniature*/
.card-product.card {
  height:100%;
  position: relative;
  background-color: $pm-bg-color;
  border: 0;
  transition: box-shadow $anim-speed-slow $anim-curve;

  &:hover {
    box-shadow: $card-hover-box-shadow-small;

    .highlighted-informations {
      transform:translateY(0);
    }

    .quick-view {
      visibility: visible;
      opacity: 1;
    }

    .thumbnail {

      img {
        transform: scale(1.05);
      }
    }
  }

  .discount {
    display:none;
  }

  .rc {
    background-image: $product-gradient;
  }

  .brand-logo {
    position: absolute;
    top: 5px;
    left: 5px;
    max-width: 100px;
    max-height: 50px;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .thumbnail {
    border: 1px solid $grey-border-light;
    border-bottom: 5px solid $grey-border-light;

    img {
      transition: transform $anim-speed-slow $anim-curve;
    }
  }

  .card-body {
    padding: 10px;
  }

  .references {
    margin-top: auto;
    color: $grey-text;
    font-size: 12px;
    border-bottom: 1px solid $grey-border;
  }

  .price-pre-mention {
    font-size: 10px;
  }

  .price {
    color: $primary;
    font-size: 18px;
    font-weight: $font-black;
  }

  .regular-price{
    color: $grey-text-light;
    text-decoration: line-through;
    font-size: 12px;
  }

  .quick-view {
    position: absolute;
    bottom: 5px;
    right: 5px;
    opacity: 0;
    visibility: hidden;
    transition: opacity $anim-speed $anim-curve;
  }
}

.card-img-top {
  overflow: hidden;

  .product-thumbnail img {
    width: 100%;
  }
}

.highlighted-informations {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: $pm-highlight-bg-color;
  transform: translateY(100%);
  transition: transform .3s;

  .card-product:hover & {
    transform: translateY($pm-highlight-translateY);
  }
}

.quick-view {
  cursor: pointer;
}

.product-flags {
  position:absolute;
  top: 1px;
  margin-bottom: 0;
  z-index: 1;
  right: 1px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.page-content--product .product-flags {
  left: 0;
}

// product flag with text + amout
.product-flag-item {
  display: flex;
  align-items: center;
  gap: 0.25rem;

  &.discount_type_destockage {
    span {
      color: $color-destockage;
    }

    .product-flag {
      background: $color-destockage;
    }
  }

  &.discount_type_nouveaute {
    span {
      color: $color-nouveaute;
    }

    .product-flag {
      background: $color-nouveaute;
    }
  }

  &.discount_type_promoencours {
    span {
      color: $color-promo-en-cours;
    }

    .product-flag {
      background: $color-promo-en-cours;
    }
  }

  &.discount_type_remiseverte {
    span {
      color: $color-remise-verte;
    }

    .product-flag {
      background: $color-remise-verte;
    }
  }

  &.discount_type_findeserie {
    span {
      color: $color-fin-de-serie;
    }

    .product-flag {
      background: $color-fin-de-serie;
    }
  }
}

.product-flag {
  top:0;
  padding: $spacer/4;
  color: $white;
  background: $primary;
  text-transform: uppercase;
  font-weight: 600;

  &:not(:last-of-type) {
    margin-bottom: 5px;
  }

  &.discount-product {
    background: $primary;
  }
}

.color {
  width: 1.25rem;
  height: 1.25rem;
  display: inline-block;
  box-shadow: 2px 2px 4px 0 rgba(0,0,0,.2);
  border: 1px solid rgba(0,0,0,.05);
  cursor: pointer;
  background-size: contain;
}

.product-title {
  color: $black;
  font-size: 14px;
  font-weight: $font-black;
  text-decoration: none;
  text-transform: uppercase;
  margin-bottom: 5px;

  & a {
    color: $black;
    transition: color $anim-speed $anim-curve;

    &:hover {
      color: $grey-text;
    }
  }

  .card-product:hover & {
    color: $grey-text;

    & a {
      color: $grey-text;
    }
  }
}

.slider-wrapper-content {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;

  @include media-breakpoint-up(lg) {
    width: 60%;
  }

  @include media-breakpoint-up(xl) {
    width: 50%;
  }
}

.content-info-product {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;

  @include media-breakpoint-up(lg) {
    width: 40%;
  }

  @include media-breakpoint-up(xl) {
    width: 50%;
  }

  .discount-label {
    text-transform: uppercase;
    font-size: 1.325rem;
    font-weight: 600;

    &.discount_type_destockage {
      color: $color-destockage;
    }

    &.discount_type_nouveaute {
      color: $color-nouveaute;
    }

    &.discount_type_promoencours {
      color: $color-promo-en-cours;
    }

    &.discount_type_remiseverte {
      color: $color-remise-verte;
    }

    &.discount_type_findeserie {
      color: $color-fin-de-serie;
    }
  }
}

.images-container {
  overflow: hidden;
  display: flex;
  flex-direction: column;

  @include media-breakpoint-up(md) {
    display: flex;
    flex-direction: row;
  }

  .thumbnail-container {
    width: 100%;
    display: none;

    @include media-breakpoint-up(md) {
      display: block;
      width: 100px;
    }

    .slick-current {

      .product-thumb {
        border: 2px solid $primary;
      }
    }

    .product-thumbs {
      height: 100%;
    }

    .product-thumb {
      margin: 10px;
      border: 2px solid transparent;
      transition: border $anim-speed $anim-curve;

      @include media-breakpoint-up(md) {
        margin: 0;
      }

      @include media-breakpoint-up(xxl) {
        margin-bottom: 17px;
      }
    }
  }
}

.slider-container {
  width: 100%;
  overflow: hidden;

  @include media-breakpoint-up(md) {
    width: calc(100% - 100px);
    margin-left: 12px;
  }
}

.slider-container-full {
  width: 100%;
  overflow: hidden;
}

/* PRODUCT PAGE */

.products-imagescover{
  box-shadow:$card-box-shadow;;
}
.product-img:not(:first-child) .rc {
  display: none;
}

#product-modal {


  .slick-slide {

    .image-figure {
      img {
        margin: 0 auto;
      }
    }

    > div {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      background-color: $grey-bg;
    }
  }
}

.product-video {
  display: flex !important;
  justify-content: center;
  align-items: center;
  background-color: $grey-bg;

  .youtube-container {
    width: 100%;
  }
}

.slick-initialized .product-img .rc{
  display: block;
}

.product-thumbs {
  margin:0 auto;

  &[data-count="2"]{
    width:$product-thumb-wrap-width-2;
  }

  .slick-slide {
    height: auto !important;
  }

  .slick-slide > div{
    cursor: pointer;
  }

  &:not(.slick-initialized) > div:not(:first-child){
    display: none;
  }

  .slick-track {
    display: block !important;
  }

  .slick-arrow {
    height: auto;
    position: absolute;
    left: 50%;


    &.slick-next {
      bottom: -10px;
      top: auto;
      transform: translateX(-50%);
    }

    &.slick-prev {
      transform: translateX(-50%) rotate(180deg);
      top: -20px;
    }
  }
}

.product-thumb{
  height:  auto;
  border: 1px solid $grey-border;

  &.slick-current {
    border: 2px solid theme-color('primary');
  }
}

.btn-zoom{
  position: absolute;
  right: $spacer;
  bottom: $spacer;
  cursor: pointer;
  display: none;
  padding: 0;
  line-height: 1;
  color: $secondary;
  transition: color $anim-speed $anim-curve;

  &:hover,
  &:focus,
  &:active {
    color: $secondary-hover;
  }

  & .material-icons {
    font-size: 2*$font-size-base;
  }
}

.images-container:hover .btn-zoom{
  display: block;
}
.label-color{
  line-height: 1;
  margin-right: $spacer/2;
}

.product-variants {

  &__grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
  }

  .product-variants-item {

    .label-variants {
      font-size: 1rem;
      font-weight: 600;
    }

    .custom-select {
      background: url('../img/static/arrow.svg');
      background-repeat: no-repeat;
      background-position: right;
    }
  }
}

.btn-all-variants {
  color: $primary;
  margin: 15px 0 25px;
  display: block;
  font-size: 1rem;
  transition: color $anim-speed $anim-curve;

  &:hover,
  &:focus,
  &:active {
    color: $primary-hover;
  }
}

.product-variants-item .color{
  width:2rem;
  height:2rem;
  border: 2px solid transparent;
}

.input-color:checked+span, .input-color:hover+span, .input-radio:checked+span, .input-radio:hover+span {
  border-color: $colorbox-border-color;
}

//product prices
.product__product-price {

  .current-price {
    display: flex;
    align-items: center;
  }
}

.discount{
  text-transform: uppercase;
  color: $white;
  background-color: $secondary;
  font-weight: $font-semi-bold;
  display: inline-block;
  padding: .25*$spacer;

  &.discount_type_destockage {
    background-color: $color-destockage;
  }

  &.discount_type_nouveaute {
      background-color: $color-nouveaute;
    }

  &.discount_type_promoencours {
      background-color: $color-promo-en-cours;
    }

  &.discount_type_remiseverte {
      background-color: $color-remise-verte;
    }

  &.discount_type_findeserie {
      background-color: $color-fin-de-serie;
  }
}

//product add to cart
.btn-add-to-cart{
  //@extend .d-flex;
  text-transform: uppercase;
  font-weight: 600;
  white-space: nowrap;
}

.product-features-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
  font-size: .875rem;
}

.features-list {
  width: 100%;

  td {
    padding: 15px 10px;

    &.value {
      font-weight: 700;
    }
  }

  tr:nth-child(odd) {
    background-color: $grey-bg;
  }
}

.card--reassurance{
  box-shadow:$card-box-shadow;;
}

// modal add to cart
.cart-content{
  padding: $spacer;
  background: theme-color('light');
}

//product pack
.pack-product-container{
  @extend .d-flex;
  @extend .justify-content-between;
  @extend .align-items-center;
  padding: 0 $spacer;
  font-size: $font-size-sm;
}
.mask{
  width: 55px;
}
.thumb-mask{
  padding: $spacer 0;
}
.pack-product__img{
  border: 1px solid $border-color;
}
.pack-product-name{
  @extend .flex-grow-1;
  @extend .mx-4;
}

.pack-product-quantity {
  border-left:1px solid $border-color;
}

.pack-product-price{
  padding-right: $spacer/2;

}
.pack-product-quantity{
  @extend .d-flex;
  @extend .align-items-center;
  @extend .align-self-stretch;
  padding-left: $spacer;
}

//modal cart
.modal-cart__image{
  margin-right: $spacer;
  max-width: 200px;

  height: auto;
}
.modal-cart__name{
  color: $primary;
  font-weight: $font-weight-bold;
}
.product-total{
  font-weight: $font-weight-bold;
}
.cart-content{
  > p:not(:last-child){
    margin-bottom: $spacer/2;
  }
}
@include media-breakpoint-mobile {
  .modal-cart__image{
    max-width: 120px;
  }
  .blockcart-modal {
    .divide-right{
      display: flex;
      justify-content: center;
    }
    .modal-footer {
      position: fixed;
      bottom: 0;
      background: #fff;
      justify-content: center;
      left: 0;
      width: 100%;
      box-shadow: 0px -0.25rem 0.5rem rgba($black, .15);
    }
    .modal-body{
      margin-bottom: ($btn-padding-y * 2) + ($btn-font-size*$btn-line-height) + $modal-inner-padding * 2;
    }
  }
}
@include media-breakpoint-down(xs) {
  .modal-cart__image{
    max-width: 70px;
  }
}
//spinner add to cart
.btn-add-to-cart {
  .btn-add-to-cart__spinner {
    @extend .spinner-border;
    width: $spinner-width-addtocart;
    height: $spinner-height-addtocart;
    border-width: $spinner-borderwidth-addtocart;
    display: none;
  }
  &.is--loading{
    .btn-add-to-cart__spinner {
      display: inline-block;
    }
    .btn-add-to-cart__icon {
      display: none;
    }
  }
}

.products-selection {
  justify-content: space-between;
  align-items: center;

  .total-products {
    display: flex;
    align-items: center;
    width: 100%;
    height: 58px;
    border-top: 1px solid $grey-border;
    border-bottom: 1px solid $grey-border;
  }
}

.pack-miniature-item{
  margin-bottom: $spacer / 2;
}
.ratio1_1{
  display: block;
}
.pack-product-name{
  margin: 0 $spacer * 1.5;
  flex-grow: 1;
}
.product-miniature{
  margin-bottom: $spacer ;
}
.product__card-img{
  position: relative;
}
.product__card-desc{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.highlighted-informations{
  text-align: center;
  padding: $spacer / 2;
}
.quantity__label{
  margin-bottom: 0;
  margin-top: $spacer;

}

.container-price-add-to-cart {
  border-top: 5px solid $grey-bg;
  padding-top: 25px;

  .wrapper {
    max-width: 450px;
    margin-left: auto;
  }
}

.product-add-to-cart {

  .product-quantity {
    row-gap: 15px;
    column-gap: 15px;
    margin: 10px 0;
  }

  .alert {
    border: 0;
  }

  .alert-no-return {
    color: $black;
  }

  &__info {
    font-weight: 600;
  }
}

.product-availability {
  font-size: .875rem;

  &.in-stock {

    .product-availability__color {
      background-color: $green;
    }
  }

  &.out-of-stock {

    .product-availability__color {
      background-color: $red;
    }
  }

  &.last {

    .product-availability__color {
      background-color: $secondary;
    }
  }

  &__color {
    width: 14px;
    height: 14px;
    border-radius: 100%;
    display: inline-block;
    margin-right: 5px;
  }
}

.product-customization{
  margin-bottom: $spacer * 1.5;
}
.product-discounts{
  margin-bottom: $spacer * 1.5;
}
.product__quantity{
  margin-bottom: 0;
}

.sort-by-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;

  select {
    height: 58px;
    width: auto;
    max-width: 100%;
  }
}

.total__item-show{
  text-align: center;
  font-size: $font-size-sm;
  margin-top: $spacer;
}
.variant-links{
  display: flex;
  justify-content: center;
}
.product__download{
  text-transform: uppercase;
}
.card--address{
  height: 100%;
  text-align: center;
  justify-content: center;
}
.invoice__address{
  margin-top: $spacer;
}
.invoice-address{
  height: 100%;
  text-align: center;
  justify-content: center;
}

.product-prices {
  margin-bottom: 15px;

  .current-price {
    display: flex;
    flex-wrap: wrap;
  }

  .product-price {
    display: flex;
    align-items: baseline;
  }

  .current-price-display {
    color: $primary;
    font-size: 1.5rem;
    font-weight: 900;
    margin-right: 10px;
    line-height: 1;
  }

  .regular-price {
    text-decoration: line-through;
    margin-right: 10px;
  }

  .tax-shipping-delivery-label {
    color: $primary;
    font-size: 1.5rem;
    font-weight: 900;
    line-height: 1;
  }
}

.product-tabs {
  margin: 50px 0;
}

.page-product-block-reassurance {
  margin-bottom: 30px;
}

.product-manufacturer {
  max-width: 90px;
}

.page-product-block-reassurance {

  .block-reassurance {
    border: 1px solid $grey-bg;
    padding: 25px;

    @include media-breakpoint-up(lg) {
      margin-left: -15px;
    }
  }

}
