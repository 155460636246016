.carousel a {
  display: block;

  &:not(:first-child) {
    display: none;
  }
}

.slider-caption {
  position: absolute;
  top: 10%;
  left: 90px;
  margin-bottom: 0;
  color: $white;
  max-width: 340px;
}

.slick-slide:not(.slick-current) .img-carousel.lazyload {
  display: none !important;
}

.slick-slide figure{
  position: absolute;
  top: 0;
}

.slick-dots {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;

  li {
    margin: 0 8px;

    button {
      padding: 0;
      font-size: 0;
      line-height: 0;
      display: block;
      width: 8px;
      height: 8px;
      color: transparent;
      border: 0;
      border-radius: 100%;
      background: $primary;
    }

    &.slick-active {

      button {
        background-color: $secondary;
        width: 10px;
        height: 10px;
      }
    }
  }
}

.slick-arrow {

  &:focus,
  &:active {
    box-shadow: none !important;
  }
}

// Carousel homepage

.carousel-home {

  @media screen and (max-width: 768px) {
    max-width: 350px;
    margin-left: auto;
    margin-right: auto;
  }

  // right element
  &__right {
    margin-top: 1rem;

    @include media-breakpoint-up(xl) {
      margin-top: 0;
    }
  }

  .carousel {
    height: 100%;
    min-height: 417px;

    @include media-breakpoint-up(lg) {
      min-height: 317px;
    }

    .img-carousel {
      aspect-ratio: 904 / 317;
    }

    .img-carousel-mobile {
      aspect-ratio: 333 / 413;
    }

    .slide-item {
      position: relative;
      height: 100%;

      &::after {
        content: "";
        width: 100%;
        height: 100%;
        display: block;
        position: absolute;
        top: 0;
        background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 40%, #000000 115%);
      }
    }

    .slick-dots {
      position: absolute;
      bottom: 5px;
      left: 50%;
      transform: translateX(-50%);

      li {

        &.slick-active {

          button {
            background-color: $secondary;
            box-shadow: none;
          }
        }
      }
    }

    .slick-arrow {
      color: $white;

      svg {
        width: 25px;
        height: 25px;
      }

      &.slick-next {

        span {
          transform: rotate(-90deg);
        }
      }

      &.slick-prev {

        span {
          transform: rotate(90deg);
        }
      }
    }

    .slick-list,
    .slick-track,
    .slick-slide,
    .slick-slide > div,
    .slick-slide .rc {
      height: 100%;
    }

    .slick-slide img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .slider-caption {
      position: absolute;
      top: auto;
      bottom: 10%;
      left: 90px;
      margin-bottom: 0;
      color: $white;
      max-width: 400px;
      height: auto;
      min-height: 200px;
      z-index: 1;

      @include media-breakpoint-mobile {
        bottom: 60px;
        left: 68px;
        width: 100%;
        min-height: auto;
        padding: 0 51px;
      }

      @include media-breakpoint-down(xs) {
        left: 0;
      }
    }

    .slider-title {
      font-size: 2.25rem;
      font-weight: 900;
      margin-bottom: 0;

      @include media-breakpoint-mobile {
        font-size: 1.3rem;
      }
    }

    .slider-description {
      font-size: .875rem;
      color: $white;
    }

    .slider-link {
      color: $white;
      font-size: .75rem;
      font-weight: 700;
      text-transform: uppercase;
      display: flex;
      align-items: center;

      span {
        border-bottom: 1px solid $white;
        font-style: italic;
      }

      svg {
        padding-top: 5px;
        transform: rotate(180deg);
      }
    }
  }
}

.slick-slide:not(.slick-current) .img-carousel.lazyload {
  display: none !important;
}

.slick-slide figure {
  position: absolute;
  top: 0;
}
